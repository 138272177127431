import { Outlet } from 'react-router-dom'

import { FadeIn } from 'components/common/FadeIn'

import { Menu } from './Menu'
import { Sidebar } from './Sidebar'
import { Styled } from './styled'

export const Layout = () => {
	return (
		<FadeIn style={{ animationDuration: '.8s' }}>
			<Styled.Wrap>
				<Sidebar />
				<Outlet />
				<Menu />
			</Styled.Wrap>
		</FadeIn>
	)
}
