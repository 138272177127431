import { urls } from 'routes/urls'

import { Button, Text } from '@creditclubteam/kit/ui-components'

import { Styled } from './styled'

export const AppError = () => {
	return (
		<Styled.Wrap>
			<Styled.Content>
				<Text as='h1' font='h1' indent='xxl'>
					Ошибка приложения
				</Text>
				<a href={urls.root}>
					<Button type='button' onClick={window.location.reload}>
						Назад на главную
					</Button>
				</a>
			</Styled.Content>
		</Styled.Wrap>
	)
}
