import { transparentize } from 'polished'
import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'
import { Image as _Image } from 'components/common/Image'
import { PageLoading } from 'components/common/PageLoading'

const Dropzone = styled.div<{ isHovered: boolean; isDragActive: boolean; isDragReject: boolean }>(
	({ theme, isDragActive, isHovered, isDragReject }) => ({
		position: 'relative',
		outline: 'none',
		display: 'flex',
		boxSizing: 'border-box',
		padding: `${theme.spaces.xl}px ${theme.spaces.s}px`,
		border: `2px dashed ${theme.colors.border}`,
		borderRadius: 8,
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',

		...mixins.when(isHovered || isDragActive, {
			borderColor: theme.colors.active,
		}),

		...mixins.when(isDragReject, {
			borderColor: theme.colors.negative,
		}),
	})
)

const File = styled.div<{ isFailed?: boolean }>(({ theme, isFailed }) => ({
	position: 'relative',
	backgroundColor: theme.colors.background,
	borderRadius: 8,
	maxHeight: 64,
	height: 64,
	display: 'grid',
	gap: theme.spaces.l,
	gridTemplateColumns: 'auto 1fr',

	...mixins.when(isFailed, {
		backgroundColor: transparentize(0.75, theme.colors.negative),
	}),

	'img:first-child': {
		width: 64,
		height: 64,
		borderRadius: 8,
		objectFit: 'cover',
	},
}))

const FileContent = styled(Text)(({ theme }) => ({
	margin: `${theme.spaces.s}px 0`,
	wordBreak: 'break-word',
	display: '-webkit-box',
	overflow: 'hidden',
	height: 'fit-content',
	maxWidth: '90%',
	textOverflow: 'ellipsis',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
}))

const ImageWrap = styled.div({
	height: 64,
	width: 64,
	display: 'flex',
	position: 'relative',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 8,
	overflow: 'hidden',
	backgroundColor: '#C2E9FF',
})

const Image = styled(_Image)<{ isLoading?: boolean }>(({ isLoading }) => ({
	width: '100%',
	height: '100%',
	objectFit: 'cover',

	...mixins.when(isLoading, { opacity: 0.25 }),
}))

const Remove = styled(_Image)(({ theme }) => ({
	position: 'absolute',
	top: theme.spaces.s,
	right: theme.spaces.s,
	width: 16,
	height: 16,
	cursor: 'pointer',
}))

const Loading = styled(PageLoading)({
	zIndex: 3,
})

export const Styled = {
	Dropzone,
	File,
	Remove,
	Image,
	Loading,
	ImageWrap,
	FileContent,
}
