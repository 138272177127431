import { ReactNode } from 'react'
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom'
import applications from 'assets/nav/applications.svg'
import assessment from 'assets/nav/assessment.svg'
import clients from 'assets/nav/clients.svg'
import facilities from 'assets/nav/facilities.svg'
import knowledges from 'assets/nav/knowledges.svg'
import notifications from 'assets/nav/notifications.svg'
import onlineSigning from 'assets/nav/onlineSigning.svg'
import profile from 'assets/nav/profile.svg'
import scoring from 'assets/nav/scoring.svg'
import { urls } from 'routes/urls'
import { useTheme } from 'styled-components'

import { Text, TextProps } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

interface NavbarItem {
	label: ReactNode
	href: string
	isDirectLink?: boolean
	disabled?: boolean
	root?: string
}

export interface NavbarProps {
	items: (string | NavbarItem)[]
}

const NAV_ICONS = {
	[urls.applications()]: <Image src={applications} />,
	[urls.clients]: <Image src={clients} />,
	[urls.facilities]: <Image src={facilities} />,
	[urls.notifications]: <Image src={notifications} />,
	['https://wiki.credit.club/partners']: <Image src={knowledges} />,
	[urls.onlineSigning]: <Image src={onlineSigning} />,
	[urls.scoring]: <Image src={scoring} />,
	[urls.assessment]: <Image src={assessment} />,
	[urls.profile()]: <Image src={profile} />,
} as const

const NavbarItem = ({ href, label, isDirectLink, disabled, root }: NavbarItem) => {
	const location = useLocation()
	const [pathname] = href.split('#')
	const path = useResolvedPath({ pathname })

	const isActive = root ? location.pathname.startsWith(root) : location.pathname === path.pathname

	const El = (
		<Styled.Item isActive={isActive}>
			{NAV_ICONS[root ?? href]}
			<Text font='nav' variant={disabled ? 'secondary' : isActive ? 'link' : 'text'}>
				{label}
			</Text>
		</Styled.Item>
	)

	if (disabled) return El

	if (isDirectLink) {
		return (
			<a href={href} target='_blank' rel='noopener noreferrer'>
				{El}
			</a>
		)
	}

	return (
		<NavLink key={href} to={{ pathname, search: location.search }}>
			{El}
		</NavLink>
	)
}

export const Navbar = ({ items }: NavbarProps) => {
	const theme = useTheme()

	const textProps: TextProps = {
		styleOverride: { textTransform: 'uppercase', padding: `${theme.spaces.s}px 0`, fontSize: 12 },
		font: 'label',
		variant: 'secondary',
	}

	return (
		<Styled.Wrap>
			{items.map((item) =>
				typeof item === 'string' ? (
					<Text key={item} {...textProps}>
						{item}
					</Text>
				) : (
					<NavbarItem key={item.href} {...item} />
				)
			)}
		</Styled.Wrap>
	)
}
