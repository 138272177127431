import { createMutation, createQuery } from 'react-query-kit'
import { RequestError, WithNull } from 'api/types'
import axios from 'axios'
import {
	ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerEmployeeRequestDto,
	ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerEmployeeUpdateDto,
	ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDto,
} from 'dto'
import { queryClient } from 'instances'
import { includes } from 'rambda'
import { and, comparison, eq, inList } from 'rsql-builder'

import { notice } from 'components/common/Notice'

import { EmployeesApi } from './dto'

const baseUrl = '/v1/employees'

const useSearchEmployees = createQuery<
	EmployeesApi.Search,
	{
		organizationId: string
		params: {
			page: number
			size?: number
			sort?: string
		}
		filter?: { text?: string; products?: string[]; role?: string[]; status?: string }
	},
	RequestError
>({
	queryKey: [baseUrl],
	fetcher: ({ organizationId, filter, params: { page = 0, size = 8, sort } }, { signal }) =>
		axios
			.get(`${baseUrl}`, {
				signal,
				params: (() => {
					const url = new URLSearchParams()

					url.append('organizationId', organizationId)
					url.append('page', `${page}`)
					url.append('size', `${size}`)
					sort && url.append('sort', sort)
					filter &&
						url.append(
							'filter',
							and(
								...([
									filter.text && comparison('text', eq(filter.text)),
									filter.status && comparison('status', eq(filter.status)),
									filter.products?.length && comparison('products', inList(...filter.products)),
									filter.role?.length && comparison('role', inList(...filter.role)),
								].filter(Boolean) as string[])
							)
						)

					if (!url.get('filter')) url.delete('filter')

					return url
				})(),
			})
			.then(({ data }) => data),
})

const useEmployeeMutation = createMutation<
	void,
	{
		id: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerEmployeeUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ id, payload }) => axios.put(`${baseUrl}/${id}`, payload),
})

const useCreateEmployee = createMutation<
	void,
	WithNull<ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerEmployeeRequestDto>,
	RequestError
>({
	mutationFn: (payload) =>
		axios.post(`${baseUrl}`, payload).then(() => {
			queryClient.invalidateQueries({ queryKey: useSearchEmployees.getKey() })
		}),
})

const useEmployeeInvitation = createMutation<void, { employeeId: string }, RequestError>({
	mutationFn: ({ employeeId }) =>
		axios
			.post(`${baseUrl}/${employeeId}/invitation`)
			.then(() => {
				notice({ message: 'Приглашение отправлено', type: 'success' })
				queryClient.invalidateQueries({ queryKey: useSearchEmployees.getKey() })
			})
			.catch((error: RequestError) => {
				if (
					includes(error?.response?.data?.type, [
						'email_not_exists',
						'entity_not_found',
						'invitation_create_error',
					])
				) {
					notice({ message: error?.response!.data.detail })
				}

				throw error
			}),
})

const useEmployeeStatusMutation = createMutation<
	void,
	{
		employeeId: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ employeeId, payload }) =>
		axios
			.patch(`${baseUrl}/${employeeId}/status`, payload)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: useSearchEmployees.getKey() })
			})
			.catch((error: RequestError) => {
				if (
					includes(error?.response?.data?.type, [
						'last_owner',
						'duplicate_email',
						'entity_not_found',
					])
				) {
					notice({ message: error?.response!.data.detail })
				}

				throw error
			}),
})

const useEmployeeDelete = createMutation<
	void,
	{
		employeeId: string
	},
	RequestError
>({
	mutationFn: ({ employeeId }) =>
		axios
			.delete(`${baseUrl}/${employeeId}`)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: useSearchEmployees.getKey() })
			})
			.catch((error: RequestError) => {
				if (includes(error?.response?.data?.type, ['entity_not_found', 'delete_forbidden'])) {
					notice({ message: error?.response!.data.detail })
				}

				throw error
			}),
})

export const employeesApi = {
	useSearchEmployees,
	useCreateEmployee,
	useEmployeeDelete,
	useEmployeeStatusMutation,
	useEmployeeMutation,
	useEmployeeInvitation,
}
