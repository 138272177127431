import { useCallback } from 'react'
import { employeesApi } from 'api/employees'
import activate from 'assets/activate.svg'
import invite from 'assets/invite.svg'
import lock from 'assets/lock.svg'
import pencil from 'assets/pencil.svg'
import recall from 'assets/recall.svg'
import {
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum,
	ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDtoStatusEnum,
} from 'dto'

import { DropdownProps } from 'components/common/Dropdown'

interface UseEmployeesItemDropdownProps {
	status: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum
	onEdit: () => void
	employeeId: string
}

export const useEmployeesItemDropdown = ({
	status,
	employeeId,
	onEdit,
}: UseEmployeesItemDropdownProps) => {
	const invitation = employeesApi.useEmployeeInvitation()
	const mutateStatus = employeesApi.useEmployeeStatusMutation()
	const employeeDelete = employeesApi.useEmployeeDelete()

	const getItems = useCallback(
		({
			status,
			onEdit,
		}: {
			status: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum
			onEdit: () => void
		}): DropdownProps['items'] => {
			switch (status) {
				case ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.ACTIVE: {
					return [
						{
							id: 1,
							label: 'Редактировать',
							onClick: onEdit,
							icon: pencil,
						},
						{
							id: 2,
							label: 'Деактивировать',
							icon: lock,
							onClick: () =>
								!mutateStatus.isPending &&
								mutateStatus.mutate({
									employeeId,
									payload: {
										status: ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDtoStatusEnum.DEACTIVATED,
									},
								}),
						},
					]
				}
				case ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.DEACTIVATED: {
					return [
						{
							id: 1,
							label: 'Редактировать',
							onClick: onEdit,
							icon: pencil,
						},
						{
							id: 2,
							label: 'Активировать',
							icon: activate,
							onClick: () =>
								!mutateStatus.isPending &&
								mutateStatus.mutate({
									employeeId,
									payload: {
										status: ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDtoStatusEnum.ACTIVE,
									},
								}),
						},
					]
				}
				case ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.INVITED: {
					return [
						{
							id: 1,
							label: 'Редактировать',
							onClick: onEdit,
							icon: pencil,
						},
						{
							id: 2,
							label: 'Отозвать приглашение',
							icon: recall,
							onClick: () => !employeeDelete.isPending && employeeDelete.mutate({ employeeId }),
						},
						{
							id: 3,
							label: 'Пригласить повторно',
							icon: invite,
							onClick: () => !invitation.isPending && invitation.mutate({ employeeId }),
						},
					]
				}
			}
		},
		[employeeDelete, employeeId, invitation, mutateStatus]
	)

	return {
		dropdownItems: getItems({ status, onEdit }),
	}
}
