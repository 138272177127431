import { PropsWithChildren } from 'react'
import { Icon } from '@iconify/react'
import styled, { useTheme } from 'styled-components'

import { mixins,PreparedTheme } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'

export interface CheckboxProps {
	checked?: boolean
	disabled?: boolean
	indent?: keyof PreparedTheme['spaces']
	onChange: (checked: boolean) => void
}

const Wrap = styled.div<{ indent?: keyof PreparedTheme['spaces'] }>(({ theme, indent }) => ({
	...mixins.when(indent, mixins.indent(theme.spaces[indent!])),
}))

const Content = styled.div<{ disabled?: boolean }>(({ theme, disabled }) => ({
	span: {
		marginLeft: theme.spaces.s,
	},

	display: 'flex',
	alignItems: 'center',
	width: 'fit-content',
	cursor: 'pointer',

	...mixins.when(disabled, {
		cursor: 'default',
	}),

	svg: {
		alignSelf: 'flex-start',
		marginTop: 1,
		minHeight: 18,
		maxHeight: 18,
		minWidth: 18,
		maxWidth: 18,
	},
}))

export const Checkbox = ({
	checked,
	onChange,
	disabled,
	children,
	indent,
}: PropsWithChildren<CheckboxProps>) => {
	const { colors } = useTheme()

	return (
		<Wrap indent={indent}>
			<Content onClick={() => !disabled && onChange(!checked)} disabled={disabled}>
				<Icon
					color={disabled ? '#CDCDCD' : checked ? colors.active : 'black'}
					icon={!checked ? 'zmdi-square-o' : 'zmdi-check-square'}
				/>
				<Text as='span' styleOverride={{ display: 'flex', alignItems: 'center' }} font='body'>
					{children}
				</Text>
			</Content>
		</Wrap>
	)
}
