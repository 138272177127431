import { useContext } from 'react'
import { dictionariesApi } from 'api/dictionaries'
import { kcCtx } from 'context'

export const useDictionaries = () => {
	const { authenticated } = useContext(kcCtx)

	dictionariesApi.useGetAddressTypes({
		enabled: authenticated,
		retry: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	})
	dictionariesApi.useGetProductsTypes({
		enabled: authenticated,
		retry: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	})
	dictionariesApi.useGetApplication({
		enabled: authenticated,
		retry: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	})
}
