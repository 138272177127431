import { useEffect } from 'react'
import { YMInitializer } from 'react-yandex-metrika'

export const Metrika = () => {
	const isDebug = new URLSearchParams(window.location.search).get('debug') === 'true'

	useEffect(() => {
		// eslint-disable-next-line no-console
		if (isDebug) console.log({ env: window.__env__ })
	}, [isDebug])

	if (isDebug || window.__env__.BUILD_MODE === 'production')
		return (
			<YMInitializer
				accounts={[96139042]}
				version='2'
				options={{
					defer: true,
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
				}}
			/>
		)

	return null
}
