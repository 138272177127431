import { ToastContainer, ToastContainerProps } from 'react-toastify'

export const NoticeContainer = () => {
	const props: ToastContainerProps = {
		position: 'top-right',
		hideProgressBar: true,
		newestOnTop: true,
		closeOnClick: true,
		limit: 3,
	}

	return <ToastContainer {...props} />
}
