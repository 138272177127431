import { MouseEventHandler, useCallback, useState } from 'react'

interface UseControls {
	onCancel?: () => void
	initialEdit?: boolean
}

export const useControls = ({ onCancel, initialEdit = false }: UseControls = {}) => {
	const [edit, setEdit] = useState(initialEdit)

	const handleCancel = useCallback(() => {
		onCancel?.()
		setEdit(false)
	}, [onCancel])

	const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
		// prevent firefox from submitting form despite its button type
		if ('preventDefault' in event) event.preventDefault()

		setEdit(true)
	}, [])

	return {
		handleCancel,
		handleEdit,
		edit,
		setEdit,
	}
}
