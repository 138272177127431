/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ComCreditclubPartnerCommonDtoV1BankDetailsDto } from './com-creditclub-partner-common-dto-v1-bank-details-dto';
import { ComCreditclubPartnerCommonDtoV1ContactInfoDto } from './com-creditclub-partner-common-dto-v1-contact-info-dto';
import { ComCreditclubPartnerLkDtoV1OrganizationLegalInfoDto } from './com-creditclub-partner-lk-dto-v1-organization-legal-info-dto';
import {
    ComCreditclubPartnerCommonDtoV1BankDetailsDto,ComCreditclubPartnerCommonDtoV1ContactInfoDto,ComCreditclubPartnerLkDtoV1OrganizationLegalInfoDto,
} from ".";

/**
 * Профиль партнера
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
 */
export interface ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto {

    /**
     * Тип организации партнера: * `PERSON` – ФЛ * `ORGANIZATION` – ЮЛ * `ENTREPRENEUR` – ИП 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    legalType: ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoLegalTypeEnum;

    /**
     * @type {ComCreditclubPartnerLkDtoV1OrganizationLegalInfoDto}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    legalInfo: ComCreditclubPartnerLkDtoV1OrganizationLegalInfoDto;

    /**
     * @type {ComCreditclubPartnerCommonDtoV1ContactInfoDto}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    mainContact: ComCreditclubPartnerCommonDtoV1ContactInfoDto;

    /**
     * Наименование для коммуникаций с клиентами
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    nameForClient: string;

    /**
     * Сайт
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    site?: string;

    /**
     * @type {ComCreditclubPartnerCommonDtoV1BankDetailsDto}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    bankDetails?: ComCreditclubPartnerCommonDtoV1BankDetailsDto;

    /**
     * Статус верификации: * `NOT_STARTED` – Не запускалась * `VERIFYING` – На проверке * `VERIFIED` – Верифицирован * `FAILED` – Проверка не пройдена 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDto
     */
    verificationStatus: ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoLegalTypeEnum {
    PERSON = 'PERSON',
    ORGANIZATION = 'ORGANIZATION',
    ENTREPRENEUR = 'ENTREPRENEUR'
}
/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum {
    NOTSTARTED = 'NOT_STARTED',
    VERIFYING = 'VERIFYING',
    VERIFIED = 'VERIFIED',
    FAILED = 'FAILED'
}

