import { NewApplication } from 'components/forms/NewApplication'

import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'

export const New = () => {
	return (
		<FadeIn>
			<Grid.Row sizes='930px'>
				<NewApplication />
			</Grid.Row>
		</FadeIn>
	)
}
