import arrow from 'assets/arrow-down.svg'
import calendar from 'assets/calendar.svg'
import rouble from 'assets/rouble.svg'
import axios from 'axios'
import { useDecisionCtx } from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import { saveAs } from 'file-saver'

import { Text } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'

export const Attachments = () => {
	const { appId, programIdToPreview } = useDecisionCtx()

	const handleDownloadReport = async () => {
		axios
			.get<string>(`/v1/applications/${appId}/decisions/${programIdToPreview}/report`, {
				params: { type: 'PRELIMINARY_PAYMENT_SCHEDULE' },
				responseType: 'blob',
				headers: {
					Accept: 'application/pdf',
				},
			})
			.then(({ data }) => saveAs(`data:application/pdf;base64,${data}`, 'График'))
	}

	if (!programIdToPreview) return null

	return (
		<Card>
			<Grid.Row gap='l'>
				<Grid.Section gap='m'>
					<Grid.Row sizes='auto 1fr' gap='s' style={{ alignItems: 'center' }}>
						<Image src={rouble} />
						<Text>Никаких дополнительных платежей</Text>
					</Grid.Row>
					<Grid.Row sizes='auto 1fr' gap='s' style={{ alignItems: 'center' }}>
						<Image src={calendar} />
						<Text>Срок действия предложения — 14 дней</Text>
					</Grid.Row>
				</Grid.Section>
				<Grid.Section gap='m' style={{ display: 'none' }}>
					<Grid.Row
						sizes='auto 1fr'
						gap='s'
						onClick={handleDownloadReport}
						style={{ alignItems: 'center', cursor: 'pointer', width: 'fit-content' }}
					>
						<Image src={arrow} />
						<Text variant='link'>Скачать график</Text>
					</Grid.Row>
				</Grid.Section>
			</Grid.Row>
		</Card>
	)
}
