import { transparentize } from 'polished'
import styled from 'styled-components'

import { ToggleSwitch } from '@creditclubteam/kit/legacy-ui-components'
import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'

const Item = styled.div(({ theme }) => ({
	padding: `${theme.spaces.s}px ${theme.spaces.l}px`,

	'&:hover': {
		backgroundColor: transparentize(.80, theme.colors.backgroundSecondary),
	},
}))

const WithIcon = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spaces.s,

	img: {
		height: 24,
		width: 24,
	},
}))

const Col = styled(Text)(mixins.ellipsis())

const Toggle = styled(ToggleSwitch)(({ theme }) => ({
	input: {
		'&:checked': {
			'~ .cc-toggle-switch-inner': {
				backgroundColor: theme.colors.positive,
			},
		},
	},
}))

export const Styled = {
	Col,
	Item,
	Toggle,
	WithIcon,
}
