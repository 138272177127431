import { ComponentType } from 'react'
import ReactModal, { Props } from 'react-modal'
import { transparentize } from 'polished'
import styled, { DefaultTheme, StyledComponent } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Image } from 'components/common/Image'

const Modal = styled(ReactModal as ComponentType<Props>)(({ theme }) => ({
	zIndex: 50,
	boxSizing: 'border-box',
	overflow: 'visible',
	margin: '0 auto',
	padding: theme.spaces.xl,
	width: '100%',
	maxWidth: 600,
	background: 'white',
	border: 'none',
	outline: 'none',
	boxShadow: '0 2px 10px rgb(0 0 0 / 10%)',
	borderRadius: '16px 16px 0 0',

	...mixins.media(theme.breakpoints.mobile, {
		borderRadius: 16,
	}),

	'.ReactModal__Overlay': {
		display: 'flex',
		position: 'fixed',
		zIndex: 1000,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		padding: `${theme.spaces.xl}px 0`,
		backgroundColor: 'rgba(#00729b, 0.8)',
		justifyContent: 'center',
		alignItems: 'center',
	},
})) as StyledComponent<ComponentType<Props>, DefaultTheme, any, never>

interface ClassNameConsumerProps {
	className?: string
	children: (className?: string) => React.ReactElement
}

const Overlay = styled(({ className, children }: ClassNameConsumerProps) => children(className))(
	({ theme }) => ({
		display: 'flex',
		position: 'fixed',
		zIndex: 1000,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		padding: `${theme.spaces.xl}px 0`,
		backgroundColor: transparentize(0.2, theme.colors.backgroundSecondary),
		justifyContent: 'center',
		alignItems: 'center',
	})
)

const Close = styled(Image)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	padding: theme.spaces.xl,
	paddingTop: theme.spaces.xxl,
	cursor: 'pointer',
}))

const Header = styled.div(({ theme }) => mixins.indent(theme.spaces.xl))

export const Styled = {
	Modal,
	Header,
	Overlay,
	Close,
}
