import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Styled = {
	Wrap: styled.div(({ theme }) => ({
		position: 'absolute',
		top: theme.spaces.l,
		right: theme.spaces.l,
	})),

	Link: styled(Link)(({ theme }) => ({
		color: theme.colors.active,
		borderRadius: 4,
		alignItems: 'center',
		display: 'flex',
		padding: `${theme.spaces.m}px ${theme.spaces.l}px`,
		transition: 'color .3s ease',

		...theme.fonts.buttonLarge,

		fontWeight: 'normal',

		':hover': {
			color: theme.colors.activeHovered,
		},
	})),
}
