import { useRef } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { applicationsApi } from 'api/applications'
import fileDotted from 'assets/file-dotted.svg'
import { useDecisionCtx } from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import { useTheme } from 'styled-components'
import { Keyboard, Pagination } from 'swiper/modules'
import { SwiperProps } from 'swiper/react'
import { Swiper } from 'swiper/types'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { Text } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'

import { CardContent } from './CardContent'
import { Styled } from './styled'

export const Program = () => {
	const { appId, programIdToPreview } = useDecisionCtx()
	const { data = [] } = applicationsApi.useGetDecision({
		variables: { appId: appId! },
	})

	const { spaces } = useTheme()
	const sliderRef = useRef<Nullable<Swiper>>(null)
	const visibleNavs = data.length > 2

	const sliderProps: SwiperProps = {
		spaceBetween: spaces.xl,
		simulateTouch: false,
		keyboard: true,
		onInit: (swiper) => {
			sliderRef.current = swiper
		},
		slidesPerView: 2,
		pagination: { clickable: true },
		modules: [Pagination, Keyboard],
	}

	return (
		<Card title='Выберите программу'>
			<Styled.Content visibleArrows={visibleNavs}>
				{visibleNavs && (
					<Styled.PrevEl type='button' onClick={() => sliderRef!.current!.slidePrev()}>
						<Icon icon='zmdi:chevron-left' />
					</Styled.PrevEl>
				)}
				{data.length > 2 ? (
					<Styled.Slider {...sliderProps} visibleBullets={visibleNavs}>
						{data.map(({ id }) => (
							<Styled.SwiperCard key={id} selected={programIdToPreview === id}>
								<CardContent key={id} programId={id} />
							</Styled.SwiperCard>
						))}
					</Styled.Slider>
				) : (
					<Grid.Row>
						{data.map(({ id }) => (
							<Styled.Card key={id} selected={programIdToPreview === id}>
								<CardContent key={id} programId={id} />
							</Styled.Card>
						))}
						{data.length <= 1 && (
							<Styled.Card isEmpty>
								<Grid.Section gap='s' style={{ justifyItems: 'center' }}>
									<Styled.Circle>
										<Image src={fileDotted} />
									</Styled.Circle>
									<Text font='body-2' variant='secondary' styleOverride={{ textAlign: 'center' }}>
										{'По заявке больше\nнет доступных программ'}
									</Text>
								</Grid.Section>
							</Styled.Card>
						)}
					</Grid.Row>
				)}
				{visibleNavs && (
					<Styled.NextEl type='button' onClick={() => sliderRef!.current!.slideNext()}>
						<Icon icon='zmdi:chevron-right' />
					</Styled.NextEl>
				)}
			</Styled.Content>
		</Card>
	)
}
