import { PropsWithChildren, ReactNode } from 'react'

import { defaultOr } from '@creditclubteam/kit/helpers'
import { Text } from '@creditclubteam/kit/ui-components'

export interface FieldProps {
	label: ReactNode
	value?: any
	edit?: boolean
}

export const Field = ({ label, edit, value, children }: PropsWithChildren<FieldProps>) => {
	if (edit) return <>{children}</>

	return (
		<div>
			<Text variant='secondary' font='body-2' indent='xs' as='div'>
				{label}
			</Text>
			<Text as='div'>{defaultOr(value)}</Text>
		</div>
	)
}
