import { array, object, string } from 'yup'

import { validationSchemas } from 'helpers/validationSchemas'

export const validationSchema = object({
	email: validationSchemas.email.required(),
	role: string().required(),
	phone: validationSchemas.phone,
	fullName: validationSchemas.fullName(false),
	products: array().of(string()),
})
