import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { adaptText } from '@creditclubteam/kit/helpers'

const formatNumber = Intl.NumberFormat('ru-RU', {
	maximumFractionDigits: 2,
}).format

export const utils = {
	formatSite: (value: string) => {
		if (!value) return value
		if (!value.startsWith('http://') && !value.startsWith('https://')) return `https://${value}`
		return value
	},

	formatNumber,

	getFullName: <T extends Record<string, any>>(data: Nullable<T>, cutFull = false) => {
		if (!data) return null

		const { name, surname, patronymic } = data

		let results = ''

		if (!surname && !patronymic) {
			results = name || ''
		} else {
			const willName: Record<string, string> = {
				surname,
				name,
				patronymic,
			}

			Object.keys(willName).forEach((key, i) => {
				if (willName[key]) {
					results += `${i !== 0 && cutFull ? willName[key][0] + '.' : willName[key]} `
				}
			})
		}

		return results.trim()
	},

	parsePhone: (unparsedPhone?: Nullable<string>) => {
		if (!unparsedPhone || !/^[0-9/+-\s]*$/.test(unparsedPhone)) return null

		let phone, phone1, phone2, phone3, phone4
		unparsedPhone = unparsedPhone.toString()

		if (unparsedPhone !== null) {
			if (unparsedPhone.length === 7) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 5)
				phone3 = unparsedPhone.slice(5, 7)
				phone = `+7 ${phone1}-${phone2}-${phone3}`
			} else if (unparsedPhone.length === 10) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 6)
				phone3 = unparsedPhone.slice(6, 8)
				phone4 = unparsedPhone.slice(8, 10)
				phone = `+7 ${phone1} ${phone2}-${phone3}-${phone4}`
			} else {
				phone = `+7 ${unparsedPhone}`
			}
		}

		return phone
	},

	unparsePhone: (phone?: Nullable<string>) => {
		if (!phone || typeof phone !== 'string') return ''

		return phone.replace(/^(\D*?[78](\D*|\s)?){1}|\D/g, '')
	},

	formatDuration: (value: Nullable<number>) => {
		if (value == null) return null
		if (value <= 11) return `${value} ${adaptText(value, ['месяц', 'месяца', 'месяцев'])}`
		if (value % 12 === 0) {
			const years = Math.floor(value / 12)
			return `${years} ${adaptText(years, ['год', 'года', 'лет'])}`
		} else {
			const years = Math.floor(value / 12)
			const months = value % 12
			return `${years} ${adaptText(years, ['год', 'года', 'лет'])} и ${months} ${adaptText(months, [
				'месяц',
				'месяца',
				'месяцев',
			])}`
		}
	},

	ls: {
		get: (key: string) => {
			try {
				return localStorage.getItem(key)
			} catch {
				//
			}
		},
		set: (key: string, value: any) => {
			try {
				return localStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value)
			} catch {
				//
			}
		},
	},
}
