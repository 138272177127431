import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { applicationsApi } from 'api/applications'
import { newApplicationHelpers } from 'components/forms/NewApplication/helpers'
import { Participants } from 'components/forms/NewApplication/Participants'
import { NewApplicationForm } from 'components/forms/NewApplication/types'
import { ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDtoTypeEnum } from 'dto'
import { Form, FormikProvider, useFormik } from 'formik'
import { useAppCtx } from 'providers/features/useAppCtx'
import { urls } from 'routes/urls'

import { Grid } from 'components/common/Grid'
import { defaultToApi, numberOrNull } from 'helpers/convertUtils'
import { utils } from 'helpers/utils'

import { Conditions } from './Conditions'
import { Documents } from './Documents'
import { Pledges } from './Pledges'
import { validationSchema } from './validationSchema'

export const NewApplication = () => {
	const { mutateAsync } = applicationsApi.useCreateApplication()
	const {
		profile: { selectedOrganizationId },
	} = useAppCtx()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const formik = useFormik<NewApplicationForm.Values>({
		initialValues: {
			documents: {},
			calculationAmount: '',
			calculationDuration: '',
			comment: '',
			purpose: '',
			pledges: [newApplicationHelpers.createPledge()],
			participants: [newApplicationHelpers.createParticipant(true)],
		},
		validationSchema: validationSchema(queryClient),
		onSubmit: async (
			{
				documents,
				comment,
				calculationAmount,
				calculationDuration,
				participants,
				pledges,
				purpose,
			},
			{ setSubmitting }
		) => {
			try {
				setSubmitting(true)

				await mutateAsync({
					purpose: defaultToApi(purpose),
					calculation: {
						amount: numberOrNull(calculationAmount)!,
						duration: numberOrNull(calculationDuration)! * 12,
					},
					organizationId: selectedOrganizationId!,
					participants: participants.map(
						({ type, email, fullName, creditHistoryConsentType, phone, roles }) => ({
							type,
							email: defaultToApi(email),
							roles,
							creditHistoryConsentType: defaultToApi(creditHistoryConsentType),
							phone: defaultToApi(utils.unparsePhone(phone)),
							name: defaultToApi(fullName.selected?.payload?.name ?? fullName.manual?.name),
							surname: defaultToApi(
								fullName.selected?.payload?.surname ?? fullName.manual?.surname
							),
							patronymic: defaultToApi(
								fullName.selected?.payload?.patronymic ?? fullName.manual?.patronymic
							),
						})
					),
					pledges: pledges.map(({ address, area, city }) => ({
						type: ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDtoTypeEnum.FACILITY,
						area: numberOrNull(area),
						city: defaultToApi(city),
						address: defaultToApi(address.selected?.payload),
					})),
					comment: defaultToApi(comment.trim().slice(0, 10000)),
					documents: Object.entries(documents).map(([, id]) => id!),
				})

				navigate({ pathname: urls.applications(), search: window.location.search })
			} catch (error) {
				// eslint-disable-next-line no-console
				console.dir(error)
			} finally {
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off'>
				<Grid.Section>
					<Conditions />
					<Pledges />
					<Participants />
					<Documents />
				</Grid.Section>
			</Form>
		</FormikProvider>
	)
}
