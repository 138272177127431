/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
    
} from ".";

/**
 * Сотрудник организации партнера
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
 */
export interface ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto {

    /**
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    id: string;

    /**
     * Имя
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    name?: string;

    /**
     * Фамилия
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    surname?: string;

    /**
     * Отчество
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    patronymic?: string;

    /**
     * Телефон. Формат 10 цифр, без +7 или 8
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    phone?: string;

    /**
     * Почта
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    email?: string;

    /**
     * Роль сотрудника: * `OWNER` – Владелец * `ADMIN` – Администратор * `EMPLOYEE` – Сотрудник 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    role: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum;

    /**
     * @type {Array<string>}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    products: Array<ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoProductsEnum>;

    /**
     * Статус сотрудника: * `INVITED` –  Приглашение отправлено * `ACTIVE` – Активен * `DEACTIVATED` – Деактивирован 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto
     */
    status: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE'
}
/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoProductsEnum {
    APPLICATION = 'APPLICATION'
}
/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum {
    INVITED = 'INVITED',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED'
}

