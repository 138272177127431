import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import { keycloak } from 'keycloak/instance'

export const useMetrikaLogger = () => {
	const { pathname, search, hash } = useLocation()

	useEffect(() => {
		if (window.__env__.BUILD_MODE !== 'production') return

		ym('hit', `${pathname}${search}${hash}`, { pathname, search, hash })
	}, [hash, pathname, search])

	useEffect(() => {
		if (!keycloak.tokenParsed?.sub || window.__env__.BUILD_MODE !== 'production') {
			return
		}

		ym('setUserID', keycloak.tokenParsed?.sub)
	}, [])
}
