import { employeesApi } from 'api/employees'
import { EmployeeModalProps } from 'components/forms/Employee'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum } from 'dto'
import { useFormik } from 'formik'
import { useAppCtx } from 'providers/features/useAppCtx'
import { pick } from 'rambda'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { AutocompleteHookValue } from '@creditclubteam/kit/ui-components'
import { notice } from 'components/common/Notice'
import { defaultToApi } from 'helpers/convertUtils'
import { utils } from 'helpers/utils'

import { validationSchema } from './validationSchema'

export const useData = ({
	editableData,
	onClose,
}: Pick<EmployeeModalProps, 'editableData' | 'onClose'>) => {
	const {
		profile: { selectedOrganizationId },
	} = useAppCtx()
	const mutate = employeesApi.useEmployeeMutation()
	const create = employeesApi.useCreateEmployee()

	const formik = useFormik({
		validationSchema,
		enableReinitialize: true,
		initialValues: {
			products: editableData?.products ?? [],
			role:
				editableData?.role ??
				ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.EMPLOYEE,
			email: editableData?.email ?? '',
			phone: utils.parsePhone(editableData?.phone) ?? '',
			fullName: {
				query: utils.getFullName(editableData) ?? '',
				manual: null as Nullable<Record<'name' | 'surname' | 'patronymic', string>>,
				selected: (utils.getFullName(editableData)
					? {
							id: utils.getFullName(editableData),
							label: utils.getFullName(editableData),
							payload: pick(['name', 'surname', 'patronymic'], editableData),
					  }
					: null) as Nullable<
					AutocompleteHookValue<Partial<{ name: string; surname: string; patronymic: string }>>
				>,
			},
		},
		onSubmit: async ({ email, fullName, phone, products, role }, { setFieldError }) => {
			try {
				const payload = {
					email: defaultToApi(email),
					products: defaultToApi(products),
					phone: defaultToApi(utils.unparsePhone(phone)),
					role: defaultToApi(role),
					name: defaultToApi(fullName.selected?.payload?.name ?? fullName.manual?.name),
					surname: defaultToApi(fullName.selected?.payload?.surname ?? fullName.manual?.surname),
					patronymic: defaultToApi(
						fullName.selected?.payload?.patronymic ?? fullName.manual?.patronymic
					),
				}

				if (editableData) {
					await mutate.mutateAsync({
						id: editableData.id,
						payload,
					})
				} else {
					await create.mutateAsync({
						organizationId: selectedOrganizationId!,
						...payload,
					})
				}

				onClose()
			} catch (error: any) {
				if (error?.response?.data?.type === 'duplicate_email') {
					setFieldError('email', 'Почта уже используется')
				}
				if (error?.response?.data?.type === 'last_owner') {
					notice({ message: error.response.data.detail, type: 'error' })
				}
			}
		},
	})

	return {
		formik,
		isLoading: mutate.isPending || create.isPending,
	}
}
