import { useCallback, useState } from 'react'

interface Props {
	initial?: boolean
	onFalse?: () => void
	onTrue?: () => void
	value?: () => void
	onToggle?: () => void
}

export const useBoolean = ({
	onFalse: falseCb,
	onTrue: trueCb,
	onToggle: toggleCb,
	initial = false,
}: Props = {}) => {
	const [value, setValue] = useState(initial)

	const setTrue = useCallback(() => {
		setValue(true)
		trueCb && trueCb()
	}, [trueCb])

	const setFalse = useCallback(() => {
		setValue(false)
		falseCb && falseCb()
	}, [falseCb])

	const toggle = useCallback(() => {
		setValue((prev) => !prev)
		toggleCb && toggleCb()
	}, [toggleCb])

	return { value, setTrue, setFalse, toggle }
}
