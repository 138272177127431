import { DetailedHTMLProps, TextareaHTMLAttributes, useEffect } from 'react'
import { NewApplicationForm } from 'components/forms/NewApplication/types'
import { useFormikContext } from 'formik'
import { omit } from 'rambda'

import { Button, Text } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { FilesUploader } from 'components/common/FilesUploader'
import { useFilesUploader } from 'components/common/FilesUploader/useFilesUploader'
import { Grid } from 'components/common/Grid'
import { Highlight } from 'components/common/Highlight'
import { Textarea } from 'components/common/Textarea'

const TEXT = {
	PERSON: ['— Паспорт (все страницы)', '— СНИЛС'],
	ORGANIZATION: ['— Устав', '— Приказ о назначении директора'],
	FLAT: [
		'— Фото правоустанавливающего документа (договор купли-продажи, договор дарения, договор мены и тд)',
	],
}

export const Documents = () => {
	const { values, isSubmitting, setFieldValue, handleChange } =
		useFormikContext<NewApplicationForm.Values>()
	const filesUploaderData = useFilesUploader({
		onRemove: (id) => setFieldValue('documents', omit(id, values.documents)),
	})

	useEffect(() => {
		setFieldValue('documents', filesUploaderData.files.reduce((acc, { id, docId }) => {
			acc[id] = docId
			return acc
		}, {} as typeof values.documents))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filesUploaderData.files])

	const commentProps: Omit<
		DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
		'ref'
	> = {
		value: values.comment,
		placeholder: 'Укажите дополнительные сведения по заявке, которые вы считаете важными',
		name: 'comment',
		onChange: handleChange,
	}

	return (
		<Card title='Документы'>
			<Highlight variantColor='light-blue' radius={16}>
				<Text as='h5' weight='bold'>
					Физическое лицо, ИП, Директор ЮЛ
				</Text>
				{TEXT.PERSON.map((text) => (
					<Text key={text}>{text}</Text>
				))}
				<Text as='h5' weight='bold'>
					Юридическое лицо
				</Text>
				{TEXT.ORGANIZATION.map((text) => (
					<Text key={text}>{text}</Text>
				))}
				<Text as='h5' weight='bold'>
					Квартира
				</Text>
				{TEXT.FLAT.map((text) => (
					<Text key={text}>{text}</Text>
				))}
			</Highlight>
			<FilesUploader {...filesUploaderData} />
			<div>
				<Text font='body-2' indent='s'>
					Комментарий
				</Text>
				<Textarea {...commentProps} />
			</div>
			<Grid.Row sizes='auto 1fr'>
				<Button
					disabled={isSubmitting || filesUploaderData.isLoading || filesUploaderData.isInvalid}
				>
					Отправить
				</Button>
			</Grid.Row>
		</Card>
	)
}
