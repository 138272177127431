import { createMutation } from 'react-query-kit'
import { RequestError } from 'api/types'
import axios from 'axios'
import { ComCreditclubPartnerLkDtoV1DocumentMetaDto } from 'dto'

const baseUrl = '/v1/documents'

const useUploader = createMutation<
	ComCreditclubPartnerLkDtoV1DocumentMetaDto[],
	{ organizationId: string; payload: File[] },
	RequestError
>({
	mutationFn: ({ organizationId, payload }) =>
		axios
			.post(`${baseUrl}`, payload, {
				params: { organizationId },
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(({ data }) => data),
})

export const documentsApi = {
	useUploader,
}
