import { join } from '@creditclubteam/kit/helpers'

const getPath =
	<ChildrenRoutes extends (string | undefined)[]>(route: string) =>
	(...children: Partial<ChildrenRoutes>) =>
		children.length ? join([route, ...children], '/') : route

export const urls = {
	root: '/',
	applications:
		getPath<['new' | string, 'docs' | 'pledge' | 'participants' | 'decision']>('/applications'),
	profile: getPath<['information' | 'employees' | 'statistics' | 'accounts']>('/profile'),
	facilities: '/facilities',
	notifications: '/notifications',
	knowledges: '/knowledges',
	onlineSigning: '/online-signing',
	scoring: '/scoring',
	assessment: '/assessment',
	clients: '/clients',
} as const satisfies Record<string, string | any>
