import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { useBoolean } from 'hooks/useBoolean'
import { CSSObject } from 'styled-components'

import { ButtonProps } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

interface DropdownItem {
	id: string | number
	label: ReactNode
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	icon?: ReactNode
	isActive?: boolean
}

export interface DropdownProps extends PropsWithChildren {
	items: DropdownItem[]
	arrow?: boolean
	justify?: CSSObject['justifyItems']
	alignIcon?: CSSObject['alignItems']
	itemsContainerStyle?: CSSObject
	className?: string
	wrapStyle?: CSSObject
}

export const Dropdown = ({
	items,
	itemsContainerStyle,
	arrow = true,
	wrapStyle,
	children,
	className,
	alignIcon,
	justify,
}: DropdownProps) => {
	const { value, toggle, setFalse, setTrue } = useBoolean()
	const triggerRef = useRef<HTMLDivElement>(null)
	const wrapRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const onMouseDown = (e: globalThis.MouseEvent) => {
			if (!wrapRef.current?.contains(e.target as Node)) {
				if (value) setFalse()
			}
		}

		const onKeyDown = (e: globalThis.KeyboardEvent) => {
			if (e.code === 'Tab' && wrapRef.current?.contains(e.target as Node)) {
				if (value) setTrue()
			}
		}

		document.addEventListener('mousedown', onMouseDown)
		document.addEventListener('keydown', onKeyDown)

		return () => {
			document.removeEventListener('mousedown', onMouseDown)
			document.removeEventListener('keydown', onKeyDown)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const btnProps: ButtonProps = {
		fullWidth: false,
		type: 'button',
		size: 'inline',
	}

	return (
		<Styled.Wrap ref={wrapRef} override={wrapStyle} className={className}>
			<Styled.Scope>
				<Styled.TriggerZone style={{ justifyContent: justify }} ref={triggerRef} onClick={toggle}>
					{children}
					{arrow && (
						<Styled.Icon style={{ alignSelf: alignIcon }} isActive={value} icon='zmdi:chevron-down' />
					)}
				</Styled.TriggerZone>
				{value && !!items.length && (
					<Styled.Items style={itemsContainerStyle}>
						{items.map(({ id, label, onClick, isActive, icon }) => (
							<Styled.Item
								key={id}
								onClick={onClick}
								{...btnProps}
								variant={isActive ? 'transparent-blue' : 'transparent'}
							>
								{typeof icon === 'string' ? icon && <Image src={icon} /> : icon}
								{label}
							</Styled.Item>
						))}
					</Styled.Items>
				)}
			</Styled.Scope>
		</Styled.Wrap>
	)
}
