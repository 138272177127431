import { object, setLocale, string } from 'yup'

import { isEmail } from '@creditclubteam/kit/helpers'

export const FORM_ERROR_MESSAGES = {
	REQUIRED: 'Обязательное поле',
	ADDRESS: 'Заполните адрес',
	LEGAL: 'Заполните организацию',
	FULLNAME: 'Заполните имя и фамилию',
	NAME: 'Заполните имя',
	SURNAME: 'Заполните фамилию',
} as const

setLocale({ mixed: { required: FORM_ERROR_MESSAGES.REQUIRED } })

const legal = (required: boolean = true) =>
	required
		? object().test({
				test: function (value: Record<string, any>) {
					if (!value.query) return this.createError({ message: FORM_ERROR_MESSAGES.REQUIRED })
					if (!value.selected) return this.createError({ message: FORM_ERROR_MESSAGES.LEGAL })
					return true
				},
			})
		: object().test({
				test: function (value: Record<string, any>) {
					if (value.query && !value.selected)
						return this.createError({ message: FORM_ERROR_MESSAGES.LEGAL })
					return true
				},
			})

const fullName = (required: boolean = true) =>
	required
		? object().test({
				test: function (value: Record<string, any>) {
					if (!value.query) return this.createError({ message: FORM_ERROR_MESSAGES.REQUIRED })

					if (!value.manual && !value.selected)
						return this.createError({ message: FORM_ERROR_MESSAGES.FULLNAME })

					if (value.manual && !value.selected) {
						if (!value.manual.name) return this.createError({ message: FORM_ERROR_MESSAGES.NAME })
						if (!value.manual.surname)
							return this.createError({ message: FORM_ERROR_MESSAGES.SURNAME })
					} else if (!value.manual && value.selected) {
						if (!value.selected.payload?.name)
							return this.createError({ message: FORM_ERROR_MESSAGES.NAME })
						if (!value.selected.payload?.surname)
							return this.createError({ message: FORM_ERROR_MESSAGES.SURNAME })
					}

					return true
				},
			})
		: object().test({
				test: function (value: Record<string, any>) {
					if (value.query && !value.manual && !value.selected) {
						return this.createError({ message: FORM_ERROR_MESSAGES.FULLNAME })
					}

					if (value.manual && !value.selected) {
						if (!value.manual.name) return this.createError({ message: FORM_ERROR_MESSAGES.NAME })
						if (!value.manual.surname)
							return this.createError({ message: FORM_ERROR_MESSAGES.SURNAME })
					} else if (!value.manual && value.selected) {
						if (!value.selected.payload?.name)
							return this.createError({ message: FORM_ERROR_MESSAGES.NAME })
						if (!value.selected.payload?.surname)
							return this.createError({ message: FORM_ERROR_MESSAGES.SURNAME })
					}

					return true
				},
			})

const address = (required: boolean = true) =>
	required
		? object().test({
				test: function (value: Record<string, any>) {
					if (!value.query) return this.createError({ message: FORM_ERROR_MESSAGES.REQUIRED })
					if (value.query && !value.selected?.payload?.mergedAddress) {
						return this.createError({ message: FORM_ERROR_MESSAGES.ADDRESS })
					}
					return true
				},
			})
		: object().test({
				test: function (value: Record<string, any>) {
					if (value.query && !value.selected?.payload?.mergedAddress) {
						return this.createError({ message: FORM_ERROR_MESSAGES.ADDRESS })
					}
					return true
				},
			})

export const validationSchemas = {
	email: string().test({
		message: '',
		test: (value) => (value ? isEmail(value) : true),
	}),
	url: string().test({
		message: '',
		test: (value) => {
			const [url] = value?.split('?') ?? ''

			return value
				? // eslint-disable-next-line no-control-regex
					/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
						url
					)
				: true
		},
	}),
	phone: string().length(16, ''),
	bank: {
		correspondentAccount: string().length(20, ''),
		bic: string().length(9, ''),
		checkingAccount: string().length(20, ''),
	},
	organization: {
		taxId: string().length(10, ''),
		ogrn: string().length(13, ''),
		kpp: string().length(9, ''),
	},
	entrepreneur: {
		taxId: string().length(12, ''),
		ogrn: string().length(15, ''),
	},
	address,
	legal,
	fullName,
}
