import { PropsWithChildren } from 'react'

import { defaultOr } from '@creditclubteam/kit/helpers'
import { Text, TextProps } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

import { Styled } from './styled'

interface DottedRowProps extends TextProps {
	label?: React.ReactNode
	hide?: boolean
}

export const DottedRow = ({
	children,
	label,
	hide,
	...props
}: PropsWithChildren<DottedRowProps>) =>
	!hide ? (
		<Grid.Row sizes='auto 1fr auto' gap='xs' style={{ whiteSpace: 'normal' }}>
			<Text as='span' font='body-2'>
				{label}
			</Text>
			<Styled.Dots />
			<Text as='span' font='body-2' style={{ textAlign: 'right' }} {...props}>
				{defaultOr(children)}
			</Text>
		</Grid.Row>
	) : null
