import { createContext, useContext, useMemo, useState } from 'react'
import {
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoProductsEnum,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum,
} from 'dto'
import { useBoolean } from 'hooks/useBoolean'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

export const employeesCtx = createContext<
	Pick<ReturnType<typeof useEmployees>, 'variables' | 'form'>
>(null!)

export const useEmployees = () => {
	const modal = useBoolean()
	const [editableId, setEditableId] = useState<Nullable<string>>(null)
	const [page, setPage] = useState(0)
	const [text, setText] = useState('')
	const [role, setRole] = useState<
		(keyof typeof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum)[]
	>([])
	const [status, setStatus] = useState<
		keyof typeof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum | ''
	>('')
	const [products, setProducts] = useState<
		(keyof typeof ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoProductsEnum)[]
	>([])

	return useMemo(
		() => ({
			variables: {
				filter: {
					text,
					role,
					status,
					products,
				},
				params: {
					page,
					size: 8,
				},
			},
			actions: {
				setText,
				setRole,
				setStatus,
				setProducts,
				setPage,
			},
			form: {
				modal,
				editableId,
				setEditableId,
			},
			values: {
				text,
				role,
				status,
				products,
			},
		}),
		[editableId, modal, page, products, role, status, text]
	)
}

export const useEmployeesCtx = () => useContext(employeesCtx)
