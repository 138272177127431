import { CompanyForm } from 'components/forms/Company'
import { ProfileForm } from 'components/forms/Profile'

import { Grid } from 'components/common/Grid'

import { Brand } from './Brand'

export const Information = () => {
	return (
		<Grid.Row sizes='930px'>
			<ProfileForm />
			<CompanyForm />
			<Brand />
		</Grid.Row>
	)
}
