import { useNavigate } from 'react-router-dom'
import error404 from 'assets/error-404.svg'
import { urls } from 'routes/urls'
import { useTheme } from 'styled-components'

import { Button, Text } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

export const Error404 = () => {
	const { colors } = useTheme()
	const navigate = useNavigate()

	return (
		<Styled.Wrap>
			<Styled.Content is404>
				<Image src={error404} />
				<Text as='h1' font='h1' styleOverride={{ color: colors.active }}>
					Ошибка
				</Text>
				<Text indent='xxl'>Страницы не существует</Text>
				<Button type='button' onClick={() => navigate(urls.root)}>
					Назад на главную
				</Button>
			</Styled.Content>
		</Styled.Wrap>
	)
}
