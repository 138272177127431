import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Dropdown as _Dropdown } from 'components/common/Dropdown'

const Dropdown = styled(_Dropdown)(({ theme }) => ({
	...mixins.indent(theme.spaces.xxl),
}))

const Wrap = styled.div({
	display: 'flex',
	flexDirection: 'column',
})

export const Styled = {
	Dropdown,
	Wrap,
}