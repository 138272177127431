import question from 'assets/question.svg'

import { Tooltip, TooltipProps } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

export interface HintProps extends Pick<TooltipProps, 'element' | 'config' | 'popperOptions'> {}

export const Hint = (props: HintProps) => {
	const tooltipProps: TooltipProps = {
		...props,
		variant: 'gray',
		triggerElement: ({ setTriggerRef }) => (
			<Image ref={setTriggerRef} src={question} style={{ width: 18, height: 18 }} />
		),
	}

	return <Tooltip {...tooltipProps} />
}
