import { useCallback } from 'react'
import { useCompanyFormCtx } from 'components/forms/Company/useCompanyForm'
import { FormikComputedProps, FormikHelpers } from 'formik'

import { Button, ButtonProps } from '@creditclubteam/kit/ui-components'
import { Controls, ControlsProps } from 'components/common/Controls'
import { notice } from 'components/common/Notice'

export type CompanyControlsProps = Pick<
	FormikHelpers<any> & FormikComputedProps<any>,
	'resetForm'
> & {
	isLoading: boolean
}

export const CompanyControls = ({ resetForm, isLoading }: CompanyControlsProps) => {
	const {
		isNotStarted,
		isFailed,
		isUserHasAccess,
		isVerified,
		controls: { edit, handleCancel, handleEdit },
	} = useCompanyFormCtx()

	const disableSubmit = isLoading

	const controlsProps: ControlsProps = {
		edit,
		cancelProps: {
			onClick: useCallback(() => {
				resetForm()
				handleCancel()
			}, [handleCancel, resetForm]),
		},
		editProps: {
			onClick: handleEdit,
		},
		submitProps: {
			disabled: disableSubmit,
		},
	}

	const submitProps: ButtonProps = {
		fullWidth: false,
		disabled: disableSubmit,
		styleOverride: { width: 'fit-content' },
	}

	const verifiedBtnProps: ButtonProps = {
		fullWidth: false,
		type: 'button',
		onClick: () =>
			notice({
				type: 'error',
				message: 'Для редактирования профиля обратитесь к персональному менеджеру',
			}),
		styleOverride: { width: 'fit-content' },
	}

	return (
		<>
			{isUserHasAccess && (
				<>
					{isVerified && <Button {...verifiedBtnProps}>Редактировать</Button>}
					{isNotStarted && <Button {...submitProps}>Отправить на проверку</Button>}
					{isFailed && <Controls {...controlsProps} />}
				</>
			)}
		</>
	)
}
