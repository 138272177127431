import { PropsWithChildren, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'

import { Pagination, PaginationProps, Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

const Content = styled.div(({ theme }) => ({
	position: 'relative',
	boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
	borderRadius: theme.borderRadius.s,

	'> div:not(:first-child)': {
		borderTop: `1px solid ${theme.colors.border}`,
	},

	'> div:first-child': {
		borderTopLeftRadius: theme.borderRadius.s,
		borderTopRightRadius: theme.borderRadius.s,
	},

	'> div:nth-last-child(3)': {
		borderBottomLeftRadius: theme.borderRadius.s,
		borderBottomRightRadius: theme.borderRadius.s,
	},
}))

export interface ListProps {
	cols?: ReactNode[]
	hidden?: boolean
	sizes?: string
	paginationProps: PaginationProps
}

export const List = ({
	sizes,
	hidden,
	paginationProps,
	cols,
	children,
}: PropsWithChildren<ListProps>) => {
	const theme = useTheme()

	if (hidden) return null

	return (
		<Grid.Section gap='m'>
			{!!cols?.length && (
				<Grid.Row
					gap='l'
					sizes={sizes}
					style={{
						alignItems: 'center',
						padding: `0 ${theme.spaces.l}px`,
					}}
				>
					{cols.map((label, idx) => (
						<Text key={idx} as='span' variant='secondary' font='small/12'>
							{label}
						</Text>
					))}
				</Grid.Row>
			)}
			<Grid.Section>
				<Content>{children}</Content>
				<Pagination {...paginationProps} />
			</Grid.Section>
		</Grid.Section>
	)
}
