import styled from 'styled-components'

const Wrap = styled.div(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: theme.spaces.xl,
}))

export const Styled = {
	Wrap,
}