import { createBrowserRouter } from 'react-router-dom'
import { Applications } from 'components/pages/Applications'
import { List as ApplicationsList } from 'components/pages/Applications/List'
import { New as ApplicationsNew } from 'components/pages/Applications/New'
import { Single as ApplicationsSingle } from 'components/pages/Applications/Single'
import { Decision } from 'components/pages/Applications/Single/Tabs/Decision'
import { Docs } from 'components/pages/Applications/Single/Tabs/Docs'
import { Profile } from 'components/pages/Profile'
import { Employees } from 'components/pages/Profile/Tabs/Employees'
import { Information } from 'components/pages/Profile/Tabs/Information'
import { ErrorBoundary } from 'components/standalone/ErrorBoundary'
import { Layout } from 'components/standalone/Layout'
import { AppDataProvider } from 'providers/AppDataProvider'

import { urls } from './urls'

export const router = createBrowserRouter([
	{
		path: urls.root,
		element: <AppDataProvider />,
		ErrorBoundary,
		children: [
			{
				element: <Layout />,
				children: [
					{
						path: urls.profile(),
						element: <Profile />,
						children: [
							{
								path: urls.profile('information'),
								element: <Information />,
							},
							{
								path: urls.profile('employees'),
								element: <Employees />,
							},
							{
								path: urls.profile('statistics'),
								element: 'statistics',
							},
							{
								path: urls.profile('accounts'),
								element: 'accounts',
							},
						],
					},
					{
						path: urls.applications(),
						element: <Applications />,
						children: [
							{
								element: <ApplicationsSingle />,
								path: urls.applications(':id'),
								handle: { title: 'Заявка', back: urls.applications() },
								children: [
									{
										path: urls.applications(':id', 'decision'),
										element: <Decision />,
									},
									{
										path: urls.applications(':id', 'docs'),
										element: <Docs />,
									},
								],
							},
							{
								element: <ApplicationsList />,
								path: urls.applications(),
								handle: { title: 'Заявки' },
							},
							{
								element: <ApplicationsNew />,
								path: urls.applications('new'),
								handle: { title: 'Новая заявка', back: urls.applications() },
							},
						],
					},
				],
			},
		],
	},
])
