import { useState } from 'react'
import { getInputProps } from 'styles/theme'

import { Button, Input, useAutocomplete } from '@creditclubteam/kit/ui-components'
import { Modal, ModalProps } from 'components/common/Modal'

type FieldNames = 'name' | 'surname' | 'patronymic'

export interface FullNameModalProps {
	isOpen: boolean
	onClose: () => void
	onChange: (value: Record<FieldNames, string>) => void
}

export const FullNameModal = ({ isOpen, onChange, onClose }: FullNameModalProps) => {
	const modalProps: ModalProps = {
		isOpen,
		onClose,
		onAfterClose: () => {
			setSurname('')
			setName('')
			setPatronymic('')
		},
		title: 'Ручной ввод ФИО',
	}

	const [surname, setSurname] = useState('')
	const [name, setName] = useState('')
	const [patronymic, setPatronymic] = useState('')

	const surnameProps = getInputProps({
		label: 'Фамилия',
		placeholder: 'Иванов',
		value: surname,
		indent: 'l',
		error: !surname,
		onChange: ({ target }) => setSurname(target.value),
		autocompleteConfig: useAutocomplete({
			request: {
				method: 'POST',
				url: '/v1/suggestions/full-name',
				data: {
					query: surname,
					parts: ['SURNAME'],
				},
			},
			selected: null,
			query: surname,
			onSelect: (option) => setSurname(option.id as string),
			getOptionItem: (option) => ({
				id: option.fullName!,
				label: option.fullName,
				payload: option,
			}),
		}),
	})

	const nameProps = getInputProps({
		label: 'Имя',
		placeholder: 'Иван',
		value: name,
		error: !name,
		indent: 'l',
		onChange: ({ target }) => setName(target.value),
		autocompleteConfig: useAutocomplete({
			request: {
				method: 'POST',
				url: '/v1/suggestions/full-name',
				data: {
					query: name,
					parts: ['NAME'],
				},
			},
			selected: null,
			query: name,
			onSelect: (option) => setName(option.id as string),
			getOptionItem: (option) => ({
				id: option.fullName!,
				label: option.fullName,
				payload: option,
			}),
		}),
	})

	const patronymicProps = getInputProps({
		value: patronymic,
		label: 'Отчество',
		placeholder: 'Иванович',
		indent: 'xl',
		onChange: ({ target }) => setPatronymic(target.value),
		autocompleteConfig: useAutocomplete({
			request: {
				method: 'POST',
				url: '/v1/suggestions/full-name',
				data: {
					query: patronymic,
					parts: ['PATRONYMIC'],
				},
			},
			selected: null,
			query: patronymic,
			onSelect: (option) => setPatronymic(option.id as string),
			getOptionItem: (option) => ({
				id: option.fullName!,
				label: option.fullName,
				payload: option,
			}),
		}),
	})

	const handleSave = () => {
		onChange({ name, surname, patronymic })
		onClose()
	}

	return (
		<Modal {...modalProps}>
			<Input {...surnameProps} />
			<Input {...nameProps} />
			<Input {...patronymicProps} />
			<Button {...{ type: 'button', disabled: !name || !surname, onClick: handleSave }}>
				Сохранить
			</Button>
		</Modal>
	)
}
