/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto } from './com-creditclub-partner-lk-dto-v1-application-request-participant-participant-data-dto';
import {
    ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto,
} from ".";

/**
 * 
 *
 * @export
 * @interface PersonParticipantDataDto
 */
export interface PersonParticipantDataDto extends ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto {

    /**
     * @type {Array<string>}
     * @memberof PersonParticipantDataDto
     */
    roles: Array<PersonParticipantDataDtoRolesEnum>;

    /**
     * Тип запроса согласия на КИ: * `PAPER` – Согласие на бумаге * `ESIA` – Через Госуслуги * `SMS` – Подписание согласия через СМС 
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    creditHistoryConsentType: PersonParticipantDataDtoCreditHistoryConsentTypeEnum;

    /**
     * Имя
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    name: string;

    /**
     * Фамилия
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    surname: string;

    /**
     * Отчество
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    patronymic?: string;

    /**
     * Телефон. Формат 10 цифр, без +7 или 8
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    phone?: string;

    /**
     * Почта
     *
     * @type {string}
     * @memberof PersonParticipantDataDto
     */
    email?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonParticipantDataDtoRolesEnum {
    BORROWER = 'BORROWER',
    OWNER = 'OWNER',
    WARRANTOR = 'WARRANTOR'
}
/**
 * @export
 * @enum {string}
 */
export enum PersonParticipantDataDtoCreditHistoryConsentTypeEnum {
    PAPER = 'PAPER',
    ESIA = 'ESIA',
    SMS = 'SMS'
}

