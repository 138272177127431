import { always, anyPass, both, compose, equals, identity, ifElse, is, isEmpty, not, trim } from 'rambda'

export const defaultToApi = ifElse(
	anyPass([
		Number.isInteger,
		both(is(String), compose(not, isEmpty, trim)),
		is(Boolean),
		is(Object),
	]),
	ifElse(is(String), trim, identity),
	always(null)
)

export const numberOrNull = ifElse(equals(''), always(null), Number)
