import { ReactNode, useState } from 'react'
import { NavLink } from 'react-router-dom'
import iconAccount from 'assets/tabs/account.svg'
import iconActiveAccount from 'assets/tabs/activeAccount.svg'
import iconActiveCase from 'assets/tabs/activeCase.svg'
import iconActiveChart from 'assets/tabs/activeChart.svg'
import iconActiveCheckBroken from 'assets/tabs/activeCheckBroken.svg'
import iconActiveGear from 'assets/tabs/activeGear.svg'
import iconActiveUsers from 'assets/tabs/activeUsers.svg'
import iconBusinessman from 'assets/tabs/businessman.svg'
import iconBusinessmanActive from 'assets/tabs/businessmanActive.svg'
import iconCase from 'assets/tabs/case.svg'
import iconChart from 'assets/tabs/chart.svg'
import iconCheckBroken from 'assets/tabs/checkBroken.svg'
import iconDoc from 'assets/tabs/doc.svg'
import iconDocActive from 'assets/tabs/docActive.svg'
import iconGear from 'assets/tabs/gear.svg'
import iconPerson from 'assets/tabs/person.svg'
import iconPersonActive from 'assets/tabs/personActive.svg'
import iconUsers from 'assets/tabs/users.svg'

import { ButtonProps } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

interface Tab {
	label: ReactNode
	icon?: keyof typeof ICONS
}

interface NavItem extends Tab {
	href: string
	isVisible?: boolean
	end?: boolean
}

interface TabItem extends Tab {
	isActive: boolean
	isVisible?: boolean
	onClick?: () => void
}

export interface TabsProps {
	navItems?: NavItem[]
	items?: TabItem[]
}

const getIconProps = (v1: string, v2: string) => ({
	default: v1,
	active: v2,
})

const ICONS = {
	doc: getIconProps(iconDoc, iconDocActive),
	person: getIconProps(iconPerson, iconPersonActive),
	businessman: getIconProps(iconBusinessman, iconBusinessmanActive),
	checkBroken: getIconProps(iconCheckBroken, iconActiveCheckBroken),
	gear: getIconProps(iconGear, iconActiveGear),
	case: getIconProps(iconCase, iconActiveCase),
	chart: getIconProps(iconChart, iconActiveChart),
	users: getIconProps(iconUsers, iconActiveUsers),
	account: getIconProps(iconAccount, iconActiveAccount),
} as const

const NavTab = ({ href, icon, end, isVisible = true, label }: NavItem) => {
	const btnProps: ButtonProps = {
		fullWidth: false,
		size: 'min',
		type: 'button',
	}

	const [isHover, setIsHover] = useState(false)

	if (!isVisible) return null

	return (
		<NavLink
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			to={{ pathname: href, search: window.location.search }}
			end={end}
		>
			{({ isActive }) => (
				<Styled.Button {...btnProps} variant={isActive ? 'transparent-blue' : 'transparent'}>
					{icon && <Image src={isActive || isHover ? ICONS[icon].active : ICONS[icon].default} />}
					{label}
				</Styled.Button>
			)}
		</NavLink>
	)
}

const TabItem = ({ icon, onClick, isVisible = true, isActive, label }: TabItem) => {
	const btnProps: ButtonProps = {
		fullWidth: false,
		onClick,
		type: 'button',
		size: 'min',
	}

	const [isHover, setIsHover] = useState(false)

	if (!isVisible) return null

	return (
		<Styled.Button
			{...btnProps}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			variant={isActive ? 'transparent-blue' : 'transparent'}
		>
			{icon && <Image src={isActive || isHover ? ICONS[icon].active : ICONS[icon].default} />}
			{label}
		</Styled.Button>
	)
}

export const Tabs = ({ items, navItems }: TabsProps) => {
	return (
		<Styled.Wrap>
			{navItems?.map((item) => <NavTab key={item.href} {...item} />)}
			{items?.map((item, idx) => <TabItem key={idx} {...item} />)}
		</Styled.Wrap>
	)
}
