import { PropsWithChildren } from 'react'
import { Props } from 'react-modal'

import { Text } from '@creditclubteam/kit/ui-components'

import close from './close.svg'
import { Styled } from './styled'

export interface ModalProps {
	isOpen: boolean
	title?: string
	showCloseButton?: boolean
	subText?: React.ReactNode
	className?: string
	overlayClassName?: string
	headerClassName?: string
	closeTimeoutMS?: number
	shouldCloseOnOverlayClick?: boolean

	onClose: () => void
	onAfterClose?: () => void
}

// ReactModal.setAppElement('#root')

export const Modal = (props: PropsWithChildren<ModalProps>) => {
	const {
		isOpen,
		onClose,
		onAfterClose,
		shouldCloseOnOverlayClick,
		children,
		title,
		showCloseButton,
		subText,
	} = props

	const modalProps: Props = {
		isOpen,
		ariaHideApp: false,
		onRequestClose: onClose,
		onAfterClose,
		shouldCloseOnOverlayClick,
		closeTimeoutMS: 500,
	}

	return (
		<Styled.Overlay>
			{(className) => (
				<Styled.Modal {...modalProps} overlayClassName={className}>
					{showCloseButton && <Styled.Close src={close} onClick={onClose} />}
					{(title || subText) && (
						<Styled.Header>
							{title && (
								<Text
									font='h3'
									weight='bold'
									indent={subText ? 's' : undefined}
									styleOverride={{ maxWidth: '95%' }}
								>
									{title}
								</Text>
							)}
							{subText}
						</Styled.Header>
					)}
					{children}
				</Styled.Modal>
			)}
		</Styled.Overlay>
	)
}

Modal.defaultProps = {
	shouldCloseOnOverlayClick: true,
	closeTimeoutMS: 500,
	showCloseButton: true,
}
