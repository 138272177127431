import { useCallback } from 'react'
import trash from 'assets/trash.svg'
import { FullNameModal, FullNameModalProps } from 'components/forms/FullName'
import { newApplicationHelpers } from 'components/forms/NewApplication/helpers'
import { NewApplicationForm } from 'components/forms/NewApplication/types'
import {
	ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum,
	ComCreditclubPartnerLkServiceSuggestionFullNameResult,
	PersonParticipantDataDtoCreditHistoryConsentTypeEnum,
	PersonParticipantDataDtoRolesEnum,
} from 'dto'
import { ArrayHelpers, FieldArray, FormikProps } from 'formik'
import { useBoolean } from 'hooks/useBoolean'
import { difference } from 'rambda'
import { useTheme } from 'styled-components'
import { getInputProps, getSelectProps } from 'styles/theme'

import { join } from '@creditclubteam/kit/helpers'
import {
	Button,
	ButtonProps,
	Input,
	Select,
	useAutocomplete,
} from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'
import { Highlight } from 'components/common/Highlight'
import { Image } from 'components/common/Image'

const TEXT = {
	[PersonParticipantDataDtoCreditHistoryConsentTypeEnum.ESIA]:
		'Клиент получит SMS-сообщение со ссылкой на подписание Согласия, используя Госуслуги. После подписания вы сможете сразу посмотреть решение с результатом автоматизированных проверок.',
	[PersonParticipantDataDtoCreditHistoryConsentTypeEnum.SMS]:
		'После обработки заявки Оператором клиент получит SMS-сообщение со ссылкой для подписания Согласия.',
	[PersonParticipantDataDtoCreditHistoryConsentTypeEnum.PAPER]:
		'После обработки заявки Оператором клиентский менеджер отправит вам шаблон согласия для подписания клиентом. Подписанное согласие загрузите в Документы.',
	'': 'Для быстрого получения решения по заявке выберите способ подписания согласия через Госуслуги.',
}

const FullNameInput = ({
	values,
	setFieldValue,
	handleBlur,
	idx,
	errors,
	touched,
}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) => {
	const { setFalse, setTrue, value } = useBoolean()

	const manualProps: FullNameModalProps = {
		onChange: (value) =>
			setFieldValue(`participants[${idx}].fullName`, {
				selected: {
					id: join([value.surname, value.name, value.patronymic], ' '),
					label: join([value.surname, value.name, value.patronymic], ' '),
					payload: value,
				},
				query: join([value.surname, value.name, value.patronymic], ' '),
			}),
		isOpen: value,
		onClose: setFalse,
	}

	const theme = useTheme()

	const fullNameAutocompleteConfig = useAutocomplete<
		ComCreditclubPartnerLkServiceSuggestionFullNameResult,
		ComCreditclubPartnerLkServiceSuggestionFullNameResult
	>({
		categories: [
			{ id: 'empty', label: 'Подсказка по ФИО' },
			{ id: 'manual', label: null },
		],
		renderOption: ({ render, onSelect, option }) =>
			render(
				option.id === 'manual'
					? {
							children: 'Ввести ФИО вручную',
							onSelect: setTrue,
							styleOverride: {
								':hover': { color: theme.colors.activeHovered },
								color: theme.colors.active,
							},
						}
					: {
							children: option.label,
							onSelect,
						}
			),
		request: {
			url: '/v1/suggestions/full-name',
			method: 'post',
			data: {
				query: values.participants[idx].fullName.query,
			},
		},
		getOptionItem: (option, id) => ({
			id,
			label: option.fullName,
			payload: option,
			categoryId: 'empty',
		}),
		query: values.participants[idx].fullName.query,
		selected: values.participants[idx].fullName.selected,
		onSelect: (option) =>
			setFieldValue(`participants[${idx}].fullName`, {
				query: option.label,
				selected: option,
				manual: null,
			}),
	})

	return (
		<>
			<FullNameModal {...manualProps} />
			<Input
				{...getInputProps({
					error: !!errors.participants?.[idx]?.fullName && !!touched.participants?.[idx]?.fullName,
					errorMessage: errors.participants?.[idx]?.fullName as string,
					onFocus: handleBlur,
					name: `participants[${idx}].fullName`,
					label: 'ФИО участника',
					placeholder: 'Введите имя',
					value: values.participants[idx].fullName.query,
					onChange: ({ target }) =>
						setFieldValue(`participants[${idx}].fullName`, {
							query: target.value,
							selected: null,
							manual: null,
						}),
					autocompleteConfig: {
						...fullNameAutocompleteConfig,
						options: [
							...fullNameAutocompleteConfig.options,
							{ id: 'manual', categoryId: 'manual', label: null },
						],
					},
				})}
			/>
		</>
	)
}

export const Participants = () => {
	const getRolesProps = useCallback(
		({
			errors,
			setFieldValue,
			handleBlur,
			values,
			touched,
			idx,
		}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) =>
			getSelectProps<true>({
				label: 'Роль',
				multi: true,
				name: `participants[${idx}].roles`,
				onFocus: handleBlur,
				hideOptionsAfterSelect: false,
				options: [
					{
						id: PersonParticipantDataDtoRolesEnum.BORROWER,
						label: 'Заёмщик',
						disabled:
							values.participants[idx].roles.includes(
								PersonParticipantDataDtoRolesEnum.WARRANTOR
							) ||
							values.participants.some(
								({ roles }, roleIdx) =>
									roleIdx !== idx && roles.includes(PersonParticipantDataDtoRolesEnum.BORROWER)
							),
					},
					{
						id: PersonParticipantDataDtoRolesEnum.OWNER,
						label: 'Залогодатель',
						disabled:
							difference(
								[
									PersonParticipantDataDtoRolesEnum.BORROWER,
									PersonParticipantDataDtoRolesEnum.WARRANTOR,
								],
								values.participants[idx].roles
							).length === 0,
					},
					{
						id: PersonParticipantDataDtoRolesEnum.WARRANTOR,
						label: 'Поручитель',
						disabled: values.participants[idx].roles.includes(
							PersonParticipantDataDtoRolesEnum.BORROWER
						),
					},
				],
				errorMessage: errors.participants?.[idx]?.roles,
				error: 'roles' in (errors.participants?.[idx] ?? {}) && touched.participants?.[idx]?.roles,
				value: values.participants[idx].roles,
				onChange: ({ value }) => setFieldValue(`participants[${idx}].roles`, value),
			}),
		[]
	)

	const getTypeProps = useCallback(
		({ setFieldValue, values, idx }: FormikProps<NewApplicationForm.Values> & { idx: number }) =>
			getSelectProps({
				label: 'Тип',
				options: [
					{
						id: ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum.PERSON,
						label: 'Физическое лицо',
					},
					// {
					// 	id: ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum.ORGANIZATION,
					// 	label: 'Юридическое лицо',
					// },
					// {
					// 	id: ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum.ENTREPRENEUR,
					// 	label: 'Индвидуальный предприниматель',
					// },
				],
				value: values.participants[idx].type,
				onChange: ({ value }) => setFieldValue(`participants[${idx}].type`, value),
			}),
		[]
	)

	const getCreditHistoryConsentTypeProps = useCallback(
		({
			setFieldValue,
			values,
			errors,
			handleBlur,
			touched,
			idx,
		}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) =>
			getSelectProps<false>({
				onFocus: handleBlur,
				name: `participants[${idx}].creditHistoryConsentType`,
				placeholder: 'Выберите из списка',
				label: 'Согласие на кредитную историю',
				errorMessage: errors.participants?.[idx]?.creditHistoryConsentType,
				error:
					'creditHistoryConsentType' in (errors.participants?.[idx] ?? {}) &&
					touched.participants?.[idx]?.creditHistoryConsentType,
				options: [
					{
						id: PersonParticipantDataDtoCreditHistoryConsentTypeEnum.ESIA,
						label: 'Через Госуслуги',
					},
					{
						id: PersonParticipantDataDtoCreditHistoryConsentTypeEnum.SMS,
						label: 'Подписание согласия через СМС',
					},
					{
						id: PersonParticipantDataDtoCreditHistoryConsentTypeEnum.PAPER,
						label: 'Согласие на бумаге',
					},
				],
				value: values.participants[idx].creditHistoryConsentType,
				onChange: ({ value }) =>
					setFieldValue(`participants[${idx}].creditHistoryConsentType`, value),
			}),
		[]
	)

	const getPhoneProps = useCallback(
		({
			errors,
			touched,
			setFieldValue,
			values,
			handleBlur,
			idx,
		}: FormikProps<NewApplicationForm.Values> & {
			idx: number
			errors: any
		}) =>
			getInputProps({
				label: 'Номер телефона',
				onFocus: handleBlur,
				mask: '+7 999-999-99-99',
				name: `participants[${idx}].phone`,
				errorMessage: errors.participants?.[idx]?.phone,
				error: 'phone' in (errors.participants?.[idx] ?? {}) && touched.participants?.[idx]?.phone,
				value: values.participants[idx].phone,
				onChange: ({ target }) => setFieldValue(`participants[${idx}].phone`, target.value),
				placeholder: '+7 999 999-99-99',
			}),
		[]
	)

	const getEmailProps = useCallback(
		({
			errors,
			touched,
			setFieldValue,
			values,
			handleBlur,
			idx,
		}: FormikProps<NewApplicationForm.Values> & {
			idx: number
			errors: any
		}) =>
			getInputProps({
				label: 'Электронная почта',
				onFocus: handleBlur,
				name: `participants[${idx}].email`,
				error: 'email' in (errors.participants?.[idx] ?? {}) && touched.participants?.[idx]?.email,
				value: values.participants[idx].email,
				onChange: ({ target }) => setFieldValue(`participants[${idx}].email`, target.value),
				placeholder: 'Email',
			}),
		[]
	)

	const getRemoveProps = useCallback(
		({ remove, idx }: Pick<ArrayHelpers, 'remove'> & { idx: number }): ButtonProps => ({
			variant: 'transparent-blue',
			size: 'inline',
			type: 'button',
			styleOverride: { padding: 0 },
			onClick: () => remove(idx),
			children: (
				<Grid.Row style={{ alignItems: 'center' }} gap='s' sizes='auto 1fr'>
					<Image src={trash} />
					<span>Удалить участника</span>
				</Grid.Row>
			),
		}),
		[]
	)

	const getPushProps = useCallback(
		({ push }: Pick<ArrayHelpers, 'push'>): ButtonProps => ({
			size: 'min',
			type: 'button',
			onClick: () => push(newApplicationHelpers.createParticipant()),
			children: 'Добавить ещё участника',
		}),
		[]
	)

	return (
		<Card title='Участник'>
			<Grid.Section>
				<FieldArray name='participants'>
					{({
						form,
						push,
						remove,
					}: ArrayHelpers & { form: FormikProps<NewApplicationForm.Values> }) => (
						<>
							{form.values.participants.map((_, idx) => (
								<Grid.Section key={idx}>
									<Grid.Row sizes='1fr 1fr 1fr'>
										<Select {...getRolesProps({ ...form, idx })} />
										<Select {...getTypeProps({ ...form, idx })} />
										<Select {...getCreditHistoryConsentTypeProps({ ...form, idx })} />
									</Grid.Row>
									<Grid.Row sizes='2fr 1fr'>
										<Highlight variantColor='yellow' font='body-2'>
											{TEXT[form.values.participants[idx].creditHistoryConsentType]}
										</Highlight>
									</Grid.Row>
									<Grid.Row sizes='1fr'>
										<FullNameInput {...form} idx={idx} />
									</Grid.Row>
									<Grid.Row>
										<Input {...getPhoneProps({ ...form, idx })} />
										<Input {...getEmailProps({ ...form, idx })} />
									</Grid.Row>
									{idx !== 0 && (
										<Grid.Row sizes='auto 1fr'>
											<Button {...getRemoveProps({ remove, idx })} />
										</Grid.Row>
									)}
									{form.values.participants.length - 1 !== idx && <Grid.Divider />}
								</Grid.Section>
							))}
							{form.values.participants.length < 10 && (
								<>
									<Grid.Divider />
									<Grid.Row sizes='auto 1fr'>
										<Button {...getPushProps({ push })} />
									</Grid.Row>
								</>
							)}
						</>
					)}
				</FieldArray>
			</Grid.Section>
		</Card>
	)
}
