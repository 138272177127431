import { Outlet, useParams } from 'react-router-dom'
import { urls } from 'routes/urls'

import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { Tabs, TabsProps } from 'components/common/Tabs'

export const Single = () => {
	const { id } = useParams()

	const tabsProps: TabsProps = {
		navItems: [
			{
				label: 'РЕШЕНИЕ',
				icon: 'checkBroken',
				href: urls.applications(id, 'decision'),
			},
			// {
			// 	label: 'УЧАСТНИКИ',
			// 	href: urls.applications(id, 'participants'),
			// },
			// {
			// 	label: 'ОБЪЕКТ ЗАЛОГА',
			// 	href: urls.applications(id, 'pledge'),
			// },
			{
				label: 'ДОКУМЕНТЫ',
				icon: 'doc',
				href: urls.applications(id, 'docs'),
			},
		],
	}

	return (
		<FadeIn>
			<Grid.Section>
				<Tabs {...tabsProps} />
				<Grid.Row sizes='930px'>
					<Outlet />
				</Grid.Row>
			</Grid.Section>
		</FadeIn>
	)
}
