import { FormEvent, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { applicationsApi } from 'api/applications'

import { Button } from '@creditclubteam/kit/ui-components'
import { FadeIn } from 'components/common/FadeIn'
import { FilesUploader } from 'components/common/FilesUploader'
import { useFilesUploader } from 'components/common/FilesUploader/useFilesUploader'
import { Grid } from 'components/common/Grid'

export const Docs = () => {
	const { id } = useParams()
	const { mutateAsync, isPending } = applicationsApi.useUploadDocuments()

	const filesUploaderData = useFilesUploader({})

	const handleSubmit = useCallback(
		async (event: FormEvent) => {
			event.preventDefault()

			try {
				await mutateAsync({
					id: id!,
					payload: { documents: filesUploaderData.files.map(({ docId }) => docId!) },
				})

				filesUploaderData.reset()
			} catch {
				//
			}
		},
		[mutateAsync, id, filesUploaderData]
	)

	return (
		<FadeIn>
			<form autoComplete='off' onSubmit={handleSubmit}>
				<Grid.Section>
					<FilesUploader {...filesUploaderData} />
					<Grid.Row sizes='auto 1fr'>
						<Button
							disabled={isPending || filesUploaderData.isEmpty || filesUploaderData.isLoading}
						>
							Отправить
						</Button>
					</Grid.Row>
				</Grid.Section>
			</form>
		</FadeIn>
	)
}
