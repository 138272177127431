import { MouseEvent, PropsWithChildren, ReactNode } from 'react'
import { CSSObject, useTheme } from 'styled-components'

import { Text } from '@creditclubteam/kit/ui-components'

import { Styled } from './styled'

export interface CardProps extends PropsWithChildren {
	title?: ReactNode
	variant?: 'default' | 'small'
	onClick?: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
	wrapStyleOverride?: CSSObject
}

export const Card = ({ title, wrapStyleOverride, onClick, variant = 'default', children }: CardProps) => {
	const { spaces } = useTheme()

	return (
		<Styled.Wrap variant={variant} onClick={onClick} override={wrapStyleOverride}>
			{title && (
				<Text
					as='h4'
					font='h4'
					styleOverride={{
						display: 'flex',
						fontWeight: 800,
						alignItems: 'center',
						gap: spaces.xl,
					}}
				>
					{title}
				</Text>
			)}
			{children}
		</Styled.Wrap>
	)
}
