import { dictionariesApi } from 'api/dictionaries'
import { NewApplicationForm } from 'components/forms/NewApplication/types'
import { useFormikContext } from 'formik'
import { getInputProps, getSelectProps } from 'styles/theme'

import { Input, Select } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'
import { inputFormatting } from 'helpers/inputFormatting'

export const Conditions = () => {
	const { values, setFieldValue, errors, touched, handleBlur } =
		useFormikContext<NewApplicationForm.Values>()
	const { data: { purposes } = {} } = dictionariesApi.useGetApplication()

	const calculatiomAmountProps = getInputProps({
		label: 'Сумма',
		onFocus: handleBlur,
		name: 'calculationAmount',
		errorMessage: errors.calculationAmount,
		error: 'calculationAmount' in errors && touched.calculationAmount,
		value: inputFormatting.format({ value: values.calculationAmount }),
		onChange: ({ target }) =>
			setFieldValue('calculationAmount', inputFormatting.unformat({ value: target.value })),
		placeholder: '1 000 000 руб',
	})

	const calculatiomDurationProps = getInputProps({
		label: 'Срок',
		mask: '999',
		onFocus: handleBlur,
		name: 'calculationDuration',
		errorMessage: errors.calculationDuration,
		error: 'calculationDuration' in errors && touched.calculationDuration,
		value: values.calculationDuration,
		onChange: ({ target }) => setFieldValue('calculationDuration', target.value),
		placeholder: '5 лет',
	})

	const purposeProps = getSelectProps({
		label: 'Цель',
		name: 'purpose',
		onFocus: handleBlur,
		errorMessage: errors.purpose,
		error: 'purpose' in errors && touched.purpose,
		placeholder: 'Выберите из списка',
		optionsWrapperMaxHeight: 250,
		options: purposes?.map(({ id, title }) => ({ id, label: title })) ?? [],
		value: values.purpose,
		onChange: ({ value }) => setFieldValue('purpose', value),
	})

	return (
		<Card title='Условия'>
			<Grid.Row sizes='1fr 1fr 1.5fr'>
				<Input {...calculatiomAmountProps} />
				<Input {...calculatiomDurationProps} />
				<Select {...purposeProps} />
			</Grid.Row>
		</Card>
	)
}
