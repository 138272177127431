import { createTheme } from '@mui/material'
import { createGlobalStyle } from 'styled-components'

import {
	ColorNames,
	MediaBreakpoints,
	mixins,
	Spaces,
	ThemeController,
} from '@creditclubteam/kit/styled'
import { InputProps, SelectProps } from '@creditclubteam/kit/ui-components'

export type PartnerThemeFonts = 'h3' | 'small/12' | 'tab' | 'nav' | 'body-2' | 'h4' | 'h5' | 'body/14'

export const getSelectProps = <T1 extends boolean, T2 = any>(
	props: SelectProps<T1, T2>
): SelectProps<T1, T2> => ({
	...props,
	labelPlacing: props.labelPlacing ?? 'out',
	selectStyleOverride:
		props.labelPlacing === 'in'
			? {
					fontSize: 16,
					lineHeight: '24px',
				}
			: undefined,
	labelStyleOverride:
		props.labelPlacing === 'in' ? undefined : { fontSize: 16, lineHeight: '120%' },
})

export const getInputProps = <T,>(props: InputProps<T>): InputProps<T> => ({
	...props,
	labelPlacing: props.labelPlacing ?? 'out',
	labelStyleOverride: { fontSize: 16, lineHeight: '120%', ...props?.labelStyleOverride },
})

export const theme = new ThemeController<PartnerThemeFonts, Spaces, MediaBreakpoints, ColorNames>({
	defaults: {
		Input: { normal: { borderRadius: 8 }, min: { borderRadius: 8 } },
		Select: { normal: { borderRadius: 8 }, min: { borderRadius: 8 } },
		Button: { normal: { borderRadius: 8 }, min: { borderRadius: 8 } },
		Tooltip: { borderRadius: 8, padding: 16 },
	},
	theme: {
		colors: {
			positive: '#25D366',
			border: '#b3b3b3',
			backgroundSecondary: '#b3b3b3',
		},
		fonts: {
			large: {
				css: {
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			'body-2': {
				css: {
					fontSize: '16px',
					lineHeight: '120%',
				},
			},
			buttonLarge: {
				css: {
					fontSize: '18px',
					lineHeight: '24px',
					fontWeight: '700',
				},
			},
			'small/12': {
				css: {
					lineHeight: '120%',
					fontSize: '12px',
				},
			},
			tab: {
				css: {
					fontSize: '14px',
					liheHeight: '16px',
					fontWeight: '700',
				},
			},
			'body/14': {
				css: {
					fontSize: '14px',
					lineHeight: '16px',
				},
			},
			pageBody: {
				css: {
					fontSize: '18px',
					lineHeight: '120%',
				},
			},
			nav: {
				css: {
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			h1: {
				css: {
					fontSize: '48px',
					fontWeight: '800',
					lineHeight: '120%',
				},
			},
			h3: {
				css: {
					fontSize: '32px',
					fontWeight: '800',
					lineHeight: '120%',
				},
			},
			h4: {
				css: {
					fontSize: '24px',
					fontWeight: '800',
					lineHeight: '120%',
				},
			},
			h5: {
				css: {
					fontSize: '18px',
					fontWeight: '800',
					lineHeight: '120%',
				},
			},
		},
	},
})

export const preparedTheme = theme.prepareTheme()

export const GlobalStyles = createGlobalStyle(({ theme }) => ({
	'*': {
		fontFamily: 'Uni Neue',
	},

	'.Toastify__toast-container': {
		'.Toastify__toast': {
			'&:last-child': {
				marginBottom: 0,
			},
		},
	},

	'.Toastify__toast-container--top-right': {
		top: theme.spaces.l,
		right: theme.spaces.l,
	},

	'.Toastify__toast': {
		margin: `0 ${theme.spaces.l}px`,

		...mixins.media(theme.breakpoints.tablet, {
			margin: 0,
			marginBottom: theme.spaces.l,
		}),

		marginBottom: theme.spaces.l,
		borderRadius: 16,
		boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',

		'&.--error': {
			background: '#FFCFCB',
		},

		'&.--info': {
			background: 'white',
		},

		'&.--success': {
			background: '#C5F7DC',
		},
	},

	'.Toastify__close-button > svg': {
		color: theme.colors.textSecondary,
	},

	'.Toastify__close-button': {
		opacity: 1,

		svg: {
			transition: 'color .3s ease',

			'&:hover': {
				color: theme.colors.textMain,
			},
		},
	},

	'.Toastify__progress-bar--default': {
		background: theme.colors.active,
	},

	'.ReactModal__Content': {
		opacity: 0,
		transform: 'translateY(100%)',
		transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
	},

	'.ReactModal__Body--open': { overflow: 'hidden' },

	'.ReactModal__Content--after-open': {
		opacity: 1,
		transform: 'translateY(0)',
	},

	'.ReactModal__Content--before-close': {
		opacity: 0,
		transform: 'translateY(100%)',
	},

	'.ReactModal__Overlay': {
		opacity: 0,
		transition: 'opacity 0.5s ease-in-out',
		overflowY: 'auto',
	},

	'.ReactModal__Overlay--after-open': {
		opacity: 1,
	},

	'.ReactModal__Overlay--before-close': {
		opacity: 0,
	},

	a: { textDecoration: 'none', color: 'inherit', outline: 'none' },
}))

export const materialTheme = createTheme({
	palette: {
		primary: { main: theme.getColor('active') },
	},
	typography: { fontFamily: 'Uni Neue' },
	components: {
		MuiSlider: {
			styleOverrides: {
				root: {
					'.MuiSlider-markLabel[style="left: 0%;"]': {
						transform: 'unset',
					},

					'.MuiSlider-markLabel[style="left: 100%;"]': {
						transform: 'translateX(-100%)',
					},
				},
			},
		},
	},
})
