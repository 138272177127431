import { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'
import alertCircle from 'assets/alert-circle.svg'
import checkBroken from 'assets/check-broken.svg'
import errorTriangle from 'assets/error-triangle.svg'
import clsx from 'clsx'
import { preparedTheme } from 'styles/theme'

import { Text } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

const icons = {
	error: errorTriangle,
	info: alertCircle,
	success: checkBroken,
} as Record<NonNullable<NoticeProps['type']>, string>

export interface NoticeProps {
	title?: ReactNode
	message?: ReactNode
	type?: 'error' | 'info' | 'success'
	props?: ToastOptions
}

export const notice = ({ title, message, type = 'error', props }: NoticeProps) => {
	const themeClassnames = {
		'--error': type === 'error',
		'--info': type === 'info',
		'--success': type === 'success',
	}

	return toast(
		<Styled.Wrap>
			{icons[type] && <Image src={icons[type]} />}
			<Styled.Content>
				{title && (
					<Text
						as='div'
						styleOverride={{ color: preparedTheme.colors.textMain }}
						weight='bold'
						font='body-2'
					>
						{title}
					</Text>
				)}
				{message && (
					<Text as='div' styleOverride={{ color: preparedTheme.colors.textMain }} font='body-2'>
						{message}
					</Text>
				)}
			</Styled.Content>
		</Styled.Wrap>,
		{
			...props,
			className: clsx(themeClassnames),
			type: 'default',
		}
	)
}
