import { Helmet } from 'react-helmet-async'
import { Outlet, UIMatch, useMatches, useParams } from 'react-router-dom'
import { applicationsApi } from 'api/applications'

import { Text } from '@creditclubteam/kit/ui-components'
import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { LinkWithQuery } from 'components/common/LinkWithQuery'
import { PageContent } from 'components/common/PageContent'

const PAGE_TITLE = 'Заявки'

export const Applications = () => {
	const { id } = useParams()
	const matches = useMatches() as UIMatch<unknown, { title?: string; back?: string }>[]
	const { handle } = matches.find(({ handle }) => handle?.title) ?? {}
	const { data: { number } = {} } = applicationsApi.useGetApplication({
		enabled: !!id,
		variables: { id: id! },
	})

	const title = handle?.title ?? PAGE_TITLE
	const fullTitle = number ? `${title} №${number}` : title

	return (
		<>
			<Helmet title={fullTitle} />
			<FadeIn>
				<PageContent>
					<Grid.Section gap='m'>
						<Text as='h1' font='h3' styleOverride={{ fontWeight: 800 }}>
							{fullTitle}
						</Text>
						{handle?.back && (
							<LinkWithQuery to={handle?.back} style={{ width: 'fit-content' }}>
								<Text as='span' font='body-2' variant='link'>
									Назад
								</Text>
							</LinkWithQuery>
						)}
					</Grid.Section>
					<Outlet />
				</PageContent>
			</FadeIn>
		</>
	)
}
