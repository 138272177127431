import { organizationsApi } from 'api/organizations'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum } from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'

import { Button, Text } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { FadeIn } from 'components/common/FadeIn'

export const Brand = () => {
	const {
		profile: { role, selectedOrganizationId },
	} = useAppCtx()
	const { mutate, isPending } = organizationsApi.useOrganizationBrandSms()

	if (![ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER].includes(role!))
		return null

	return (
		<FadeIn>
			<Card title='Брендирование сервиса'>
				<Text>
					Используйте свой логотип и название компании в коммуникациях с клиентами. Это сделает наш
					продукт частью вашего сервиса.
				</Text>
				<Button
					{...({
						type: 'button',
						disabled: isPending,
						styleOverride: { width: 'fit-content' },
						onClick: () => mutate({ id: selectedOrganizationId! }),
					})}
				>
					Подключить
				</Button>
			</Card>
		</FadeIn>
	)
}
