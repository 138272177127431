import { object, string } from 'yup'

import { validationSchemas } from 'helpers/validationSchemas'

export const validationSchema = object({
	taxId: validationSchemas.entrepreneur.taxId.required(),
	ogrn: validationSchemas.entrepreneur.ogrn.required(),
	phone: validationSchemas.phone.required(),
	email: validationSchemas.email.required(),
	mainContactEmail: validationSchemas.email.required(),
	mainContactPhone: validationSchemas.phone.required(),
	site: validationSchemas.url,
	nameForClient: string().required(),
	bankDetailsName: string().required(),
	bankDetailsBic: validationSchemas.bank.bic.required(),
	bankDetailsCorrespondentAccount: validationSchemas.bank.correspondentAccount.required(),
	bankDetailsCheckingAccount: validationSchemas.bank.checkingAccount.required(),
	legalInfoFullName: object({
		query: string().required(),
	}),
	mainContactFullName: validationSchemas.fullName(),
	registrationAddress: validationSchemas.address(),
	mailingAddress: validationSchemas.address(),
})
