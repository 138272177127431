import { createMutation, createQuery } from 'react-query-kit'
import { OrganizationsApi } from 'api/organizations/dto'
import { RequestError, WithNull } from 'api/types'
import axios from 'axios'
import { ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerOrganizationProfileUpdateDto } from 'dto'
import { queryClient } from 'instances'

import { notice } from 'components/common/Notice'

const baseUrl = '/v1/organizations'

const useGetOrganization = createQuery<
	OrganizationsApi.PartnerOrganization,
	{ id: string },
	RequestError
>({
	queryKey: [baseUrl],
	fetcher: ({ id }, { signal }) =>
		axios.get(`${baseUrl}/${id}`, { signal }).then(({ data }) => data),
})

const useOrganizationMutation = createMutation<
	void,
	{
		id: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerOrganizationProfileUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ id, payload }) =>
		axios.put(`${baseUrl}/${id}/profile`, payload).then(() => {
			queryClient.invalidateQueries({ queryKey: useGetOrganization.getKey({ id }) })
		}),
})

const useOrganizationBrandSms = createMutation<
	void,
	{
		id: string
	},
	RequestError
>({
	mutationFn: ({ id }) =>
		axios
			.post(`${baseUrl}/${id}/brand/request`)
			.then(() => {
				notice({
					message: 'Заявка принята. С вами свяжется персональный менеджер.',
					type: 'success',
				})
			})
			.catch((error) => {
				notice({
					message: 'Не удалось отправить заявку. Пожалуйста, повторите позже.',
					type: 'error',
				})

				throw error
			}),
})

export const organizationsApi = {
	useGetOrganization,
	useOrganizationBrandSms,
	useOrganizationMutation,
}
