import { Entrepreneur } from 'components/forms/Company/Entrepreneur'
import { Organization } from 'components/forms/Company/Organization'
// import { Person } from 'components/forms/Company/Person'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoLegalTypeEnum } from 'dto'

import { Badge } from 'components/common/Badge'
import { Card } from 'components/common/Card'
import { FadeIn } from 'components/common/FadeIn'
import { Tabs, TabsProps } from 'components/common/Tabs'

import { companyFormCtx, useCompanyForm } from './useCompanyForm'

export const CompanyForm = () => {
	const {
		activeTab,
		ctx,
		legalType,
		hintBadgeProps,
		titleBadgeProps,
		handleSetActiveTab,
		organizationStatus,
	} = useCompanyForm()

	if (organizationStatus !== 'success') return null

	const tabsProps: TabsProps = {
		items: [
			{
				isActive: activeTab === 'ORGANIZATION',
				onClick: handleSetActiveTab('ORGANIZATION'),
				label: 'ЮРИДИЧЕСКОЕ ЛИЦО',
				isVisible:
					ctx.controls.edit ||
					legalType ===
						ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoLegalTypeEnum.ORGANIZATION,
				icon: 'case',
			},
			{
				isActive: activeTab === 'ENTREPRENEUR',
				onClick: handleSetActiveTab('ENTREPRENEUR'),
				label: 'ИП',
				isVisible:
					ctx.controls.edit ||
					legalType ===
						ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoLegalTypeEnum.ENTREPRENEUR,
				icon: 'businessman',
			},
			// {
			// 	isActive: activeTab === 'PERSON',
			// 	onClick: handleSetActiveTab('PERSON'),
			// 	label: 'Физическое лицо',
			// 	isVisible:
			// 		ctx.controls.edit ||
			// 		legalType === ComCreditclubPartnerLkDtoV1OrganizationPartnerOrganizationRefDtoLegalTypeEnum.PERSON,
			// 	icon: 'person',
			// },
		],
	}

	return (
		<FadeIn>
			<Card title={<>Профиль компании {titleBadgeProps && <Badge {...titleBadgeProps} />}</>}>
				<Tabs {...tabsProps} />
				{hintBadgeProps && (
					<FadeIn>
						<Badge {...hintBadgeProps} />
					</FadeIn>
				)}
				<companyFormCtx.Provider value={ctx}>
					{activeTab === 'ORGANIZATION' && <Organization />}
					{activeTab === 'ENTREPRENEUR' && <Entrepreneur />}
					{/* {activeTab === 'PERSON' && <Person />} */}
				</companyFormCtx.Provider>
			</Card>
		</FadeIn>
	)
}
