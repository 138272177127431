import { NewApplicationForm } from 'components/forms/NewApplication/types'
import { ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum, PersonParticipantDataDtoRolesEnum } from 'dto'

const createPledge = (): NewApplicationForm.Pledge => {
	return {
		address: {
			query: '',
			selected: null,
		},
		area: '',
		city: '',
	}
}

const createParticipant = (addBorrower: boolean = false): NewApplicationForm.Participant => {
	return {
		fullName: {
			query: '',
			selected: null,
			manual: null,
		},
		creditHistoryConsentType: '',
		email: '',
		phone: '',
		type: ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum.PERSON,
		roles: addBorrower ? [PersonParticipantDataDtoRolesEnum.BORROWER] : [],
	}
}

export const newApplicationHelpers = {
	createPledge,
	createParticipant,
}
