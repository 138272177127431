import { PropsWithChildren } from 'react'
import pencil from 'assets/pencil.svg'
import warning from 'assets/warning.svg'
import { transparentize } from 'polished'
import styled, { DefaultTheme } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

const ICONS = {
	warning,
	pencil,
}

export interface BadgeProps extends PropsWithChildren {
	variantColor?: 'red' | 'green' | 'yellow' | 'orange'
	inheritTextColors?: boolean
	font?: keyof DefaultTheme['fonts']
	icon?: keyof typeof ICONS
}

const Wrap = styled(Text)<BadgeProps>(({ theme, variantColor, inheritTextColors }) => ({
	padding: `${theme.spaces.s}px ${theme.spaces.l}px`,
	width: 'fit-content',
	display: 'flex',
	gap: theme.spaces.s,
	alignItems: 'center',
	borderRadius: 8,

	img: {
		width: 24,
		height: 24,
	},

	...mixins.when(variantColor === 'yellow', {
		backgroundColor: theme.colors.selected,
	}),

	...mixins.when(variantColor === 'red', {
		backgroundColor: transparentize(0.75, theme.colors.negative),

		...mixins.when(!inheritTextColors, {
			color: theme.colors.negative,
		}),
	}),

	...mixins.when(variantColor === 'orange', {
		backgroundColor: '#FF751080',

		...mixins.when(!inheritTextColors, {
			color: transparentize(0.5, theme.colors.textMain),
		}),
	}),

	...mixins.when(variantColor === 'green', {
		backgroundColor: '#c5f7dc',

		...mixins.when(!inheritTextColors, {
			color: theme.colors.positive,
		}),
	}),
}))

export const Badge = ({ children, variantColor, inheritTextColors = false, icon, font = 'body/14' }: BadgeProps) => {
	return (
		<Wrap
			as='span'
			weight='normal'
			font={font}
			inheritTextColors={inheritTextColors}
			variantColor={variantColor}
		>
			{icon && <Image src={ICONS[icon]} />}
			{children}
		</Wrap>
	)
}
