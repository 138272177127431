import { useCallback, useEffect } from 'react'
import Slider, { SliderProps } from '@mui/material/Slider'
import { keepPreviousData } from '@tanstack/react-query';
import { applicationsApi } from 'api/applications'
import { getServicePayment } from 'components/pages/Applications/Single/Tabs/Decision/helpers'
import {
	DecisionFormValues,
	OperationLoanTerms,
	OperationOption,
} from 'components/pages/Applications/Single/Tabs/Decision/types'
import { useDecisionCtx } from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import {
	ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDtoAmountIntervalDto,
	ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDtoDurationIntervalDto,
	ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum,
} from 'dto'
import { useFormikContext } from 'formik'

import { moneyUtils } from '@creditclubteam/kit/legacy-helpers'
import { ToggleSwitch } from '@creditclubteam/kit/legacy-ui-components'
import { Button, ButtonProps, Text } from '@creditclubteam/kit/ui-components'
import { BlurLoading } from 'components/common/BlurLoading'
import { Card } from 'components/common/Card'
import { DottedRow } from 'components/common/DottedRow'
import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { Highlight, HighlightProps } from 'components/common/Highlight'
import { utils } from 'helpers/utils'

const getAmountMarks = ({
	from,
	to,
}: ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDtoAmountIntervalDto): SliderProps['marks'] => {
	return [
		{
			value: from,
			label:
				from >= 1000000 ? `${Math.floor(from / 1000000)} млн` : `${Math.floor(from / 1000)} тыс.`,
		},
		{
			value: to,
			label: to >= 1000000 ? `${Math.floor(to / 1000000)} млн` : `${Math.floor(to / 1000)} тыс.`,
		},
	]
}

const getDurationMarks = ({
	to,
}: ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDtoDurationIntervalDto): SliderProps['marks'] => {
	return Array(Math.floor(to / 60))
		.fill(null)
		.map((_, idx) => ({
			value: (idx + 1) * 60,
			label: utils.formatDuration((idx + 1) * 60),
		}))
}

export const Conditions = () => {
	const { appId, programIdToPreview, editable } = useDecisionCtx()
	const { dirty, setFieldValue, isSubmitting, resetForm, handleChange, values, initialValues } =
		useFormikContext<DecisionFormValues>()
	const { data: { loanTerms } = {} } = applicationsApi.useGetDecision({
		variables: { appId: appId! },
		select: (content) => content.find(({ id }) => id === programIdToPreview)!,
	})
	const {
		data: calculationResult,
		isFetching,
		status,
	} = applicationsApi.useCalculateDecision({
		enabled: editable && !!values.operations.length && !!programIdToPreview,
		placeholderData: dirty ? keepPreviousData : undefined,
		variables: {
			appId: appId!,
			decisionId: programIdToPreview!,
			payload: { operations: values.operations },
		},
	})

	useEffect(() => {
		if (status === 'success') {
			setFieldValue('amount', calculationResult?.loanTerms?.amount ?? 0)
			setFieldValue('duration', calculationResult?.loanTerms?.duration ?? 0)
		}
	}, [
		calculationResult?.loanTerms?.amount,
		calculationResult?.loanTerms?.duration,
		setFieldValue,
		status,
	])

	const handleChangeCommitted = useCallback(
		({
			operation,
			field,
			value,
		}: {
			operation: OperationLoanTerms | OperationOption
			field?: string
			value?: any
		}) => {
			field && setFieldValue(field, value)
			setFieldValue('operations', [...values.operations, operation])
		},
		[setFieldValue, values.operations]
	)

	const highlightProps: HighlightProps = {
		font: 'body-2',
		radius: 16,
		styleOverride: { width: 'auto', height: 'min-content' },
		variantColor: 'light-blue',
	}

	const amountInterval = calculationResult?.loanTerms.amountInterval ?? loanTerms?.amountInterval
	const durationInterval =
		calculationResult?.loanTerms.durationInterval ?? loanTerms?.durationInterval
	const amount = moneyUtils.RUB(calculationResult?.loanTerms?.amount ?? initialValues.amount, true)
	const maxAmount = moneyUtils.RUB(
		calculationResult?.loanTerms?.maxAmount ?? loanTerms?.maxAmount,
		true
	)
	const duration = utils.formatDuration(
		calculationResult?.loanTerms?.duration ?? initialValues.duration
	)
	const monthlyPayment = moneyUtils.RUB(
		calculationResult?.loanTerms?.monthlyPayment ?? loanTerms?.monthlyPayment,
		true
	)
	const getInterestRate = () => {
		const marketingInterestRate =
			calculationResult?.loanTerms?.marketingInterestRate ?? loanTerms?.marketingInterestRate ?? 0
		const interestRate = calculationResult?.loanTerms?.interestRate ?? loanTerms?.interestRate ?? 0

		if (!marketingInterestRate || marketingInterestRate === interestRate) {
			return [`${utils.formatNumber(interestRate)}%`]
		} else {
			return [utils.formatNumber(marketingInterestRate), utils.formatNumber(interestRate)]
		}
	}

	const interestRate = getInterestRate()

	const amountProps: SliderProps = {
		min: amountInterval?.from,
		value: Number(values.amount),
		onChangeCommitted: (_, value) =>
			handleChangeCommitted({
				operation: {
					type: ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum.LOANTERMS,
					amount: value as number,
					duration: values.duration,
				},
			}),
		onChange: handleChange,
		name: 'amount',
		step: 1000,
		marks: amountInterval ? getAmountMarks(amountInterval) : undefined,
		max: amountInterval?.to,
	}

	const durationProps: SliderProps = {
		min: durationInterval?.from,
		value: Number(values.duration),
		onChangeCommitted: (_, value) =>
			handleChangeCommitted({
				operation: {
					type: ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum.LOANTERMS,
					amount: values.amount,
					duration: value as number,
				},
			}),
		onChange: handleChange,
		name: 'duration',
		step: 1,
		marks: durationInterval ? getDurationMarks(durationInterval) : undefined,
		max: durationInterval?.to,
	}

	const submitProps: ButtonProps = {
		size: 'min',
		disabled: isSubmitting,
		children: 'Сохранить настройки',
	}

	const cancelProps: ButtonProps = {
		size: 'min',
		type: 'button',
		variant: 'outline-blue',
		disabled: isSubmitting,
		children: 'Отменить',
		onClick: () => {
			resetForm()
		},
	}

	return (
		<FadeIn>
			<Card title='Условия по кредиту'>
				<Grid.Row sizes='1fr 1fr'>
					<Highlight
						{...highlightProps}
						styleOverride={{ position: 'relative', width: 'auto', overflow: 'hidden' }}
					>
						<BlurLoading isActive={isFetching} />
						<Grid.Section gap='m' style={{ width: '100%' }}>
							<DottedRow label='Ставка'>
								{interestRate.length <= 1 ? (
									interestRate[0]
								) : (
									<>
										<span style={{ textDecoration: 'line-through' }}>{interestRate[0]}</span> →{' '}
										{interestRate[1]}
									</>
								)}
							</DottedRow>
							<DottedRow label='Сумма'>{amount}</DottedRow>
							<DottedRow label='Максимальная доступная сумма'>{maxAmount}</DottedRow>
							<DottedRow label='Срок расчёта'>{duration}</DottedRow>
							<DottedRow label='Ежемесячный платёж'>{monthlyPayment}</DottedRow>
							<Grid.Section gap='xs'>
								{getServicePayment(calculationResult?.loanTerms ?? loanTerms).map((value, i) =>
									i === 0 ? (
										<DottedRow key={i} label='Сервисный платёж'>
											{value}
										</DottedRow>
									) : (
										<Text key={i} styleOverride={{ textAlign: 'right' }} font='body-2'>
											{value}
										</Text>
									)
								)}
							</Grid.Section>
						</Grid.Section>
					</Highlight>
					<Grid.Section>
						<div>
							<Text as='div' indent='s' font='body-2'>
								Сумма кредита
							</Text>
							<Text as='div' font='body-2' indent='xs'>
								{moneyUtils.RUB(Number(values.amount), true)}
							</Text>
							<Slider {...amountProps} />
						</div>
						<Grid.Section gap='l'>
							<div>
								<Text as='div' indent='s' font='body-2'>
									Срок расчёта
								</Text>
								<Text as='div' font='body-2' indent='xs'>
									{utils.formatDuration(Number(values.duration))}
								</Text>
								<Slider {...durationProps} />
							</div>
							{loanTerms?.options.map(
								({ id, title, enabled }) =>
									id !== 'REFINANCING' && (
										<ToggleSwitch
											key={id}
											name={id}
											title={title}
											disabled={!enabled}
											onChange={({ checked }) =>
												handleChangeCommitted({
													operation: {
														type: ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum.OPTION,
														optionId: id,
														selected: checked,
													},
													field: `options.${id}`,
													value: checked,
												})
											}
											checked={values.options[id]}
										/>
									)
							)}
						</Grid.Section>
						{dirty && (
							<Grid.Row controls>
								<Button {...submitProps} />
								<Button {...cancelProps} />
							</Grid.Row>
						)}
					</Grid.Section>
				</Grid.Row>
			</Card>
		</FadeIn>
	)
}
