import { useCallback } from 'react'
import { keepPreviousData } from '@tanstack/react-query';
import { dictionariesApi } from 'api/dictionaries'
import { employeesApi } from 'api/employees'
import moreVert from 'assets/more-vert.svg'
import { useEmployeesItemDropdown } from 'components/pages/Profile/Tabs/Employees/Item/useDropdown'
import { useEmployeesCtx } from 'components/pages/Profile/Tabs/Employees/useEmployees'
import { useAppCtx } from 'providers/features/useAppCtx'
import { theme } from 'styles/theme'

import { Text } from '@creditclubteam/kit/ui-components'
import { Dropdown, DropdownProps } from 'components/common/Dropdown'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'
import { utils } from 'helpers/utils'

import { Styled } from './styled'

interface Props {
	id: string
}

const ROLES = {
	ADMIN: 'Администратор',
	OWNER: 'Владелец',
	EMPLOYEE: 'Сотрудник',
} as const

const STATUSES = {
	ACTIVE: { title: 'Активный', color: theme.getColor('positive') },
	DEACTIVATED: { title: 'Деактивирован', color: theme.getColor('textSecondary') },
	INVITED: { title: 'Приглашённый', color: '#ffa665' },
} as const

export const Item = ({ id }: Props) => {
	const { profile } = useAppCtx()
	const { variables, form } = useEmployeesCtx()
	const { data } = employeesApi.useSearchEmployees({
		enabled: !!profile.selectedOrganizationId,
		placeholderData: keepPreviousData,
		select: ({ content }) => content.find(({ id: _id }) => _id === id)!,
		variables: {
			organizationId: profile.selectedOrganizationId!,
			...variables,
		},
	})
	const { data: { products } = {} } = dictionariesApi.useGetProductsTypes()
	const { dropdownItems } = useEmployeesItemDropdown({
		status: data!.status,
		employeeId: id,
		onEdit: useCallback(() => {
			form.setEditableId(data!.id)
			form.modal.setTrue()
		}, [data, form]),
	})

	const dropdownProps: DropdownProps = {
		arrow: false,
		items: dropdownItems,
		itemsContainerStyle: { right: 0 },
		wrapStyle: { minWidth: 'auto', alignSelf: 'center' },
	}

	return (
		<Styled.Item>
			<Grid.Row sizes='1fr .75fr .25fr auto'>
				<Styled.Column>
					<Text font='body-2'>{utils.getFullName(data)}</Text>
					<Styled.Row>
						{data?.products.map((product) => (
							<Text key={id} as='span' font='small/12' variant='secondary'>
								{products?.find(({ id }) => product === id)?.title ?? product}
							</Text>
						))}
					</Styled.Row>
				</Styled.Column>
				<Styled.Column>
					<Text font='body-2'>{utils.parsePhone(data?.phone)}</Text>
					<Text font='body-2'>{data?.email}</Text>
				</Styled.Column>
				<Styled.Column>
					{data?.role && <Text font='body-2'>{ROLES[data.role]}</Text>}
					{data?.status && (
						<Text font='body-2' styleOverride={{ color: STATUSES[data.status].color }}>
							{STATUSES[data.status].title}
						</Text>
					)}
				</Styled.Column>
				<Dropdown {...dropdownProps}>
					<Image src={moreVert} />
				</Dropdown>
			</Grid.Row>
		</Styled.Item>
	)
}
