import { useCallback } from 'react'
import { dictionariesApi } from 'api/dictionaries'
import trash from 'assets/trash.svg'
import { AddressModal, AddressModalProps } from 'components/forms/Address'
import { newApplicationHelpers } from 'components/forms/NewApplication/helpers'
import { NewApplicationForm } from 'components/forms/NewApplication/types'
import { ComCreditclubPartnerCommonDtoV1AddressDto } from 'dto'
import { ArrayHelpers, FieldArray, FormikProps } from 'formik'
import { useBoolean } from 'hooks/useBoolean'
import { getInputProps } from 'styles/theme'

import {
	Button,
	ButtonProps,
	Input,
	Text,
	useAddresscomplete,
} from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'
import { Highlight } from 'components/common/Highlight'
import { Image } from 'components/common/Image'
import { inputFormatting } from 'helpers/inputFormatting'

const TEXT = [
	'— Дом менее 3-х этажей',
	'— Цокольный этаж',
	'— Рыночная стоимость менее 1 млн рублей',
	'— Доля в объекте (рассмотрим только в случае согласия всех собственников)',
	'— Имеются отказники от приватизации',
]

const AddressInput = ({
	values,
	setFieldValue,
	handleBlur,
	idx,
	errors,
	touched,
}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) => {
	const { setFalse, setTrue, value } = useBoolean()

	const manualProps: AddressModalProps = {
		onChange: (value) =>
			setFieldValue(`pledges[${idx}].address`, {
				selected: {
					id: value.mergedAddress,
					label: value.mergedAddress,
					payload: value,
				},
				query: value.mergedAddress,
			}),
		isOpen: value,
		onClose: setFalse,
	}


	return (
		<>
			<AddressModal {...manualProps} />
			<Input
				{...getInputProps({
					error: 'address' in (errors.pledges?.[idx] ?? {}) && !!touched.pledges?.[idx]?.address,
					onFocus: handleBlur,
					name: `pledges[${idx}].address`,
					placeholder: 'Город, улица, дом, квартира',
					label: 'Адрес местонахождения объекта',
					value: values.pledges[idx].address.query,
					onChange: ({ target }) =>
						setFieldValue(`pledges[${idx}].address`, {
							query: target.value,
							selected: null,
						}),
					autocompleteConfig: useAddresscomplete<
						ComCreditclubPartnerCommonDtoV1AddressDto[],
						ComCreditclubPartnerCommonDtoV1AddressDto
					>({
						onManual: setTrue,
						request: {
							url: '/v1/suggestions/address',
							method: 'post',
							data: {
								query: values.pledges[idx].address.query,
							},
						},
						query: values.pledges[idx].address.query,
						selected: values.pledges[idx].address.selected,
						onSelect: (option) =>
							setFieldValue(`pledges[${idx}].address`, {
								query: option.label,
								selected: option,
							}),
					}),
				})}
			/>
		</>
	)
}

export const Pledges = () => {
	const { data: { operationCities } = {} } = dictionariesApi.useGetApplication()

	const getAreaProps = useCallback(
		({
			errors,
			touched,
			setFieldValue,
			values,
			handleBlur,
			idx,
		}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) =>
			getInputProps({
				label: 'Площадь',
				onFocus: handleBlur,
				name: `pledges[${idx}].area`,
				error: 'area' in (errors.pledges?.[idx] ?? {}) && touched.pledges?.[idx]?.area,
				value: inputFormatting.format({ value: values.pledges[idx].area }),
				onChange: ({ target }) =>
					setFieldValue(`pledges[${idx}].area`, inputFormatting.unformat({ value: target.value })),
				placeholder: '0м²',
			}),
		[]
	)

	const getCityProps = useCallback(
		({
			values,
			errors,
			setFieldValue,
			touched,
			handleBlur,
			idx,
		}: FormikProps<NewApplicationForm.Values> & { idx: number; errors: any }) =>
			getInputProps({
				label: (
					<>
						Город.{' '}
						<Text as='span' variant='error' font='inherit'>
							Работаем только в городах из списка
						</Text>
					</>
				),
				name: `pledges[${idx}].city`,
				placeholder: 'Выберите город',
				onFocus: handleBlur,
				autocompleteConfig: {
					selected: values.pledges[idx].city
						? {
								id: values.pledges[idx].city,
								label: values.pledges[idx].city,
							}
						: null,
					optionsWrapperMaxHeight: 250,
					filter: ({ id }) =>
						(id as string).toLowerCase().includes(values.pledges[idx].city.toLowerCase()),
					onSelect: ({ id }) => setFieldValue(`pledges[${idx}].city`, id),
					options: operationCities?.map((city) => ({ id: city, label: city })) ?? [],
				},
				error: 'city' in (errors.pledges?.[idx] ?? {}) && touched.pledges?.[idx]?.city,
				errorMessage: errors.pledges?.[idx]?.city,
				value: values.pledges[idx].city,
				onChange: ({ target }) => setFieldValue(`pledges[${idx}].city`, target.value),
			}),
		[operationCities]
	)

	const getRemoveProps = useCallback(
		({ remove, idx }: Pick<ArrayHelpers, 'remove'> & { idx: number }): ButtonProps => ({
			variant: 'transparent-blue',
			size: 'inline',
			type: 'button',
			styleOverride: { padding: 0 },
			onClick: () => remove(idx),
			children: (
				<Grid.Row style={{ alignItems: 'center' }} gap='s' sizes='auto 1fr'>
					<Image src={trash} />
					<span>Удалить объект</span>
				</Grid.Row>
			),
		}),
		[]
	)

	const getPushProps = useCallback(
		({ push }: Pick<ArrayHelpers, 'push'>): ButtonProps => ({
			size: 'min',
			type: 'button',
			onClick: () => push(newApplicationHelpers.createPledge()),
			children: 'Добавить ещё объект',
		}),
		[]
	)

	return (
		<Card title='Квартира в залог'>
			<Highlight variantColor='red' radius={16}>
				<Text as='h5' weight='bold'>
					Стоп-факторы по объекту залога
				</Text>
				{TEXT.map((value) => (
					<Text key={value}>{value}</Text>
				))}
			</Highlight>
			<Grid.Section>
				<FieldArray name='pledges'>
					{({
						form,
						push,
						remove,
					}: ArrayHelpers & { form: FormikProps<NewApplicationForm.Values> }) => (
						<>
							{form.values.pledges.map((_, idx) => (
								<Grid.Section key={idx}>
									<Grid.Row sizes='1fr 1fr'>
										<Input {...getCityProps({ ...form, idx })} />
									</Grid.Row>
									<Grid.Row sizes='3fr 1fr'>
										<AddressInput {...form} idx={idx} />
										<Input {...getAreaProps({ ...form, idx })} />
									</Grid.Row>
									{idx !== 0 && (
										<Grid.Row sizes='auto 1fr'>
											<Button {...getRemoveProps({ remove, idx })} />
										</Grid.Row>
									)}
									{form.values.pledges.length - 1 !== idx && <Grid.Divider />}
								</Grid.Section>
							))}
							{form.values.pledges.length < 10 && (
								<>
									<Grid.Divider />
									<Grid.Row sizes='auto 1fr'>
										<Button {...getPushProps({ push })} />
									</Grid.Row>
								</>
							)}
						</>
					)}
				</FieldArray>
			</Grid.Section>
		</Card>
	)
}
