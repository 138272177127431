import { useCallback } from 'react'
import { applicationsApi } from 'api/applications'
import gearSix from 'assets/gear-six.svg'
import { getServicePayment } from 'components/pages/Applications/Single/Tabs/Decision/helpers'
import { OperationOption } from 'components/pages/Applications/Single/Tabs/Decision/types'
import { useDecisionCtx } from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import {
	ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum,
	ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum,
} from 'dto'
import { useTheme } from 'styled-components'

import { moneyUtils } from '@creditclubteam/kit/legacy-helpers'
import { ToggleSwitch } from '@creditclubteam/kit/legacy-ui-components'
import { Button, ButtonProps, Text } from '@creditclubteam/kit/ui-components'
import { DottedRow } from 'components/common/DottedRow'
import { Grid } from 'components/common/Grid'
import { Hint } from 'components/common/Hint'
import { Image } from 'components/common/Image'
import { utils } from 'helpers/utils'

import { Styled } from './styled'

const HINTS = {
	REFINANCING: (
		<Text font='body-2' weight='normal'>
			Если по программе в условиях выхода на сделку система требует погасить долги клиент может
			воспользоваться данной опцией. Как это работает: клиент получает кредит и погашает все
			перечисленные долги. Оставшиеся деньги сможет потратить на любые цели.
		</Text>
	),
	DURATION: (
		<Text font='body-2' weight='normal'>
			Договор заключается сроком от 12 месяцев до 120 месяцев, взависимости от программы
			кредитования. График платежей может рассчитываться по модели графика на срок до 240 мес., с
			выплатой остатка основного долга в конце действия договора. При этом по истечении срока
			действия договора возможна пролонгация.
		</Text>
	),
} as const

const STATUS = {
	APPROVED: { color: 'green', title: 'Одобрено' },
	APPROVED_WITH_CONDITIONS: { color: 'blue', title: 'Одобрено под условиями' },
	DENIED: { color: 'red', title: 'Отказ' },
	PRE_APPROVED: { color: 'yellow', title: 'Предварительно одобрено' },
} as Record<
	ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum,
	{ color: 'blue' | 'yellow' | 'red' | 'green'; title: string }
>

export interface CardContentProps {
	programId: string
}

const REFINANCING = 'REFINANCING'

export const CardContent = ({ programId }: CardContentProps) => {
	const { appId, setProgramIdToPreview, editable, programIdToPreview, setEditable } =
		useDecisionCtx()
	const { data: { status, loanTerms, program, selected } = {} } = applicationsApi.useGetDecision({
		variables: { appId: appId! },
		select: (v) => v.find(({ id }) => programId === id)!,
	})
	const { spaces, colors } = useTheme()
	const { mutateAsync, isPending } = applicationsApi.useSelectDecision()
	const decisionMutation = applicationsApi.useDecisionMutation()

	const handleChangeRefinancing = useCallback(
		({ checked }: { checked: boolean }) => {
			decisionMutation.mutate({
				appId: appId!,
				decisionId: programId,
				payload: {
					operations: [
						{
							type: ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum.OPTION,
							optionId: REFINANCING,
							selected: checked,
						} as OperationOption,
					],
				},
			})
		},
		[appId, decisionMutation, programId]
	)

	const getSelectBtnProps = (): ButtonProps => ({
		variant: selected ? undefined : 'outline-blue',
		size: 'min',
		disabled: isPending,
		styleOverride: {
			backgroundColor: selected ? colors.positive : 'transparent',
			'&:hover': {
				backgroundColor: selected ? colors.positive : undefined,
			},
		},
		type: 'button',
		children: selected ? 'Выбрано' : 'Выбрать',
		onClick: async (event) => {
			event.stopPropagation()

			if (
				!selected &&
				!isPending &&
				status !== ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum.DENIED
			) {
				try {
					await mutateAsync({ appId: appId!, decisionId: programId })
					setProgramIdToPreview(programId)()
				} catch {
					//
				}
			}
		},
	})

	const getEditProps = useCallback(
		(): ButtonProps => ({
			variant: programIdToPreview === programId && editable ? 'blue' : 'outline-blue',
			size: 'min',
			styleOverride: {
				display: 'flex',
				padding: spaces.s,
				alignItems: 'center',
				img:
					programIdToPreview === programId && editable
						? { filter: 'brightness(0) invert(1)' }
						: undefined,
			},
			onClick: (event) => {
				event.stopPropagation()
				setEditable((p) => (programIdToPreview !== programId ? true : !p))
				setProgramIdToPreview(programId)()
			},
			type: 'button',
		}),
		[editable, programId, programIdToPreview, setEditable, setProgramIdToPreview, spaces.s]
	)

	return (
		<Styled.Wrap onClick={() => setProgramIdToPreview(programId)()}>
			<Grid.Section>
				<Grid.Section gap='s'>
					{status && (
						<Styled.Badge variantColor={STATUS[status].color}>{STATUS[status].title}</Styled.Badge>
					)}
					<Styled.ProgramTitle as='h5' font='h5' variant={selected ? 'link' : 'text'} weight='bold'>
						{program}
					</Styled.ProgramTitle>
				</Grid.Section>
				<Grid.Section gap='m'>
					<DottedRow label='Ставка'>
						{typeof loanTerms?.interestRate === 'number'
							? `${utils.formatNumber(loanTerms.interestRate)}%`
							: null}
					</DottedRow>
					<DottedRow label='Сумма'>{moneyUtils.RUB(loanTerms?.amount, true)}</DottedRow>
					<DottedRow
						label={
							<Grid.Row gap='s' sizes='auto auto'>
								<span>Срок расчёта</span>
								<Hint config={{ placement: 'right' }} element={HINTS.DURATION} />
							</Grid.Row>
						}
					>
						{utils.formatDuration(loanTerms?.duration)}
					</DottedRow>
					<DottedRow label='Ежемесячный платёж'>
						{moneyUtils.RUB(loanTerms?.monthlyPayment, true)}
					</DottedRow>
					<Grid.Section gap='xs'>
						{getServicePayment(loanTerms).map((value, i) =>
							i === 0 ? (
								<DottedRow key={i} label='Сервисный платёж'>{value}</DottedRow>
							) : (
								<Text key={i} styleOverride={{ textAlign: 'right' }} font='body-2'>
									{value}
								</Text>
							)
						)}
					</Grid.Section>
				</Grid.Section>
			</Grid.Section>
			<Grid.Section>
				{loanTerms?.options.some(({ id }) => id === REFINANCING) && (
					<Grid.Section gap='s'>
						{loanTerms.options.map(
							({ id, enabled, selected, title }) =>
								id === REFINANCING && (
									<Grid.Row style={{ alignItems: 'center' }} key={id} sizes='auto 1fr' gap='s'>
										<ToggleSwitch
											title={title}
											checked={selected}
											onClick={(event) => event.stopPropagation()}
											onChange={handleChangeRefinancing}
											disabled={!enabled || decisionMutation.isPending}
											name={id}
										/>
										<Hint config={{ placement: 'right' }} element={HINTS.REFINANCING} />
									</Grid.Row>
								)
						)}
					</Grid.Section>
				)}
				<Grid.Row sizes='auto auto 1fr' gap='l'>
					{status !==
						ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum.DENIED && (
						<Button {...getSelectBtnProps()} />
					)}
					<Button {...getEditProps()}>
						<Image src={gearSix} />
					</Button>
				</Grid.Row>
			</Grid.Section>
		</Styled.Wrap>
	)
}
