import { useContext, useEffect, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { kcCtx } from 'context'
import { AppCtx, appCtx } from 'providers/features/useAppCtx'
import { useDictionaries } from 'providers/features/useDictionaries'
import { useProfile } from 'providers/features/useProfile'
import { useMetrikaLogger } from 'providers/Metrika/useMetrikaLogger'
import { urls } from 'routes/urls'

import { FadeIn } from 'components/common/FadeIn'
import { PageLoading } from 'components/common/PageLoading'

export const AppDataProvider = () => {
	const { kcStatus } = useContext(kcCtx)
	const navigate = useNavigate()
	const profile = useProfile()

	useMetrikaLogger()
	useDictionaries()

	const isLoading = [profile.isLoading, kcStatus === 'pending'].some(Boolean)

	const appCtxValue = useMemo<AppCtx>(
		() => ({
			profile: profile.ctx,
		}),
		[profile.ctx]
	)

	useEffect(() => {
		if (window.location.pathname === urls.root)
			navigate({ pathname: urls.profile('information'), search: window.location.search })
	}, [navigate])

	if (profile.isError || kcStatus === 'error')
		throw profile.error

	if (isLoading)
		return (
			<FadeIn>
				<PageLoading useLogo fullHeight fullVh />
			</FadeIn>
		)

	return (
		<appCtx.Provider value={appCtxValue}>
			<Outlet />
		</appCtx.Provider>
	)
}
