import styled, { CSSObject } from 'styled-components'

import { mixins, PreparedTheme } from '@creditclubteam/kit/styled'
import { CardProps } from 'components/common/Card'

const getVariants = ({
	spaces,
}: PreparedTheme): Record<NonNullable<CardProps['variant']>, CSSObject> => ({
	default: {
		padding: spaces.xl,
		borderRadius: 16,
		boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
	},
	small: {
		borderRadius: 8,
		padding: `${spaces.l}px ${spaces.m}px`,
		boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
	},
})

const Wrap = styled.div<Pick<CardProps, 'variant'> & { override?: CSSObject }>(
	({ theme, variant, override }) => ({
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spaces.xl,

		...getVariants(theme)[variant!],

		...mixins.when(override, override!),
	})
)

export const Styled = {
	Wrap,
}
