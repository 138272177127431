import dots from 'assets/dots.svg'
import styled from 'styled-components'

const Dots = styled.div({
	width: '100%',
	height: 16,
	backgroundRepeat: 'repeat-x',
	backgroundPosition: 'bottom',
	backgroundImage: `url(${dots})`,
})

export const Styled = {
	Dots,
}
