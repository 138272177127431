import styled from 'styled-components'

export const Textarea = styled.textarea<{ error?: boolean }>(({ theme, error }) => ({
	width: '100%',
	resize: 'vertical',
	minHeight: 128,
	boxSizing: 'border-box',
	borderColor: !error ? theme.colors.border : theme.colors.negative,
	color: theme.colors.textMain,
	outline: 'none',
	borderRadius: 8,
	padding: `${theme.spaces.l}px`,
	transition: 'border-color .3s ease',

	':focus': {
		borderColor: theme.colors.active,
	},

	'::placeholder': {
		color: '#d7d7d7',
	},

	...theme.fonts['body-2'],
}))
