import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { applicationsApi } from 'api/applications'
import { useFormik } from 'formik'
import { always, pipe } from 'rambda'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { DecisionFormValues, OperationLoanTerms, OperationOption } from './types'

export const decisionCtx = createContext<ReturnType<typeof useDecision>['ctx']>(null!)

export const useDecision = () => {
	const { id: appId } = useParams()
	const [editable, setEditable] = useState<boolean>(false)
	const [programIdToPreview, setProgramIdToPreview] = useState<Nullable<string>>(null)
	const { data: { loanTerms } = {} } = applicationsApi.useGetDecision({
		enabled: editable,
		variables: { appId: appId! },
		select: (content) => content.find(({ id }) => id === programIdToPreview),
	})
	const { data: [head] = [] } = applicationsApi.useGetDecision({
		enabled: editable,
		variables: { appId: appId! },
	})
	const { mutateAsync } = applicationsApi.useDecisionMutation()

	useEffect(() => {
		if (!programIdToPreview && head?.id) {
			setProgramIdToPreview(head.id)
			setEditable(true)
		}
	}, [head?.id, programIdToPreview])

	const formik = useFormik<DecisionFormValues>({
		enableReinitialize: true,
		initialValues: {
			amount: loanTerms?.amount ?? 100000,
			duration: loanTerms?.duration ?? 12,
			operations: [] as Array<OperationLoanTerms | OperationOption>,
			options:
				loanTerms?.options?.reduce(
					(acc, curr) => {
						acc[curr.id] = curr.selected
						return acc
					},
					{} as Record<string, boolean>
				) ?? {},
		},
		onSubmit: async ({ operations }, { resetForm, setSubmitting }) => {
			try {
				setSubmitting(true)
				await mutateAsync({
					appId: appId!,
					decisionId: programIdToPreview!,
					payload: { operations },
				})
				resetForm()
			} catch {
				//
			} finally {
				setSubmitting(false)
			}
		},
	})

	return {
		formik,
		ctx: {
			appId,
			setProgramIdToPreview: pipe(setProgramIdToPreview, always(formik.resetForm)),
			programIdToPreview,
			setEditable,
			editable,
		},
	}
}

export const useDecisionCtx = () => useContext(decisionCtx)
