import { PageLoadingProps } from 'components/common/PageLoading'

import { Styled } from './styled'

export interface BlurLoadingProps extends PageLoadingProps {
	isActive: boolean
}

export const BlurLoading = ({ isActive, ...props }: BlurLoadingProps) => {
	return (
		<>
			<Styled.Background isActive={isActive} />
			<Styled.Loader {...props} isActive={isActive} />
		</>
	)
}
