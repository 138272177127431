import styled from 'styled-components'

const Card = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '500px',
	flexDirection: 'column',
	height: `70vh`,
	borderRadius: 16,
	boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.10)',
})

export const Styled = {
	Card,
}