import { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { documentsApi } from 'api/documents'
import fileText from 'assets/file-text.svg'
import removeRed from 'assets/remove-red.svg'
import axios from 'axios'
import { useBoolean } from 'hooks/useBoolean'
import { useAppCtx } from 'providers/features/useAppCtx'
import { filter, omit } from 'rambda'
import { useTheme } from 'styled-components'

import { Button, ButtonProps, Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import { notice } from 'components/common/Notice'

import { Styled } from './styled'
import { useFilesUploader } from './useFilesUploader'

const MB20 = 20000000

export interface FilesUploaderProps
	extends Pick<ReturnType<typeof useFilesUploader>, '_props' | 'onRemove' | 'files'> {}

export const FilesUploader = ({
	onRemove,
	files,
	_props: {
		convertFile,
		loading,
		setFailure,
		setFiles,
		setLoading,
		setSuccess,
		failure,
		success,
	},
}: FilesUploaderProps) => {
	const {
		profile: { selectedOrganizationId },
	} = useAppCtx()
	const { mutateAsync } = documentsApi.useUploader()
	const { value: isHovered, setFalse, setTrue } = useBoolean()
	const { colors } = useTheme()

	const uploadFile = useCallback(
		async ({ id, file, isRetry }: { id: string; file?: File; isRetry?: boolean }) => {
			try {
				const payload = file ?? files.find(({ id: _id }) => _id === id)!.file

				setFailure(omit(id))
				if (isRetry) setLoading((p) => ({ ...p, [id]: true }))

				const [{ id: docId }] = await mutateAsync({
					organizationId: selectedOrganizationId!,
					payload: [payload],
				})

				setFiles((p) => p.map((v) => (v.id === id ? { ...v, docId } : v)))
				setSuccess((p) => ({ ...p, [id]: true }))
			} catch {
				setFailure((p) => ({ ...p, [id]: true }))
			} finally {
				setLoading((p) => ({ ...p, [id]: false }))
			}
		},
		[
			files,
			mutateAsync,
			selectedOrganizationId,
			setFailure,
			setFiles,
			setLoading,
			setSuccess,
		]
	)

	const onDrop = useCallback(
		async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			const newFiles = acceptedFiles.map(convertFile)

			setLoading((prev) =>
				newFiles.reduce(
					(acc, { id }) => {
						acc[id] = true
						return acc
					},
					{ ...prev } as typeof loading
				)
			)

			setFiles((prev) => [...prev, ...newFiles])

			if (rejectedFiles.some(({ file }) => file.size >= MB20)) {
				notice({
					message: 'Максимальный размер файла для загрузки 20 МБ',
				})
			}

			axios.all(newFiles.map(({ id, file }) => uploadFile({ id, file })))
		},
		[convertFile, setFiles, setLoading, uploadFile]
	)

	const handleRemove = useCallback(
		(id: string) => () => {
			setLoading(omit(id))
			setFailure(omit(id))
			onRemove?.(id)
			setFiles(filter(({ id: _id }) => id !== _id))
		},
		[onRemove, setFailure, setFiles, setLoading]
	)

	const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		onDrop,
		maxSize: MB20,
		accept: {
			'multipart/mixed': ['.pdf', '.xml', '.zip', '.sig', '.crt', '.jpeg', '.tiff', '.png', '.jpg'],
		},
	})

	const getRetryProps = useCallback(
		(id: string): ButtonProps => ({
			type: 'button',
			onClick: () => uploadFile({ id, isRetry: true }),
			styleOverride: { fontWeight: 'normal' },
			variant: 'transparent-blue',
			size: 'inline',
		}),
		[uploadFile]
	)

	return (
		<Grid.Section gap='xl'>
			<div>
				<Text indent='l'>Загрузите фотографии или отсканированные документы.</Text>
				<Styled.Dropzone
					{...getRootProps({
						onMouseEnter: setTrue,
						isDragActive,
						isDragReject,
						onMouseLeave: setFalse,
						isHovered,
					})}
				>
					<input {...getInputProps()} hidden />
					<Grid.Section gap='xs'>
						<Text as='span' styleOverride={{ justifySelf: 'center' }} variant='secondary'>
							Перетащите файлы в эту область
						</Text>
						<Text
							as='span'
							font='body'
							styleOverride={{ justifySelf: 'center', color: colors.border }}
							variant='secondary'
						>
							Максимальный размер файла 20 Мбайт. Используйте следующие форматы: PDF, PNG, JPEG,
							TIFF, XML, ZIP, SIG, CRT
						</Text>
					</Grid.Section>
				</Styled.Dropzone>
			</div>
			{!!files.length && (
				<Grid.Section>
					<Grid.Row gap='xl'>
						{files.map(({ id, name, url }) => (
							<Grid.Section gap='xs' key={id}>
								<Styled.File isFailed={failure[id]}>
									<Styled.ImageWrap>
										{!loading[id] && (
											<Styled.Image
												isLoading={loading[id]}
												src={url}
												fallbackSrc={fileText}
												fallbackStyles={{ height: '40px !important', width: '40px !important' }}
												onLoad={() => URL.revokeObjectURL(url)}
											/>
										)}
										{loading[id] && <Styled.Loading min />}
									</Styled.ImageWrap>
									<Styled.FileContent variant='secondary'>{name}</Styled.FileContent>
									{loading[id] === false && (
										<Styled.Remove onClick={handleRemove(id)} src={removeRed} />
									)}
								</Styled.File>
								{success[id] && <Text variant='success'>Успешно загружено</Text>}
								{failure[id] && (
									<Text variant='error'>
										Ошибка загрузки.
										<Button {...getRetryProps(id)}>Повторить?</Button>
									</Text>
								)}
							</Grid.Section>
						))}
					</Grid.Row>
				</Grid.Section>
			)}
		</Grid.Section>
	)
}
