import { organizationsApi } from 'api/organizations'
import { WithNull } from 'api/types'
import { useCompanyFormCtx } from 'components/forms/Company/useCompanyForm'
import {
	ComCreditclubPartnerCommonDtoV1AddressDto,
	ComCreditclubPartnerLkDtoV1OrganizationOrganizationInfoDto,
	ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerOrganizationProfileUpdateDtoLegalTypeEnum,
	ComCreditclubPartnerLkServiceSuggestionOrganizationResult,
} from 'dto'
import { useFormik } from 'formik'
import { useAppCtx } from 'providers/features/useAppCtx'
import { pick } from 'rambda'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { AutocompleteHookValue } from '@creditclubteam/kit/ui-components'
import { defaultToApi } from 'helpers/convertUtils'
import { utils } from 'helpers/utils'

import { validationSchema } from './validationSchema'

export const useData = () => {
	const {
		controls: { edit, handleCancel },
	} = useCompanyFormCtx()
	const { profile } = useAppCtx()
	const { data } = organizationsApi.useGetOrganization({
		enabled: !edit && !!profile.selectedOrganizationId,
		variables: { id: profile.selectedOrganizationId! },
	})
	const { mutateAsync, status } = organizationsApi.useOrganizationMutation()

	const legalInfo = data?.profile
		?.legalInfo as WithNull<ComCreditclubPartnerLkDtoV1OrganizationOrganizationInfoDto>

	const formik = useFormik({
		enableReinitialize: true,
		validationSchema,
		initialValues: {
			bankDetailsName: data?.profile?.bankDetails?.name ?? '',
			bankDetailsBic: data?.profile?.bankDetails?.bic ?? '',
			bankDetailsCorrespondentAccount: data?.profile?.bankDetails?.correspondentAccount ?? '',
			bankDetailsCheckingAccount: data?.profile?.bankDetails?.checkingAccount ?? '',
			legalInfoFullName: {
				query: legalInfo?.fullName ?? '',
				selected: legalInfo
					? ({
							id: data?.id,
							label: legalInfo?.fullName,
							payload: pick(['fullName', 'taxId', 'ogrn', 'kpp'], legalInfo),
						} as Nullable<
							AutocompleteHookValue<ComCreditclubPartnerLkServiceSuggestionOrganizationResult>
						>)
					: null,
			},
			representativeFullName: {
				query: utils.getFullName(legalInfo?.representative) ?? '',
				manual: null as Nullable<Record<'name' | 'surname' | 'patronymic', string>>,
				selected: (legalInfo?.representative
					? {
							id: utils.getFullName(legalInfo.representative),
							label: utils.getFullName(legalInfo.representative),
							payload: pick(['name', 'surname', 'patronymic'], legalInfo.representative),
						}
					: null) as Nullable<
					AutocompleteHookValue<
						Partial<Record<'name' | 'surname' | 'patronymic' | 'fullName', string>>
					>
				>,
			},
			mainContactFullName: {
				query: utils.getFullName(data?.profile?.mainContact) ?? '',
				manual: null as Nullable<Record<'name' | 'surname' | 'patronymic' | 'fullName', string>>,
				selected: data?.profile?.mainContact
					? ({
							id: utils.getFullName(data.profile.mainContact),
							label: utils.getFullName(data.profile.mainContact),
							payload: pick(['name', 'surname', 'patronymic'], data.profile.mainContact),
						} as Nullable<
							AutocompleteHookValue<
								Partial<Record<'name' | 'surname' | 'patronymic' | 'fullName', string>>
							>
						>)
					: null,
			},
			registrationAddress: {
				query: legalInfo?.registrationAddress?.mergedAddress ?? '',
				selected: legalInfo?.registrationAddress
					? ({
							id: legalInfo.registrationAddress.mergedAddress,
							label: legalInfo.registrationAddress.mergedAddress,
							payload: legalInfo.registrationAddress,
						} as Nullable<AutocompleteHookValue<ComCreditclubPartnerCommonDtoV1AddressDto>>)
					: null,
			},
			mailingAddress: {
				query: legalInfo?.mailingAddress?.mergedAddress ?? '',
				selected: legalInfo?.mailingAddress
					? ({
							id: legalInfo.mailingAddress.mergedAddress,
							label: legalInfo.mailingAddress.mergedAddress,
							payload: legalInfo.mailingAddress,
						} as Nullable<AutocompleteHookValue<ComCreditclubPartnerCommonDtoV1AddressDto>>)
					: null,
			},
			isRegistrationAddressMatchWithMailing: false,
			mainContactPhone: utils.parsePhone(data?.profile?.mainContact.phone) ?? '',
			mainContactEmail: data?.profile?.mainContact.email ?? '',
			representativePost: legalInfo?.representative?.post ?? '',
			shortName: legalInfo?.shortName ?? '',
			phone: utils.parsePhone(legalInfo?.phone) ?? '',
			email: legalInfo?.email ?? '',
			ogrn: legalInfo?.ogrn ?? '',
			taxId: legalInfo?.taxId ?? '',
			kpp: legalInfo?.kpp ?? '',
			site: data?.profile?.site ?? '',
			nameForClient: data?.profile?.nameForClient ?? '',
		},
		onSubmit: async (
			{
				site,
				nameForClient,
				mainContactEmail,
				bankDetailsBic,
				bankDetailsCheckingAccount,
				bankDetailsCorrespondentAccount,
				bankDetailsName,
				kpp,
				email,
				taxId,
				ogrn,
				phone,
				representativePost,
				shortName,
				legalInfoFullName,
				registrationAddress,
				isRegistrationAddressMatchWithMailing,
				mailingAddress,
				representativeFullName,
				mainContactFullName,
				mainContactPhone,
			},
			{ setFieldError }
		) => {
			try {
				await mutateAsync({
					id: data!.id,
					payload: {
						site: defaultToApi(utils.formatSite(site)),
						legalType:
							'ORGANIZATION' as ComCreditclubPartnerLkDtoV1OrganizationRequestPartnerOrganizationProfileUpdateDtoLegalTypeEnum,
						nameForClient: defaultToApi(nameForClient),
						legalInfo: {
							shortName: defaultToApi(shortName),
							kpp: defaultToApi(kpp),
							ogrn: defaultToApi(ogrn),
							email: defaultToApi(email),
							fullName: defaultToApi(
								legalInfoFullName.selected?.payload?.fullName ?? legalInfoFullName.query
							),
							phone: defaultToApi(utils.unparsePhone(phone)),
							registrationAddress: defaultToApi(registrationAddress.selected?.payload),
							mailingAddress: defaultToApi(
								isRegistrationAddressMatchWithMailing
									? registrationAddress.selected?.payload
									: mailingAddress.selected?.payload
							),
							representative: {
								name: defaultToApi(
									representativeFullName.selected?.payload?.name ??
										representativeFullName.manual?.name
								),
								surname: defaultToApi(
									representativeFullName.selected?.payload?.surname ??
										representativeFullName.manual?.surname
								),
								patronymic: defaultToApi(
									representativeFullName.selected?.payload?.patronymic ??
										representativeFullName.manual?.patronymic
								),
								post: defaultToApi(representativePost),
							},
							taxId: defaultToApi(taxId),
						} as typeof legalInfo,
						bankDetails: {
							bic: defaultToApi(bankDetailsBic),
							checkingAccount: defaultToApi(bankDetailsCheckingAccount),
							correspondentAccount: defaultToApi(bankDetailsCorrespondentAccount),
							name: defaultToApi(bankDetailsName),
						},
						mainContact: {
							name: defaultToApi(
								mainContactFullName.selected?.payload?.name ?? mainContactFullName.manual?.name
							),
							surname: defaultToApi(
								mainContactFullName.selected?.payload?.surname ??
									mainContactFullName.manual?.surname
							),
							patronymic: defaultToApi(
								mainContactFullName.selected?.payload?.patronymic ??
									mainContactFullName.manual?.patronymic
							),
							phone: defaultToApi(utils.unparsePhone(mainContactPhone)),
							email: defaultToApi(mainContactEmail),
						},
					},
				})

				handleCancel()
			} catch (error: any) {
				if (error?.response?.data.type === 'duplicate_organization')
					setFieldError('taxId', 'Такой ИНН уже зарегистрирован')
			}
		},
	})

	return {
		formik,
		edit,
		status,
	}
}
