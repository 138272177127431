import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { AppError } from 'components/standalone/ErrorBoundary/AppError'
import { kcCtx, KcStatuses } from 'context'
import { keycloak } from 'keycloak/instance'

let isInitialized = false

export const KeycloakProvider = ({ children }: PropsWithChildren) => {
	const [status, setStatus] = useState<KcStatuses>('pending')
	const [authenticated, setAuthenticated] = useState(false)
	const timeout = useRef<number>(0)
	const value = useMemo(() => ({ kcStatus: status, authenticated }), [authenticated, status])

	useEffect(() => {
		const init = async () => {
			isInitialized = true

			const cb = () => {
				timeout.current = setTimeout(() => setStatus('success'), 1000)
			}

			try {
				await keycloak.init({
					onLoad: 'login-required',
					pkceMethod: 'S256',
					redirectUri: location.href,
					checkLoginIframe: false,
				})

				setAuthenticated(true)
				cb()
			} catch {
				setStatus('error')
			}
		}

		if (!keycloak.authenticated && !isInitialized) init()

		return () => {
			clearTimeout(timeout.current)
		}
	}, [])

	if (!authenticated) return null
	if (status === 'error') return <AppError />

	return <kcCtx.Provider value={value}>{children}</kcCtx.Provider>
}
