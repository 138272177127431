import { PropsWithChildren } from 'react';

import { Styled } from './styled';

export const PageContent = ({ children }: PropsWithChildren) => {
	return (
		<Styled.Wrap>
			{children}
		</Styled.Wrap>
	);
};