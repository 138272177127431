import { Icon as IconifyIcon } from '@iconify/react'
import styled, { CSSObject } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Button } from '@creditclubteam/kit/ui-components'

const Wrap = styled.div<{ override?: CSSObject }>(({ theme, override }) => ({
	display: 'flex',
	flexDirection: 'column',
	minWidth: 150,
	gap: theme.spaces.xs,

	...mixins.when(override, override!),
}))

const TriggerZone = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	userSelect: 'none',
	justifyContent: 'space-between',
	gap: theme.spaces.s,
}))

const Items = styled.div(({ theme }) => ({
	display: 'flex',
	position: 'absolute',
	marginTop: theme.spaces.m,
	zIndex: 2,
	alignItems: 'flex-start',
	flexDirection: 'column',
	boxSizing: 'border-box',
	backgroundColor: '#fff',
	justifyContent: 'space-between',
	width: '100%',
	minWidth: 250,
	maxHeight: 300,
	overflowY: 'auto',
	maxWidth: '100%',
	borderRadius: theme.spaces.l,
	padding: theme.spaces.l,
	boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
	gap: theme.spaces.s,
}))

const Scope = styled.div({
	position: 'relative',
})

const Item = styled(Button)(({ theme }) => ({
	padding: 0,
	whiteSpace: 'pre-wrap',
	textAlign: 'left',
	fontWeight: 'normal',

	...theme.fonts.large,

	'&:hover': {
		color: theme.colors.active,
	},

	['svg, img']: {
		marginRight: theme.spaces.s,
		verticalAlign: 'bottom',
	},
}))

const Icon = styled(IconifyIcon)<{ isActive: boolean }>(({ isActive }) => ({
	color: 'black',
	transition: 'transform .3s ease, color .3s ease',
	height: 22,
	width: 24,
	marginTop: 1,

	...mixins.when(isActive, {
		transform: 'scaleY(-1)',
	}),
}))

export const Styled = {
	TriggerZone,
	Wrap,
	Scope,
	Items,
	Item,
	Icon,
}
