/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
    ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionLoanTermsUpdateOperationDto,ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionOptionUpdateOperationDto,
} from ".";

/**
 * Операция обновления
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDto
 */
export interface ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDto {

    /**
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDto
     */
    type?: ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateOperationDtoTypeEnum {
    LOANTERMS = 'LOAN_TERMS',
    OPTION = 'OPTION'
}

