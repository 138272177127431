import { useCallback } from 'react'
import logout from 'assets/logout.svg'
import user from 'assets/user.svg'
import { keycloak } from 'keycloak/instance'
import { urls } from 'routes/urls'

import { Text } from '@creditclubteam/kit/ui-components'
import { Dropdown, DropdownProps } from 'components/common/Dropdown'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'
import { LinkWithQuery } from 'components/common/LinkWithQuery'
import { utils } from 'helpers/utils'

import { Styled } from './styled'

export const Menu = () => {
	const dropdownProps: DropdownProps = {
		itemsContainerStyle: { right: 0 },
		items: [
			{
				id: 1,
				label: (
					<LinkWithQuery to={urls.profile('information')}>
						<Image src={user} height={24} width={24} />
						{utils.parsePhone(keycloak.tokenParsed?.preferred_username)}
					</LinkWithQuery>
				),
			},
			{
				id: 2,
				onClick: useCallback(() => keycloak.logout(), []),
				label: (
					<>
						<Image src={logout} height={24} width={24} />
						Выйти
					</>
				),
			},
		],
		justify: 'flex-end',
	}

	return (
		<Styled.Wrap>
			<Grid.Row gap='l' sizes='1fr auto' style={{ alignItems: 'center' }}>
				<Styled.Link
					to={
						{
							development: 'https://test.partner-old.credit.club',
							production: 'https://partner-old.credit.club',
							localhost: 'https://test.partner-old.credit.club',
						}[window.__env__.BUILD_MODE]
					}
				>
					В старый интерфейс
				</Styled.Link>
				<Dropdown {...dropdownProps}>
					<Text>Учётная запись</Text>
				</Dropdown>
			</Grid.Row>
		</Styled.Wrap>
	)
}
