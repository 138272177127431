import { useCallback } from 'react'
import { dictionariesApi } from 'api/dictionaries'
import { WithNull } from 'api/types'
import { FullNameModal, FullNameModalProps } from 'components/forms/FullName'
import {
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum,
	ComCreditclubPartnerLkServiceSuggestionFullNameResult,
} from 'dto'
import { useBoolean } from 'hooks/useBoolean'
import { mergeDeepRight } from 'rambda'
import { useTheme } from 'styled-components'
import { getInputProps, getSelectProps } from 'styles/theme'

import { join } from '@creditclubteam/kit/helpers'
import { PreparedTheme } from '@creditclubteam/kit/styled'
import {
	Button,
	ButtonProps,
	Input,
	Select,
	Text,
	useAutocomplete,
} from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import { Modal, ModalProps } from 'components/common/Modal'

import { useData } from './useData'

export interface EmployeeModalProps
	extends Pick<ModalProps, 'onAfterClose' | 'isOpen' | 'onClose'> {
	editableData?: WithNull<ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDto>
}

const overrideOptionStyle = ({ colors }: PreparedTheme) => ({
	div: { color: 'white' },
	backgroundColor: colors.activeHovered,
	color: 'white',
})

export const EmployeeModal = ({
	editableData,
	onAfterClose,
	isOpen,
	onClose,
}: EmployeeModalProps) => {
	const theme = useTheme()
	const {
		formik: {
			values,
			errors,
			touched,
			handleSubmit,
			handleBlur,
			handleChange,
			setValues,
			resetForm,
			setFieldValue,
		},
		isLoading,
	} = useData({ editableData, onClose })
	const fullNameModal = useBoolean()
	const { data: products } = dictionariesApi.useGetProductsTypes({
		select: ({ products }) => products,
	})

	const modalProps: ModalProps = {
		isOpen,
		onAfterClose: useCallback(() => {
			onAfterClose?.()
			resetForm()
		}, [resetForm, onAfterClose]),
		title: editableData ? 'Редактирование' : 'Новый сотрудник',
		onClose,
	}

	const fullNameAutocompleteConfig = useAutocomplete<
		ComCreditclubPartnerLkServiceSuggestionFullNameResult,
		ComCreditclubPartnerLkServiceSuggestionFullNameResult
	>({
		categories: [
			{ id: 'empty', label: 'Подсказка по ФИО' },
			{ id: 'manual', label: null },
		],
		renderOption: ({ render, onSelect, option }) =>
			render(
				option.id === 'manual'
					? {
							children: 'Ввести ФИО вручную',
							onSelect: fullNameModal.setTrue,
							styleOverride: {
								':hover': { color: theme.colors.activeHovered },
								color: theme.colors.active,
							},
						}
					: {
							children: option.label,
							onSelect,
						}
			),
		query: values.fullName.query,
		selected: values.fullName.selected,
		getOptionItem: (response) => ({
			id: response.fullName!,
			label: response.fullName,
			categoryId: 'empty',
			payload: response,
		}),
		onSelect: (option) =>
			setValues((prev) =>
				mergeDeepRight(prev, {
					fullName: {
						query: option.label as string,
						manual: null,
						selected: option,
					},
				})
			),
		request: {
			method: 'POST',
			url: '/v1/suggestions/full-name',
			data: {
				query: values.fullName.query,
			},
		},
	})

	const fullNameProps = getInputProps({
		inputContainerStyleOverride: { alignItems: 'center' },
		error: 'fullName' in errors && !!touched.fullName,
		errorMessage: errors.fullName as string,
		placeholder: 'Фамилия, имя и отчество сотрудника',
		onFocus: handleBlur,
		name: 'fullName',
		autocompleteConfig: {
			...fullNameAutocompleteConfig,
			options: [
				...fullNameAutocompleteConfig.options,
				{ id: 'manual', categoryId: 'manual', label: null },
			],
		},
		value: values.fullName.query,
		onChange: ({ target }) =>
			setValues((prev) =>
				mergeDeepRight(prev, {
					fullName: {
						query: target.value,
						manual: null,
						selected: values.fullName.selected ? null : values.fullName.selected,
					},
				})
			),
		label: 'ФИО',
	})

	const fullNameModalProps: FullNameModalProps = {
		isOpen: fullNameModal.value,
		onClose: fullNameModal.setFalse,
		onChange: (value) =>
			setFieldValue('fullName', {
				selected: null,
				query: join([value.surname, value.name, value.patronymic], ' '),
				manual: value,
			}),
	}

	const phoneProps = getInputProps({
		label: 'Номер телефона',
		mask: '+7 999 999-99-99',
		name: 'phone',
		onFocus: handleBlur,
		placeholder: '+7',
		errorMessage: errors.phone,
		error: 'phone' in errors && !!touched.phone,
		value: values.phone,
		onChange: handleChange,
	})

	const emailProps = getInputProps({
		label: 'Электронная почта *',
		error: 'email' in errors && !!touched.email,
		errorMessage: errors.email,
		onFocus: handleBlur,
		placeholder: 'Email на который придёт приглашение',
		name: 'email',
		value: values.email,
		onChange: handleChange,
	})

	const productsProps = getSelectProps<true>({
		options:
			products?.map(({ id, title: label }) => ({
				id,
				label,
			})) ?? [],
		hideOptionsAfterSelect: false,
		onFocus: handleBlur,
		name: 'products',
		errorMessage: errors.products,
		placeholder: 'Выберите из списка',
		error: 'products' in errors && touched.products,
		value: values.products,
		multi: true,
		label: 'Модули',
		onChange: ({ value }) => setFieldValue('products', value),
	})

	const roleProps = getSelectProps<false>({
		value: values.role,
		placeholder: 'Сотрудник',
		renderOption: ({ render, onSelect, option }) =>
			render({
				children: option.label,
				styleOverride: { '&:hover': overrideOptionStyle(theme) },
				highlightedStyleOverride: overrideOptionStyle(theme),
				onSelect,
			}),
		options: [
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
				payload: 'Владелец',
				label: (
					<>
						Владелец{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Владелец учётной записи компании, максимальный доступ ко всем настройкам
						</Text>
					</>
				),
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
				payload: 'Администратор',
				label: (
					<>
						Администратор{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Имеет доступ к модулям системы, профилю компании. Может приглашать и удалять
							сотрудников
						</Text>
					</>
				),
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.EMPLOYEE,
				payload: 'Сотрудник',
				label: (
					<>
						Сотрудник{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Имеет ограниченный доступ только к модулям системы
						</Text>
					</>
				),
			},
		],
		label: 'Права',
		getOptionLabel: ({ payload }) => payload,
		onChange: ({ value }) => setFieldValue('role', value),
	})

	const submitProps: ButtonProps = {
		fullWidth: false,
		disabled: isLoading,
		styleOverride: { width: 'fit-content' },
	}

	return (
		<Modal {...modalProps}>
			<form autoComplete='off' onSubmit={handleSubmit}>
				<FullNameModal {...fullNameModalProps} />
				<Grid.Section>
					<Input {...fullNameProps} />
					<Input {...emailProps} />
					<Input {...phoneProps} />
					<Select {...roleProps} />
					<Select {...productsProps} />
					<Button {...submitProps}>{editableData ? 'Сохранить' : 'Отправить приглашение'}</Button>
				</Grid.Section>
			</form>
		</Modal>
	)
}
