import { inferData } from 'react-query-kit'
import { QueryClient } from '@tanstack/react-query'
import { dictionariesApi } from 'api/dictionaries'
import { PersonParticipantDataDtoRolesEnum } from 'dto'
import { array, lazy, number, object, string } from 'yup'

import { numberOrNull } from 'helpers/convertUtils'
import { FORM_ERROR_MESSAGES, validationSchemas } from 'helpers/validationSchemas'

export const validationSchema = (queryClient: QueryClient) =>
	object({
		calculationAmount: number()
			.min(200000, 'Не меньше 200 000 руб')
			.max(100000000, 'Не больше 100 000 000 руб')
			.required(),
		purpose: string().required(),
		calculationDuration: string()
			.test({
				test: function (value) {
					if (Number(value!) < 1) return this.createError({ message: 'Не меньше 1 года' })
					if (Number(value!) > 20) return this.createError({ message: 'Не больше 20 лет' })
					return true
				},
			})
			.required(),
		documents: lazy((value) => {
			const shapes: any = {}
			const keys = Object.keys(value)

			keys.forEach((parameter) => {
				shapes[parameter] = string().required()
			})

			return object().shape(shapes)
		}),
		pledges: array()
			.of(
				object({
					address: validationSchemas.address(false),
					city: string().test({
						test: function (value) {
							if (!value) return this.createError({ message: FORM_ERROR_MESSAGES.REQUIRED })

							const isMatched = queryClient
								.getQueryData<inferData<typeof dictionariesApi.useGetApplication>>(
									dictionariesApi.useGetApplication.getKey()
								)
								?.operationCities?.some((city) => city === value)

							if (isMatched) return true
							return this.createError({ message: 'Город не найден' })
						},
					}),
					area: string().test({
						test: (value) => (value ? (numberOrNull(value) ?? 0) < 1000000 : true),
					}),
				})
			)
			.min(1)
			.max(10),
		participants: array()
			.of(
				object({
					email: validationSchemas.email,
					phone: validationSchemas.phone.required(),
					fullName: validationSchemas.fullName(),
					roles: array()
						.of(string())
						.min(1, FORM_ERROR_MESSAGES.REQUIRED)
						.test({
							message: 'В заявке должен быть заёмщик',
							test: (_, p) =>
								p.options.context?.participants?.every(({ roles }: any) => !!roles.length)
									? p.options.context?.participants?.some(
											({ roles }: Record<string, any>) =>
												roles?.includes(PersonParticipantDataDtoRolesEnum.BORROWER)
									  )
									: true,
						}),
					creditHistoryConsentType: string().required(),
				})
			)
			.min(1)
			.max(10),
	})
