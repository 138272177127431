import { applicationsApi } from 'api/applications'
import search from 'assets/search.svg'
import {
	decisionCtx,
	useDecision,
} from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import { FormikProvider } from 'formik'

import { Text } from '@creditclubteam/kit/ui-components'
import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { Highlight } from 'components/common/Highlight'
import { Image } from 'components/common/Image'
import { PageLoading } from 'components/common/PageLoading'

import { Attachments } from './Attachments'
import { Conditions } from './Conditions'
import { EntityScoring } from './EntityScoring'
import { Program } from './Program'
import { Styled } from './styled'

export const Decision = () => {
	const { ctx, formik } = useDecision()
	const { isError, isLoading, data, isSuccess } = applicationsApi.useGetDecision({
		enabled: !ctx.editable,
		refetchOnMount: true,
		refetchInterval: (data) => (data.state.data ? false : 15000),
		variables: { appId: ctx.appId! },
	})

	const isHintVisible = data?.find(({ selected }) => selected)?.processed === false

	return (
		<FadeIn>
			{isLoading && <PageLoading max />}
			{isSuccess && !data.length && (
				<Styled.Card>
					<Image src={search} />
					<Text>Формируем решение</Text>
				</Styled.Card>
			)}
			{isError && <Text variant='error'>Ошибка загрузки данных</Text>}
			{isSuccess && !!data.length && (
				<form autoComplete='off' onSubmit={formik.handleSubmit}>
					<Grid.Section>
						<Grid.Row sizes='1fr'>
							<decisionCtx.Provider value={ctx}>
								<Program />
								<FormikProvider value={formik}>
									{isHintVisible && (
										<Highlight
											styleOverride={{ width: '1fr', maxWidth: 'unset' }}
											variantColor='yellow'
										>
											Дождитесь когда система обработает изменения настроек кредита. Требования для
											завершения проверки и условия выхода на сделку могут измениться.
										</Highlight>
									)}
									{ctx.editable && <Conditions />}
									<Attachments />
									<EntityScoring />
								</FormikProvider>
							</decisionCtx.Provider>
						</Grid.Row>
					</Grid.Section>
				</form>
			)}
		</FadeIn>
	)
}
