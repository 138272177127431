import { useCallback, useMemo, useState } from 'react'
import { nanoid } from 'nanoid'

const convertFile = (file: File) => ({
	id: nanoid(),
	file,
	docId: null as string | null,
	name: file.name,
	url: URL.createObjectURL(file),
})

export interface UseFilesUploaderProps {
	onRemove?: (id: string) => void
}

export const useFilesUploader = ({
	onRemove,
}: UseFilesUploaderProps) => {
	const [loading, setLoading] = useState<Record<string, boolean>>({})
	const [failure, setFailure] = useState<Record<string, boolean>>({})
	const [success, setSuccess] = useState<Record<string, boolean>>({})
	const [files, setFiles] = useState<ReturnType<typeof convertFile>[]>([])

	const reset = useCallback(() => {
		setLoading({})
		setFailure({})
		setSuccess({})
		setFiles([])
	}, [])

	return useMemo(
		() => ({
			_props: {
				convertFile,
				loading,
				setLoading,
				failure,
				setFailure,
				success,
				setSuccess,
				setFiles,
			},
			files,
			isEmpty: !files.length,
			isLoading: Object.values(loading).some(Boolean),
			isInvalid: Object.values(failure).some(Boolean),
			isSuccess: Object.values(success).every(Boolean),
			reset,
			onRemove,
		}),
		[failure, files, loading, onRemove, reset, success]
	)
}
