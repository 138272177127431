import { useEffect, useMemo } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import { applicationsApi } from 'api/applications'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum } from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'
import { includes } from 'rambda'
import { urls } from 'routes/urls'
import { getSelectProps } from 'styles/theme'

import {
	Button,
	ButtonProps,
	Input,
	InputProps,
	Select,
	Text,
} from '@creditclubteam/kit/ui-components'
import { BlurLoading } from 'components/common/BlurLoading'
import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { LinkWithQuery } from 'components/common/LinkWithQuery'
import { List as ListItems, ListProps } from 'components/common/List'
import { PageLoading } from 'components/common/PageLoading'
import { utils } from 'helpers/utils'

import { Item } from './Item'
import { applicationsCtx, useApplications } from './useApplications'

const COLS_SIZES = '.8fr .8fr 1.25fr 1.25fr 1.5fr 1.25fr .9fr .8fr 1fr'

export const List = () => {
	const {
		profile: { selectedOrganizationId, id, role },
	} = useAppCtx()
	const { variables, values, statuses, actions, options } = useApplications()
	const { data, isRefetching, isLoading, isError, isPlaceholderData, isFetching } =
		applicationsApi.useSearchApplications({
			enabled: !!selectedOrganizationId,
			refetchInterval: 5000,
			placeholderData: keepPreviousData,
			variables: {
				...variables,
				organizationId: selectedOrganizationId!,
			},
		})

	const ctxValue = useMemo(
		() => ({
			variables,
		}),
		[variables]
	)

	useEffect(() => {
		if (data?.size && data.empty && data.last && data.totalPages - 1 >= 0)
			actions.setPage(data.totalPages - 1)
	}, [actions, data?.empty, data?.last, data?.size, data?.totalPages, variables.filter])

	const textProps: InputProps = {
		label: 'Поиск',
		value: values.text,
		onChange: ({ target }) => actions.setText(target.value),
	}

	const employeeIdProps = includes(role, [
		ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
		ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
	])
		? getSelectProps({
				value: values.employeeId,
				labelPlacing: 'in',
				optionsWrapperMaxHeight: 300,
				options: [
					{ id: id!, label: 'Только мои заявки' },
					{ id: 'all', label: 'Все заявки' },
					...(options.employees
						.filter(({ id: _id }) => id !== _id)
						.map((option) => ({
							id: option.id,
							label: utils.getFullName(option) || option.email,
						})) ?? []),
				],
				label: 'Выбор сотрудника',
				onChange: ({ value }) => actions.setEmployeeId(value as typeof values.employeeId),
			})
		: null

	const statusProps = getSelectProps({
		labelPlacing: 'in',
		value: values.status,
		multi: true,
		options: [
			{ id: 'all', label: 'Все статусы' },
			...(options.status.map(({ id, title }) => ({ id, label: title })) ?? []),
		],
		label: 'Статус',
		hideOptionsAfterSelect: false,
		onChange: ({ value }) =>
			value.lastIndexOf('all') === value.length - 1
				? actions.setStatus(['all'])
				: actions.setStatus(value.filter((v) => v !== 'all') as typeof values.status),
	})

	const listProps: ListProps = {
		paginationProps: {
			paged: {
				total: data?.totalElements,
				current: (data?.number ?? 0) + 1,
				onChange: (page) => actions.setPage(page - 1),
				pageSize: variables.params.size,
			},
		},
		sizes: COLS_SIZES,
		hidden: !data?.content.length,
		cols: [
			'Заявка',
			'Сумма',
			'Клиент',
			'Сотрудник',
			'Статус',
			'Менеджер',
			'Связь с клиентом',
			'Выдача кредита',
			'Дата',
		],
	}

	const newAppProps: ButtonProps = {
		type: 'button',
		children: 'Создать',
	}

	const isDataEmpty = !data?.content.length

	return (
		<FadeIn>
			<Grid.Section>
				<Grid.Row gap='l' sizes={employeeIdProps ? 'auto 1fr 1fr 1fr' : 'auto 1fr 1fr'}>
					<LinkWithQuery to={urls.applications('new')}>
						<Button {...newAppProps} />
					</LinkWithQuery>
					<Input {...textProps} />
					<Select {...statusProps} />
					{employeeIdProps && <Select {...employeeIdProps} />}
				</Grid.Row>
				<applicationsCtx.Provider value={ctxValue}>
					{isDataEmpty && ((!isFetching && !statuses.isCustomersFetching) || isRefetching) && (
						<Text styleOverride={{ justifySelf: 'center' }} variant='secondary'>
							Заявки не найдены
						</Text>
					)}
					{isError && (
						<Text styleOverride={{ justifySelf: 'center' }} variant='error'>
							Ошибка загрузки
						</Text>
					)}
					{isLoading && <PageLoading max />}
					<ListItems {...listProps}>
						{data?.content.map(({ id }) => <Item key={id} id={id} sizes={COLS_SIZES} />)}
						<BlurLoading
							max={(data?.content.length ?? 0) > 3}
							min={data?.content.length === 1}
							isActive={statuses.isCustomersFetching || (isFetching && isPlaceholderData)}
						/>
					</ListItems>
				</applicationsCtx.Provider>
			</Grid.Section>
		</FadeIn>
	)
}
