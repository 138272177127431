import { createQuery } from 'react-query-kit'
import { RequestError } from 'api/types'
import axios from 'axios'
import { and, comparison, eq } from 'rsql-builder'

import { CustomersApi } from './dto'

const baseUrl = '/v1/customers'

const useSearchCustomers = createQuery<
	CustomersApi.Search,
	{
		organizationId: string
		params: {
			page: number
			size?: number
			sort?: string
		}
		filter: { text: string }
	},
	RequestError
>({
	queryKey: [baseUrl],
	fetcher: (
		{ organizationId, filter, params: { page = 0, size = 5, sort } },
		{ signal }
	) =>
		axios
			.get(baseUrl, {
				signal,
				params: (() => {
					const url = new URLSearchParams()

					url.append('organizationId', organizationId)
					url.append('page', `${page}`)
					url.append('size', `${size}`)
					sort && url.append('sort', sort)
					url.append('filter', and(comparison('text', eq(filter.text))))

					return url
				})(),
			})
			.then(({ data }) => data),
})

export const customersApi = {
	useSearchCustomers,
}
