import { createQuery } from 'react-query-kit'
import { RequestError } from 'api/types'
import axios from 'axios'

import { DictionariesApi } from './dto'

const baseUrl = '/v1/dictionaries'

const useGetAddressTypes = createQuery<
	DictionariesApi.Address,
	void,
	RequestError
>({
	queryKey: [baseUrl, 'address-types'],
	fetcher: (_, { signal }) =>
		axios.get(`${baseUrl}/address-types`, { signal }).then(({ data }) => data),
})

const useGetProductsTypes = createQuery<
	DictionariesApi.Products,
	void,
	RequestError
>({
	queryKey: [baseUrl, 'products'],
	fetcher: (_, { signal }) =>
		axios.get(`${baseUrl}/products`, { signal }).then(({ data }) => data),
})

const useGetApplication = createQuery<
	DictionariesApi.Application,
	void,
	RequestError
>({
	queryKey: [baseUrl, 'application'],
	fetcher: (_, { signal }) =>
		axios.get(`${baseUrl}/application`, { signal }).then(({ data }) => data),
})

export const dictionariesApi = {
	useGetAddressTypes,
	useGetApplication,
	useGetProductsTypes,
}
