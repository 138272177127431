import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { organizationsApi } from 'api/organizations'
import { profileApi } from 'api/profile'
import { kcCtx } from 'context'
import { keycloak } from 'keycloak/instance'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

export type ProfileCtx = ReturnType<typeof useProfile>['ctx']

const QUERY_ORG_KEY = 'org'

export const useProfile = () => {
	const { authenticated } = useContext(kcCtx)
	const [params, setParams] = useSearchParams(new URLSearchParams(window.location.search))
	const [selectedOrganizationId, _setSelectedOrganizationId] = useState<Nullable<string>>(
		params.get(QUERY_ORG_KEY)
	)

	const isSupportMode = !!keycloak.tokenParsed?.support

	const setSelectedOrganizationId = useCallback(
		(value: string) => {
			setParams({ [QUERY_ORG_KEY]: value })
			_setSelectedOrganizationId(value)
		},
		[setParams]
	)

	const query = profileApi.useGetOrganizations({
		enabled: authenticated,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	})

	const isSelectedOrgMatchedWithQuery = !!query.data?.some(
		({ id }) => id === selectedOrganizationId
	)

	const profile = profileApi.useGetProfile({
		enabled:
			!!selectedOrganizationId && authenticated && !isSupportMode && isSelectedOrgMatchedWithQuery,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		variables: { orgId: selectedOrganizationId! },
	})

	const organization = organizationsApi.useGetOrganization({
		enabled:
			!!selectedOrganizationId && authenticated && !isSupportMode && isSelectedOrgMatchedWithQuery,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		variables: { id: selectedOrganizationId! },
	})

	useEffect(() => {
		if (isSupportMode) return

		if (
			(!isSelectedOrgMatchedWithQuery ||
				!selectedOrganizationId ||
				profile.error?.response?.status === 404 ||
				organization.error?.response?.status === 404) &&
			query.data?.[0]
		) {
			setSelectedOrganizationId(query.data[0].id)
		}
	}, [
		isSelectedOrgMatchedWithQuery,
		organization.error?.response?.status,
		organization.isError,
		profile.error?.response?.status,
		profile.isError,
		query.data,
		isSupportMode,
		selectedOrganizationId,
		setParams,
		setSelectedOrganizationId,
	])

	return useMemo(
		() => ({
			isLoading: [
				query.status === 'pending' && !query.isRefetching,
				isSupportMode ? false : profile.status === 'pending' && !query.isRefetching,
			].some(Boolean),
			error: query.error || profile.error || organization.error,
			isError: [
				query.isError,
				(isSupportMode
					? false
					: (profile.isError && profile.error?.response?.status !== 404) ||
						profile.errorUpdateCount > 1,
				(organization.isError && organization.error?.response?.status !== 404) ||
					organization.errorUpdateCount > 1),
			].some(Boolean),
			ctx: {
				id: profile.data?.id,
				role: profile.data?.role,
				legalType: organization.data?.profile?.legalType,
				selectedOrganizationId,
				setSelectedOrganizationId,
			},
		}),
		[
			isSupportMode,
			organization.data?.profile?.legalType,
			organization.error,
			organization.errorUpdateCount,
			organization.isError,
			profile.data?.id,
			profile.data?.role,
			profile.error,
			profile.errorUpdateCount,
			profile.isError,
			profile.status,
			query.error,
			query.isError,
			query.isRefetching,
			query.status,
			selectedOrganizationId,
			setSelectedOrganizationId,
		]
	)
}
