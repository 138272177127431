import { createMutation, createQuery } from 'react-query-kit'
import { ProfileApi } from 'api/profile/dto'
import { RequestError } from 'api/types'
import axios from 'axios'
import { queryClient } from 'instances'

const baseUrl = '/v1/profile/organizations'

const useGetOrganizations = createQuery<ProfileApi.GetOrganizations[], void, RequestError>({
	queryKey: [baseUrl],
	fetcher: (_, { signal }) => axios.get(`${baseUrl}`, { signal }).then(({ data }) => data),
})

const useGetProfile = createQuery<ProfileApi.GetProfile, { orgId: string }, RequestError>({
	queryKey: [baseUrl, 'self'],
	fetcher: ({ orgId }, { signal }) =>
		axios.get(`${baseUrl}/${orgId}/self`, { signal }).then(({ data }) => data),
})

const useProfileMutation = createMutation<
	void,
	{ orgId: string; payload: ProfileApi.ProfileMutation },
	RequestError
>({
	mutationFn: ({ orgId, payload }) =>
		axios.put(`${baseUrl}/${orgId}/self`, payload).then(() => {
			queryClient.invalidateQueries({ queryKey: useGetProfile.getKey({ orgId }) })
		}),
})

export const profileApi = {
	useGetOrganizations,
	useGetProfile,
	useProfileMutation,
}
