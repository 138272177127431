import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum } from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'
import { includes } from 'rambda'
import { urls } from 'routes/urls'

import { Text } from '@creditclubteam/kit/ui-components'
import { FadeIn } from 'components/common/FadeIn'
import { PageContent } from 'components/common/PageContent'
import { Tabs, TabsProps } from 'components/common/Tabs'

const PAGE_TITLE = 'Профиль'

export const Profile = () => {
	const {
		profile: { role },
	} = useAppCtx()

	const tabsProps: TabsProps = {
		navItems: [
			{
				href: urls.profile('information'),
				label: 'ИНФОРМАЦИЯ',
				icon: 'gear',
			},
			includes(role, [
				ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
				ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
			]) && {
				href: urls.profile('employees'),
				label: 'СОТРУДНИКИ',
				icon: 'users',
			},
			// {
			// 	href: urls.profile('statistics'),
			// 	label: 'СТАТИСТИКА',
			// 	icon: 'chart',
			// },
			// {
			// 	href: urls.profile('accounts'),
			// 	label: 'СЧЕТА',
			// 	icon: 'account',
			// },
		].filter(Boolean) as TabsProps['navItems'],
	}

	return (
		<>
			<Helmet title={PAGE_TITLE} />
			<FadeIn>
				<PageContent>
					<Text as='h1' font='h3' styleOverride={{ fontWeight: 800 }}>
						{PAGE_TITLE}
					</Text>
					<Tabs {...tabsProps} />
					<Outlet />
				</PageContent>
			</FadeIn>
		</>
	)
}
