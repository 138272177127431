import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'

const Slider = styled(Swiper)<{ visibleBullets?: boolean }>(({ visibleBullets }) => ({
	maxWidth: '100%',
	paddingBottom: visibleBullets ? 51 : 0,

	'.swiper-pagination': {
		'&-bullet': {
			width: 16,
			height: 16,

			'&-active': {
				backgroundColor: 'black',
			},
		},
	},
}))

const SwiperCard = styled(SwiperSlide)<{ selected?: boolean }>(({ theme, selected }) => ({
	borderRadius: 16,
	boxSizing: 'border-box',
	padding: theme.spaces.l,
	cursor: selected ? 'default' : 'pointer',
	marginTop: 1,
	height: '100%',
	border: `1px solid ${theme.colors.border}`,

	':hover': {
		zIndex: 1,
	},

	...mixins.when(selected, {
		marginTop: 0,
		border: `2px solid ${theme.colors.active}`,
	}),
}))

const ProgramTitle = styled(Text)({
	display: '-webkit-box',
	overflow: 'hidden',
	height: `${21.6 * 2}px`,
	width: '100%',
	'-webkit-box-orient': 'vertical',
	'-webkit-line-clamp': '2',
})

const Circle = styled.div(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#C2E9FF',
	borderRadius: '100%',
	height: 62,
	width: 62,
}))

const Card = styled.div<{ selected?: boolean; isEmpty?: boolean }>(
	({ theme, isEmpty, selected }) => ({
		borderRadius: 16,
		boxSizing: 'border-box',
		padding: theme.spaces.l,
		height: '100%',
		border: `1px solid ${theme.colors.border}`,

		...mixins.when(selected, {
			border: `2px solid ${theme.colors.active}`,
		}),

		...mixins.when(isEmpty, {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}),
	})
)

const Content = styled.div<{ visibleArrows?: boolean }>(({ visibleArrows }) => ({
	display: 'grid',
	margin: !visibleArrows ? 0 : `0 -24px`,
	gridTemplateColumns: !visibleArrows ? '1fr' : 'auto 1fr auto',
}))

const Badge = styled(Text).attrs({
	as: 'span',
	font: 'body/2',
})<{ variantColor: 'yellow' | 'blue' | 'red' | 'green'; variant?: never }>(
	({ theme, variantColor }) => ({
		padding: `${theme.spaces.s}px ${theme.spaces.l}px`,
		width: 'fit-content',
		display: 'flex',
		gap: theme.spaces.s,
		lineHeight: '80%',
		alignItems: 'center',
		borderRadius: 40,

		...mixins.when(variantColor === 'yellow', {
			backgroundColor: '#FFE8A3',
			color: '#DAA506',
		}),

		...mixins.when(variantColor === 'red', {
			backgroundColor: '#FBE4E3',
			color: '#E31E30',
		}),

		...mixins.when(variantColor === 'blue', {
			backgroundColor: '#C2E9FF',
			color: '#00A1DC',
		}),

		...mixins.when(variantColor === 'green', {
			backgroundColor: 'rgba(37, 211, 102, 0.20)',
			color: theme.colors.active,
		}),
	})
)

const Wrap = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	gap: theme.spaces.xl,
	width: '100%',
	height: '100%',
}))

const PrevEl = styled.button({
	transform: 'translateY(calc(100% - 51px))',
	outline: 'none',
	textDecoration: 'none',
	border: 'none',
	background: 'none',
	cursor: 'pointer',
	alignSelf: 'center',
	svg: {
		height: 24,
		width: 24,
	},
})

const NextEl = styled.button({
	transform: 'translateY(calc(100% - 51px))',
	outline: 'none',
	cursor: 'pointer',
	textDecoration: 'none',
	border: 'none',
	background: 'none',
	alignSelf: 'center',
	svg: {
		height: 24,
		width: 24,
	},
})

export const Styled = {
	Card,
	PrevEl,
	SwiperCard,
	Circle,
	NextEl,
	ProgramTitle,
	Content,
	Slider,
	Wrap,
	Badge,
}
