import { PropsWithChildren } from 'react'
import { transparentize } from 'polished'
import styled, { CSSObject, DefaultTheme } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'

export interface HighlightProps extends PropsWithChildren {
	styleOverride?: CSSObject
	radius?: 8 | 16
	variantColor?: 'red' | 'green' | 'yellow' | 'orange' | 'light-blue'
	font?: keyof DefaultTheme['fonts']
}

const Wrap = styled(Text)<HighlightProps>(({ theme, variantColor, radius = 8, styleOverride }) => ({
	padding: `${theme.spaces.l}px`,
	width: 'fit-content',
	display: 'flex',
	gap: theme.spaces.s,
	maxWidth: 656,
	height: 'min-content',
	flexDirection: 'column',
	justifyItems: 'center',
	alignItems: 'flex-start',
	borderRadius: radius,

	...mixins.when(variantColor === 'yellow', {
		backgroundColor: theme.colors.selected,
	}),

	...mixins.when(variantColor === 'red', {
		backgroundColor: transparentize(0.75, theme.colors.negative),
	}),

	...mixins.when(variantColor === 'orange', {
		backgroundColor: '#FF751080',
	}),

	...mixins.when(variantColor === 'light-blue', {
		backgroundColor: '#C2E9FF',
	}),

	...mixins.when(variantColor === 'green', {
		backgroundColor: 'rgba(19, 237, 0, 0.5)',
	}),

	...mixins.when(styleOverride, styleOverride!),
}))

export const Highlight = ({
	children,
	variantColor,
	radius,
	styleOverride,
	font = 'body/14',
}: HighlightProps) => {
	return (
		<Wrap
			as='div'
			weight='normal'
			radius={radius}
			styleOverride={styleOverride}
			font={font}
			variantColor={variantColor}
		>
			{children}
		</Wrap>
	)
}
