import { useCallback } from 'react'
import { profileApi } from 'api/profile'
import { FullNameModal, FullNameModalProps } from 'components/forms/FullName'
import { useFormik } from 'formik'
import { useBoolean } from 'hooks/useBoolean'
import { useAppCtx } from 'providers/features/useAppCtx'
import { pick } from 'rambda'
import { useTheme } from 'styled-components'
import { getInputProps } from 'styles/theme'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { join } from '@creditclubteam/kit/helpers'
import {
	AutocompleteHookValue,
	Input,
	InputProps,
	Text,
	useAutocomplete,
} from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Controls, ControlsProps } from 'components/common/Controls'
import { useControls } from 'components/common/Controls/useControls'
import { FadeIn } from 'components/common/FadeIn'
import { Field } from 'components/common/Field'
import { Grid } from 'components/common/Grid'
import { Hint } from 'components/common/Hint'
import { defaultToApi } from 'helpers/convertUtils'
import { utils } from 'helpers/utils'

import { validationSchema } from './validatetionSchema'

export const ProfileForm = () => {
	const { handleCancel, handleEdit, edit } = useControls()
	const { profile } = useAppCtx()
	const { data } = profileApi.useGetProfile({
		enabled: !!profile.selectedOrganizationId && !edit,
		variables: { orgId: profile.selectedOrganizationId! },
	})
	const fullNameModal = useBoolean()
	const theme = useTheme()
	const { mutateAsync, status } = profileApi.useProfileMutation()

	const {
		values,
		errors,
		dirty,
		touched,
		isValid,
		setFieldValue,
		setFieldError,
		handleBlur,
		handleChange,
		resetForm,
		handleSubmit,
	} = useFormik({
		validationSchema,
		enableReinitialize: true,
		initialValues: {
			fullName: {
				query: utils.getFullName(data!) ?? '',
				manual: null as Nullable<Record<'name' | 'surname' | 'patronymic' | 'fullName', string>>,
				selected: data
					? ({
							id: utils.getFullName(data),
							label: utils.getFullName(data),
							payload: pick(['name', 'surname', 'patronymic'], data),
						} as Nullable<
							AutocompleteHookValue<
								Partial<Record<'name' | 'surname' | 'patronymic' | 'fullName', string>>
							>
						>)
					: null,
			},
			phone: utils.parsePhone(data?.phone) ?? '',
			email: data?.email ?? '',
		},
		onSubmit: async ({ phone, email, fullName }, { setFieldError }) => {
			try {
				await mutateAsync({
					orgId: profile.selectedOrganizationId!,
					payload: {
						email: defaultToApi(email),
						phone: defaultToApi(utils.unparsePhone(phone)),
						name: defaultToApi(fullName.manual?.name ?? fullName.selected?.payload?.name),
						surname: defaultToApi(fullName.manual?.surname ?? fullName.selected?.payload?.surname),
						patronymic: defaultToApi(
							fullName.manual?.patronymic ?? fullName.selected?.payload?.patronymic
						),
					},
				})

				handleCancel()
			} catch (error: any) {
				error?.response?.data.type === 'duplicate_email' &&
					setFieldError('email', 'Почта уже используется')
			}
		},
	})

	const fullNameAutocompleteConfig = useAutocomplete({
		categories: [
			{ id: 'empty', label: 'Подсказка по ФИО' },
			{ id: 'manual', label: null },
		],
		renderOption: ({ render, onSelect, option }) =>
			render(
				option.id === 'manual'
					? {
							children: 'Ввести ФИО вручную',
							onSelect: fullNameModal.setTrue,
							styleOverride: {
								':hover': { color: theme.colors.activeHovered },
								color: theme.colors.active,
							},
						}
					: {
							children: option.label,
							onSelect,
						}
			),
		query: values.fullName.query,
		selected: values.fullName.selected,
		getOptionItem: (response) => ({
			id: response.fullName as string,
			label: response.fullName,
			categoryId: 'empty',
			payload: response,
		}),
		onSelect: (option) =>
			setFieldValue('fullName', {
				query: utils.getFullName(option.payload),
				selected: option,
				manual: values.fullName.manual ? null : values.fullName.manual,
			}),
		request: {
			method: 'POST',
			url: '/v1/suggestions/full-name',
			data: {
				query: values.fullName.query,
			},
		},
	})

	const fullNameProps = getInputProps({
		inputContainerStyleOverride: { alignItems: 'center' },
		onFocus: handleBlur,
		name: 'fullName',
		errorMessage: errors.fullName as string,
		error: 'fullName' in errors && !!touched.fullName,
		autocompleteConfig: {
			...fullNameAutocompleteConfig,
			options: [
				...fullNameAutocompleteConfig.options,
				{ id: 'manual', categoryId: 'manual', label: null },
			],
		},
		value: values.fullName.query,
		onChange: ({ target }) =>
			setFieldValue('fullName', {
				query: target.value,
				manual: values.fullName.manual ? null : values.fullName.manual,
				selected: values.fullName.selected ? null : values.fullName.selected,
			}),
		placeholder: 'Ваша фамилия, имя и отчество',
		label: 'Фамилия Имя Отчество',
	})

	const phoneProps = getInputProps({
		label: (
			<Grid.Row gap='xs' sizes='auto auto 1fr'>
				Номер телефона{' '}
				<Hint
					popperOptions={{ placement: 'right' }}
					element={
						<Text font='body-2'>
							Номер для связи. Не является номером учётной записи для входа.
						</Text>
					}
				/>
			</Grid.Row>
		),
		mask: '+7 999 999-99-99',
		onFocus: handleBlur,
		name: 'phone',
		placeholder: '+7',
		error: 'phone' in errors && !!touched.phone,
		errorMessage: errors.phone,
		value: values.phone,
		onChange: handleChange,
	})

	const emailProps: InputProps = getInputProps({
		label: 'Электронная почта',
		value: values.email,
		name: 'email',
		placeholder: 'Email',
		onFocus: handleBlur,
		errorMessage: errors.email,
		error: 'email' in errors && !!touched.email,
		onChange: ({ target }) => {
			if (errors.email === 'Почта уже используется') setFieldError('email', undefined)
			setFieldValue('email', target.value)
		},
	})

	const controlsProps: ControlsProps = {
		edit,
		submitProps: {
			disabled: status === 'pending' || !isValid || !dirty,
		},
		cancelProps: {
			onClick: useCallback(() => {
				resetForm()
				handleCancel()
			}, [handleCancel, resetForm]),
		},
		editProps: {
			onClick: handleEdit,
		},
	}

	const fullNameModalProps: FullNameModalProps = {
		isOpen: fullNameModal.value,
		onClose: fullNameModal.setFalse,
		onChange: (value) =>
			setFieldValue('fullName', {
				selected: null,
				query: join([value.surname, value.name, value.patronymic], ' '),
				manual: value,
			}),
	}

	return (
		<FadeIn>
			<Card title='Мой профиль'>
				<form autoComplete='off' onSubmit={handleSubmit}>
					<FullNameModal {...fullNameModalProps} />
					<Grid.Section>
						<Grid.Row sizes='1fr'>
							<Field edit={edit} {...pick(['label', 'value'], fullNameProps)}>
								<Input {...fullNameProps} />
							</Field>
						</Grid.Row>
						<Grid.Row>
							<Field edit={edit} {...pick(['label', 'value'], phoneProps)}>
								<Input {...phoneProps} />
							</Field>
							<Field edit={edit} {...pick(['label', 'value'], emailProps)}>
								<Input {...emailProps} />
							</Field>
						</Grid.Row>
						<Controls {...controlsProps} />
					</Grid.Section>
				</form>
			</Card>
		</FadeIn>
	)
}
