/* eslint-disable react-refresh/only-export-components */
import styled from 'styled-components'

import { mixins, PreparedTheme } from '@creditclubteam/kit/styled'

const Section = styled.div<{ gap?: keyof PreparedTheme['spaces'] }>(({ theme, gap = 'xl' }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	position: 'relative',
	gap: theme.spaces[gap],
}))

const Divider = styled.div(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.colors.backgroundSecondary,
	height: 1,
}))

const Row = styled.div<{
	sizes?: string
	gap?: keyof PreparedTheme['spaces']
	controls?: boolean
	keepSizesOnMobile?: boolean
	indent?: keyof PreparedTheme['spaces']
}>(
	({
		theme,
		indent,
		keepSizesOnMobile = false,
		controls,
		gap = 'xl',
		sizes = '1fr 1fr',
	}) => ({
		display: 'grid',
		gap: theme.spaces[gap],
		overflowWrap: 'break-word',
		height: 'min-content',
		gridTemplateColumns: controls ? 'repeat(auto-fit, minmax(0, min-content))' : '1fr',
		gridAutoColumns: 'min-content',

		...mixins.media(theme.breakpoints.mobile, {
			gridTemplateColumns: controls
				? 'repeat(auto-fit, minmax(0, min-content))'
				: sizes.replace(/1fr/g, 'minmax(0, 1fr)'),
		}),

		...mixins.when(!keepSizesOnMobile, {
			[theme.mediaQueries.mobile]: {
				gridTemplateColumns: '1fr',
			},
		}),

		...mixins.when(indent, mixins.indent(theme.spaces[indent!])),
	})
)

export const Grid = {
	Section,
	Divider,
	Row,
}
