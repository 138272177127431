import { createMutation, createQuery } from 'react-query-kit'
import { RequestError, WithNull } from 'api/types'
import axios from 'axios'
import {
	ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateDto,
	ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerApplicationDocumentUpdateDto,
	ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerApplicationUpdateDto,
	ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerShortApplicationRequestDto,
} from 'dto'
import { queryClient } from 'instances'
import { and, comparison, eq, inList } from 'rsql-builder'

import { notice } from 'components/common/Notice'

import { ApplicationsApi } from './dto'

const baseUrl = '/v1/applications'

const useSearchApplications = createQuery<
	ApplicationsApi.Search,
	{
		organizationId: string
		params: {
			page: number
			size?: number
			sort?: string
		}
		filter?: { participants?: string[]; employeeId?: string; status?: string[]; createdAt?: string }
	},
	RequestError
>({
	queryKey: [baseUrl],
	fetcher: ({ organizationId, filter, params: { page = 0, size = 10, sort } }, { signal }) =>
		axios
			.get(baseUrl, {
				signal,
				params: (() => {
					const url = new URLSearchParams()

					url.append('organizationId', organizationId)
					url.append('page', `${page}`)
					url.append('size', `${size}`)
					sort && url.append('sort', sort)
					filter &&
						url.append(
							'filter',
							and(
								...([
									filter.participants?.length &&
										comparison('participants.id', inList(...filter.participants)),
									filter.employeeId && comparison('employeeId', eq(filter.employeeId)),
									filter.status?.length && comparison('status', inList(...filter.status)),
								].filter(Boolean) as string[])
							)
						)

					if (!url.get('filter')) url.delete('filter')

					return url
				})(),
			})
			.then(({ data }) => data),
})

const useGetApplication = createQuery<ApplicationsApi.Get, { id: string }, RequestError>({
	queryKey: [baseUrl, 'application'],
	fetcher: ({ id }, { signal }) =>
		axios.get(`${baseUrl}/${id}`, { signal }).then(({ data }) => data),
})

const useGetDecision = createQuery<ApplicationsApi.GetDecision, { appId: string }, RequestError>({
	queryKey: [baseUrl, 'decision'],
	fetcher: ({ appId }, { signal }) =>
		axios.get(`${baseUrl}/${appId}/decisions`, { signal }).then(({ data }) => data),
})

const useCalculateDecision = createQuery<
	ApplicationsApi.CalculateDecision,
	{
		appId: string
		decisionId: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateDto>
	},
	RequestError
>({
	queryKey: [baseUrl, 'decision', 'calculation'],
	fetcher: ({ appId, decisionId, payload }, { signal }) =>
		axios
			.post(`${baseUrl}/${appId}/decisions/${decisionId}/calculation`, payload, { signal })
			.then(({ data }) => data),
})

const useCalculateDecisionMutation = createMutation<
	ApplicationsApi.CalculateDecision,
	{
		appId: string
		decisionId: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ appId, decisionId, payload }) =>
		axios
			.post(`${baseUrl}/${appId}/decisions/${decisionId}/calculation`, payload)
			.then(({ data }) => data),
})

const useSelectDecision = createMutation<
	ApplicationsApi.SelectDecision,
	{
		appId: string
		decisionId: string
	},
	RequestError
>({
	mutationFn: ({ appId, decisionId }) =>
		axios.post(`${baseUrl}/${appId}/decisions/${decisionId}/select`).then(({ data }) => {
			queryClient.setQueryData(useGetDecision.getKey({ appId }), data)
			return data
		}),
})

const useApplicationMutation = createMutation<
	void,
	{
		id: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerApplicationUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ id, payload }) =>
		axios
			.patch(`${baseUrl}/${id}`, payload, {
				headers: { 'Content-Type': 'application/merge-patch+json' },
			})
			.then(() => {
				queryClient.invalidateQueries({ queryKey: useSearchApplications.getKey() })
			}),
})

const useDecisionMutation = createMutation<
	ApplicationsApi.DecisionMutation,
	{
		appId: string
		decisionId: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1ApplicationDecisionRequestDecisionUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ appId, decisionId, payload }) =>
		axios.put(`${baseUrl}/${appId}/decisions/${decisionId}`, payload).then(({ data: newData }) => {
			queryClient.setQueryData(useGetDecision.getKey({ appId }), newData)
			return newData
		}),
})

const useUploadDocuments = createMutation<
	void,
	{
		id: string
		payload: WithNull<ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerApplicationDocumentUpdateDto>
	},
	RequestError
>({
	mutationFn: ({ id, payload }) =>
		axios.post(`${baseUrl}/${id}/documents`, payload).then(() => {
			notice({
				message: 'Документы отправлены',
				type: 'success',
			})
		}),
})

const useCreateApplication = createMutation<
	void,
	WithNull<ComCreditclubPartnerLkDtoV1ApplicationRequestPartnerShortApplicationRequestDto>,
	RequestError
>({
	mutationFn: (payload) =>
		axios.post(`${baseUrl}`, payload).then(() => {
			notice({
				message: 'Ваша заявка принята и появится в списке после завершения обработки',
				type: 'success',
			})
			queryClient.invalidateQueries({ queryKey: useSearchApplications.getKey() })
		}),
})

export const applicationsApi = {
	useSearchApplications,
	useGetApplication,
	useSelectDecision,
	useApplicationMutation,
	useGetDecision,
	useCalculateDecision,
	useCalculateDecisionMutation,
	useDecisionMutation,
	useUploadDocuments,
	useCreateApplication,
}
