import { useCallback, useEffect, useMemo } from 'react'
import { keepPreviousData } from '@tanstack/react-query';
import { dictionariesApi } from 'api/dictionaries'
import { employeesApi } from 'api/employees'
import { EmployeeModal, EmployeeModalProps } from 'components/forms/Employee'
import {
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum,
} from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'
import { getSelectProps } from 'styles/theme'

import {
	Button,
	Input,
	InputProps,
	Select,
	SelectProps,
	Text,
} from '@creditclubteam/kit/ui-components'
import { BlurLoading } from 'components/common/BlurLoading'
import { FadeIn } from 'components/common/FadeIn'
import { Grid } from 'components/common/Grid'
import { List as ListItems, ListProps } from 'components/common/List'
import { PageLoading } from 'components/common/PageLoading'

import { Item } from './Item'
import { employeesCtx, useEmployees } from './useEmployees'

export const Employees = () => {
	const {
		profile: { selectedOrganizationId, role },
	} = useAppCtx()
	const { variables, values, actions, form } = useEmployees()
	const { data, isLoading, isRefetching, isPlaceholderData, isError, isFetching } =
		employeesApi.useSearchEmployees({
			placeholderData: keepPreviousData,
			enabled: !!selectedOrganizationId && !form.editableId,
			variables: {
				organizationId: selectedOrganizationId!,
				...variables,
			},
		})
	const { data: products } = dictionariesApi.useGetProductsTypes({
		select: ({ products }) => products,
	})

	const ctxValue = useMemo(
		() => ({
			form,
			variables,
		}),
		[form, variables]
	)

	useEffect(() => {
		if (data?.size && data.empty && data.last && data.totalPages - 1 >= 0)
			actions.setPage(data.totalPages - 1)
	}, [actions, data?.empty, data?.last, data?.size, data?.totalPages, variables.filter])

	const textProps: InputProps = {
		labelPlacing: 'in',
		label: 'Поиск',
		value: values.text,
		onChange: ({ target }) => actions.setText(target.value),
	}

	const productsProps: SelectProps<true> = getSelectProps({
		labelPlacing: 'in',
		options:
			products?.map(({ id, title: label }) => ({
				id,
				label,
			})) ?? [],
		hideOptionsAfterSelect: false,
		value: values.products,
		multi: true,
		label: 'Модули',
		onChange: ({ value }) => actions.setProducts(value as typeof values.products),
	})

	const roleProps = getSelectProps({
		value: values.role,
		multi: true,
		labelPlacing: 'in',
		hideOptionsAfterSelect: false,
		renderOption: ({ render, onSelect, option }) =>
			render({
				children: option.label,
				onSelect,
			}),
		options: [
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
				payload: 'Владелец',
				label: (
					<>
						Владелец{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Владелец учётной записи компании, максимальный доступ ко всем настройкам
						</Text>
					</>
				),
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
				payload: 'Администратор',
				label: (
					<>
						Администратор{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Имеет доступ к модулям системы, профилю компании. Может приглашать и удалять
							сотрудников
						</Text>
					</>
				),
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.EMPLOYEE,
				payload: 'Сотрудник',
				label: (
					<>
						Сотрудник{' '}
						<Text as='div' variant='secondary' font='small/12'>
							Имеет ограниченный доступ только к модулям системы
						</Text>
					</>
				),
			},
		],
		label: 'Права',
		getOptionLabel: ({ payload }) => payload,
		onChange: ({ value }) => actions.setRole(value as typeof values.role),
	})

	const statusProps = getSelectProps({
		labelPlacing: 'in',
		value: values.status,
		options: [
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.ACTIVE,
				label: 'Активный',
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.DEACTIVATED,
				label: 'Деактивирован',
			},
			{
				id: ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoStatusEnum.INVITED,
				label: 'Отправлено приглашение',
			},
		],
		label: 'Статус',
		onChange: ({ value }) =>
			actions.setStatus(value === values.status ? '' : (value as typeof values.status)),
	})

	const employeeModalProps: EmployeeModalProps = {
		isOpen: form.modal.value,
		editableData: data?.content.find(({ id }) => id === form.editableId),
		onAfterClose: useCallback(() => form.setEditableId(null), [form]),
		onClose: form.modal.setFalse,
	}

	const isAddButtonVisible = [
		ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
		ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
	].includes(role!)

	const listItemsProps: ListProps = {
		paginationProps: {
			paged: {
				total: data?.totalElements,
				current: (data?.number ?? 0) + 1,
				onChange: (page) => actions.setPage(page - 1),
				pageSize: variables.params.size,
			},
		},
		hidden: !data?.content.length,
	}

	const isDataEmpty = !data?.content.length

	return (
		<FadeIn>
			<Grid.Section>
				<EmployeeModal {...employeeModalProps} />
				<Grid.Section gap='l'>
					<Grid.Row
						gap='l'
						sizes={isAddButtonVisible ? 'auto 1.5fr 1fr 1fr 1fr' : '1.5fr 1fr 1fr 1fr'}
					>
						{isAddButtonVisible && (
							<Button type='button' onClick={form.modal.setTrue}>
								Добавить
							</Button>
						)}
						<Input {...textProps} />
						<Select {...productsProps} />
						<Select {...roleProps} />
						<Select {...statusProps} />
					</Grid.Row>
				</Grid.Section>
				<employeesCtx.Provider value={ctxValue}>
					{isDataEmpty && (!isFetching || isRefetching) && (
						<Text styleOverride={{ justifySelf: 'center' }} variant='secondary'>
							Сотрудники не найдены
						</Text>
					)}
					{isError && (
						<Text styleOverride={{ justifySelf: 'center' }} variant='error'>
							Ошибка загрузки
						</Text>
					)}
					{isLoading && <PageLoading max />}
					<ListItems {...listItemsProps}>
						{data?.content.map(({ id }) => <Item key={id} id={id} />)}
						<BlurLoading
							max={(data?.content.length ?? 0) > 3}
							min={data?.content.length === 1}
							isActive={isFetching && isPlaceholderData}
						/>
					</ListItems>
				</employeesCtx.Provider>
			</Grid.Section>
		</FadeIn>
	)
}
