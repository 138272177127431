import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { organizationsApi } from 'api/organizations'
import { profileApi } from 'api/profile'
import Logo from 'assets/logo.svg'
import { Navbar, NavbarProps } from 'components/standalone/Layout/Sidebar/Navbar'
import { appCtx } from 'providers/features/useAppCtx'
import { urls } from 'routes/urls'

import { join } from '@creditclubteam/kit/helpers'
import { Text } from '@creditclubteam/kit/ui-components'
import { DropdownProps } from 'components/common/Dropdown'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'
import { utils } from 'helpers/utils'

import { Styled } from './styled'

export const Sidebar = () => {
	const {
		profile: { selectedOrganizationId, setSelectedOrganizationId },
	} = useContext(appCtx)
	const { data } = profileApi.useGetOrganizations()
	const navigate = useNavigate()
	const { data: { manager } = {} } = organizationsApi.useGetOrganization({
		variables: { id: selectedOrganizationId! },
	})

	const dropdownProps: DropdownProps = {
		alignIcon: 'flex-start',
		items:
			data?.map(({ id, title: label }) => ({
				id,
				label,
				onClick: () => {
					setSelectedOrganizationId(id)
					navigate({ pathname: urls.profile('information'), search: location.search })
				},
				isActive: id === selectedOrganizationId,
			})) ?? [],
	}

	const organizationTitle = data?.find(({ id }) => selectedOrganizationId === id)?.title

	const navbarProps: NavbarProps = {
		items: [
			{ label: 'Заявки', href: urls.applications(), root: urls.applications() },
			// { label: 'Клиенты', href: urls.clients, disabled: true },
			// { label: 'Объекты', href: urls.facilities, disabled: true },
			// { label: 'Уведомления', href: urls.notifications, disabled: true },
			{
				label: 'База знаний',
				isDirectLink: true,
				href: 'https://wiki.credit.club/partners',
			},
			// 'цифровые сервисы',
			// { label: 'Онлайн подписание', href: urls.onlineSigning, disabled: true },
			// { label: 'Скоринг', href: urls.scoring, disabled: true },
			// { label: 'Оценка недвижимости', href: urls.assessment, disabled: true },
			'настройки',
			{ label: 'Профиль', href: urls.profile('information'), root: urls.profile() },
		],
	}

	return (
		<Styled.Wrap>
			<Styled.Dropdown {...dropdownProps}>
				{organizationTitle ?? <Image src={Logo} />}
			</Styled.Dropdown>
			<Navbar {...navbarProps} />
			{manager && (
				<Grid.Section gap='l'>
					<Text variant='secondary' font='label' styleOverride={{ fontSize: 12 }}>
						МЕНЕДЖЕР CREDIT.CLUB
					</Text>
					<Grid.Section gap='s'>
						<Text font='body'>
							{join([manager.name, manager.surname], ' ')}
						</Text>
						<div>
							{manager.phone && (
								<Text
									as='a'
									styleOverride={{ display: 'block' }}
									href={`tel:+${manager.phone}`}
									font='body'
									variant='secondary'
								>
									{utils.parsePhone(manager.phone)}
								</Text>
							)}
							{manager.email && (
								<Text
									as='a'
									styleOverride={{ display: 'block' }}
									href={`mailto:${manager.email}`}
									font='body'
									variant='secondary'
								>
									{manager.email}
								</Text>
							)}
						</div>
					</Grid.Section>
				</Grid.Section>
			)}
		</Styled.Wrap>
	)
}
