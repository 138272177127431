import { transparentize } from 'polished'
import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'

const Item = styled.div(({ theme }) => ({
	padding: theme.spaces.s,

	'&:hover': {
		backgroundColor: transparentize(.80, theme.colors.backgroundSecondary),
	},
}))

const Column = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	gap: theme.spaces.xs,

	...mixins.ellipsis(),
}))

const Row = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.l,
}))

export const Styled = {
	Column,
	Row,
	Item,
}