import { createContext, useContext, useMemo, useState } from 'react'
import { customersApi } from 'api/customers'
import { dictionariesApi } from 'api/dictionaries'
import { employeesApi } from 'api/employees'
import { ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum } from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'
import { includes } from 'rambda'

export const applicationsCtx = createContext<Pick<ReturnType<typeof useApplications>, 'variables'>>(
	null!
)

export const useApplications = () => {
	const {
		profile: { id, selectedOrganizationId, role },
	} = useAppCtx()
	const [page, setPage] = useState(0)
	const [status, setStatus] = useState<string[]>(['all'])
	const [text, setText] = useState('')
	const [employeeId, setEmployeeId] = useState(id!)
	const { data: customersIds, isFetching } = customersApi.useSearchCustomers({
		enabled: !!text,
		select: ({ content }) => content.map(({ id }) => id),
		variables: {
			organizationId: selectedOrganizationId!,
			params: { page: 0 },
			filter: { text },
		},
	})
	const { data: statusOptions = [] } = dictionariesApi.useGetApplication({
		select: ({ statuses }) => statuses,
	})
	const { data: { content: employeesOptions = [] } = {} } = employeesApi.useSearchEmployees({
		enabled: includes(role, [
			ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.ADMIN,
			ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
		]),
		variables: { organizationId: selectedOrganizationId!, params: { page: 0, size: 100 } },
	})

	return useMemo(
		() => ({
			variables: {
				filter: {
					participants: text && !customersIds?.length ? [''] : customersIds,
					employeeId: employeeId === 'all' ? undefined : employeeId,
					status: status.filter((v) => v !== 'all'),
				},
				params: {
					page,
					size: 10,
				},
			},
			statuses: {
				isCustomersFetching: isFetching,
			},
			actions: {
				setPage,
				setStatus,
				setEmployeeId,
				setText,
			},
			options: {
				status: statusOptions!,
				employees: employeesOptions!,
			},
			values: {
				text,
				status,
				employeeId,
			},
		}),
		[customersIds, employeeId, isFetching, status, page, statusOptions, employeesOptions, text]
	)
}

export const useApplicationsCtx = () => useContext(applicationsCtx)
