import { Link, LinkProps } from 'react-router-dom'

/** Сохраняет query-параметры при смене роута */
export const LinkWithQuery = (props: LinkProps & { to: string }) => {
	return (
		<Link {...props} to={{ pathname: props.to, search: window.location.search }}>
			{props.children}
		</Link>
	)
}
