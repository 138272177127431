import styled, { keyframes } from 'styled-components'

const Wrap = styled.div(({ theme }) => ({
	display: 'grid',
	position: 'relative',
	boxSizing: 'border-box',
	gridTemplateColumns: '239px 1fr',
	gap: theme.spaces.xl,
	minWidth: 760,
	padding: theme.spaces.l,
}))

const fadeIn = keyframes`
	from {
		opacity: 0;
		transform: scale(.985);
	}

	to {
		opacity: 1;
		transform: scale(1);
}
`

const FadeIn = styled.div`
	animation-duration: .4s;
	animation-name: ${fadeIn};
	transition: transform .4s ease-in-out, opacity .4s ease-in-out;
`

export const Styled = {
	Wrap,
	FadeIn,
}
