import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { AppError } from './AppError'
import { Error403 } from './Error403'
import { Error404 } from './Error404'

export const ErrorBoundary = () => {
	const error = useRouteError() as Nullable<Error>

	if (isRouteErrorResponse(error) && error?.status === 403) return <Error403 />
	if (isRouteErrorResponse(error) && error?.status === 404) return <Error404 />

	return (
		<>
			<AppError />
		</>
	)
}
