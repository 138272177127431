import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'

const Wrap = styled.div(({ theme }) => ({
	display: 'grid',
	gap: theme.spaces.l,
	gridTemplateColumns: '1fr',

	...mixins.indent(theme.spaces.l * 4),
}))

const Item = styled.nav<{ isActive: boolean }>(({ theme, isActive }) => ({
	display: 'flex',
	gap: theme.spaces.l,
	alignItems: 'center',

	'&:hover': {
		color: theme.colors.activeHovered,
	},

	img: {
		borderRadius: 8,
		height: 32,
		width: 32,
		transition: 'box-shadow .3s ease',

		...mixins.when(isActive, { boxShadow: '0px 4px 10px 0px rgba(0, 161, 220, 0.50);' }),
	},
}))

const NavItem = styled(NavLink)(({ theme }) => ({
	display: 'grid',
	gap: theme.spaces.l,
	gridTemplateColumns: '1fr',
}))

export const Styled = {
	Wrap,
	NavItem,
	Item,
}
