import { ForwardedRef, forwardRef, useCallback, useState } from 'react'
import styled, { CSSObject } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'

import Error from './error.svg'

export type ImageProps = {
	fallbackSrc?: string
	fallbackStyles?: CSSObject
	ref?: ForwardedRef<HTMLElement>
} & Omit<
	React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'ref'
>

const StyledImage = styled.img<{ stylesOverride?: CSSObject }>(({ stylesOverride }) => ({
	...mixins.when(stylesOverride, stylesOverride!),
}))

export const Image = forwardRef(
	({ src, fallbackSrc, fallbackStyles, ...props }: ImageProps, ref: any) => {
		const [error, setError] = useState(false)

		const handleError = useCallback(() => {
			setError(true)
		}, [])

		return (
			<StyledImage
				ref={ref}
				src={error ? fallbackSrc ?? Error : src}
				stylesOverride={error ? fallbackStyles : undefined}
				onError={handleError}
				{...props}
			/>
		)
	}
)
