import styled from 'styled-components'

const Wrap = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.m,
	alignItems: 'center',

	img: {
		height: 24,
		width: 24,
	},
}))

const Content = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.xs,
	alignItems: 'center',
	flexDirection: 'column',
}))

export const Styled = {
	Wrap,
	Content,
}
