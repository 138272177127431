import { keepPreviousData } from '@tanstack/react-query';
import { applicationsApi } from 'api/applications'
import { DecisionFormValues } from 'components/pages/Applications/Single/Tabs/Decision/types'
import { useDecisionCtx } from 'components/pages/Applications/Single/Tabs/Decision/useDecision'
import { ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto } from 'dto'
import { useFormikContext } from 'formik'

import { Text } from '@creditclubteam/kit/ui-components'
import { Card } from 'components/common/Card'
import { Grid } from 'components/common/Grid'

const Item = ({
	entity,
	data,
}: Pick<ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto, 'entity'> & {
	data?: string[]
}) => {
	if (!data) return null

	const roles = entity.roles
		.map(({ title }) => title)
		.join(', ')
		.toLowerCase()

	return (
		<Grid.Section gap='s'>
			<Grid.Section gap='s'>
				<Text as='h4' font='h4'>
					{entity.title}
				</Text>
				{!!roles && (
					<Text font='body-2' variant='secondary'>
						{roles.charAt(0).toUpperCase() + roles.slice(1)}
					</Text>
				)}
			</Grid.Section>
			<Grid.Section gap='s'>
				{data.map((text, idx) => (
					<Text key={idx} font='body-2'>
						{idx + 1}. {text}
					</Text>
				))}
			</Grid.Section>
		</Grid.Section>
	)
}

export const EntityScoring = () => {
	const { programIdToPreview, appId } = useDecisionCtx()
	const { values, dirty } = useFormikContext<DecisionFormValues>()
	const { data: calculationResult } = applicationsApi.useCalculateDecision({
		enabled: !!values.operations.length,
		placeholderData: dirty ? keepPreviousData : undefined,
		variables: {
			appId: appId!,
			decisionId: programIdToPreview!,
			payload: { operations: values.operations },
		},
	})
	const { data = [] } = applicationsApi.useGetDecision({
		variables: { appId: appId! },
		select: (v) =>
			(calculationResult?.entityScoring.length && calculationResult?.entityScoring) ||
			v.find(({ id }) => programIdToPreview === id)?.entityScoring ||
			v.find(({ selected }) => selected)?.entityScoring,
	})

	if (!data.length) return null

	return (
		<>
			{data.some(({ requiredActions }) => requiredActions?.length) && (
				<Card title='Что нужно сделать для завершения проверки'>
					<Grid.Section gap='l'>
						{data.map(
							({ entity, requiredActions }) =>
								!!requiredActions.length && (
									<Item key={entity.title} entity={entity} data={requiredActions} />
								)
						)}
					</Grid.Section>
				</Card>
			)}
			{data.some(({ conditions }) => conditions?.length) && (
				<Card title='Условия выхода на сделку'>
					<Grid.Section gap='l'>
						{data.map(
							({ entity, conditions }) =>
								!!conditions.length && <Item key={entity.title} entity={entity} data={conditions} />
						)}
					</Grid.Section>
				</Card>
			)}
		</>
	)
}
