/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto } from './com-creditclub-partner-lk-dto-v1-application-decision-entity-scoring-dto';
import { ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto } from './com-creditclub-partner-lk-dto-v1-application-decision-loan-terms-dto';
import {
    ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto,ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto,
} from ".";

/**
 * Решение по кредитной программе
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
 */
export interface ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto {

    /**
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    id: string;

    /**
     * Признак выбора в качестве основного
     *
     * @type {boolean}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    selected: boolean;

    /**
     * Признак, что решение обработано и содержит актуальные условия и требования
     *
     * @type {boolean}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    processed: boolean;

    /**
     * Статус решения по кредитной программе: * `APPROVED` – Одобрено * `APPROVED_WITH_CONDITIONS` – Одобрено под условиями * `PRE_APPROVED` – Предварительно одобрено * `DENIED` – Отказано 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    status: ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum;

    /**
     * Название программы
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    program: string;

    /**
     * @type {ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    loanTerms: ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto;

    /**
     * Результаты проверок сущностей
     *
     * @type {Array<ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto>}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDto
     */
    entityScoring: Array<ComCreditclubPartnerLkDtoV1ApplicationDecisionEntityScoringDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1ApplicationDecisionDecisionDtoStatusEnum {
    APPROVED = 'APPROVED',
    APPROVEDWITHCONDITIONS = 'APPROVED_WITH_CONDITIONS',
    PREAPPROVED = 'PRE_APPROVED',
    DENIED = 'DENIED'
}

