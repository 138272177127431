import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { FadeIn } from 'components/common/FadeIn'

const Wrap = styled(FadeIn)(() => ({
	display: 'flex',
	alignItems: 'center',
	height: '100vh',
	justifyContent: 'center',
}))

const Content = styled.div<{ is404?: boolean }>(({ is404 }) => ({
	textAlign: 'center',

	...mixins.when(is404, {
		h1: {
			marginTop: -56,
		},
	}),
}))

export const Styled = {
	Wrap,
	Content,
}
