import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { keepPreviousData } from '@tanstack/react-query'
import { applicationsApi } from 'api/applications'
import { dictionariesApi } from 'api/dictionaries'
// import car from 'assets/car.svg'
import home from 'assets/home.svg'
import whatsapp from 'assets/whatsapp.svg'
import { useApplicationsCtx } from 'components/pages/Applications/List/useApplications'
import { useAppCtx } from 'providers/features/useAppCtx'
import { urls } from 'routes/urls'

import { defaultOr } from '@creditclubteam/kit/helpers'
import { date } from '@creditclubteam/kit/legacy-helpers'
import { ToggleSwitchProps } from '@creditclubteam/kit/legacy-ui-components'
import { Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import { Image } from 'components/common/Image'
import { utils } from 'helpers/utils'

import { Styled } from './styled'

interface Props {
	id: string
	sizes: string
}

const numInstance = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 })

export const Item = ({ id, sizes }: Props) => {
	const { profile } = useAppCtx()
	const { variables } = useApplicationsCtx()
	const { data, isFetching, isRefetching } = applicationsApi.useSearchApplications({
		enabled: !!profile.selectedOrganizationId,
		select: ({ content }) => content.find(({ id: _id }) => _id === id)!,
		placeholderData: keepPreviousData,
		variables: {
			organizationId: profile.selectedOrganizationId!,
			...variables,
		},
	})
	const { mutate } = applicationsApi.useApplicationMutation()
	const { data: { statuses } = {} } = dictionariesApi.useGetApplication()
	const navigate = useNavigate()

	const goToApplication = useCallback(
		() => navigate({ search: window.location.search, pathname: urls.applications(id, 'decision') }),
		[id, navigate]
	)

	const status = statuses?.find(({ id }) => data?.status === id)

	const allowCommunicationWithClientProps: ToggleSwitchProps = {
		name: 'allowCommunicationWithClient',
		onClick: (event) => event.stopPropagation(),
		onChange: ({ checked }) =>
			mutate({
				id,
				payload: {
					specification: {
						allowCommunicationWithClient: checked,
						// нормально всё, не трогайте
						allowLoanIssue: undefined!,
					},
				},
			}),
		disabled: isRefetching ? false : isFetching,
		title: data!.specification.allowCommunicationWithClient ? 'Да' : 'Нет',
		checked: data!.specification.allowCommunicationWithClient ?? false,
	}

	const allowLoanIssueProps: ToggleSwitchProps = {
		name: 'allowLoanIssue',
		onClick: (event) => event.stopPropagation(),
		onChange: ({ checked }) =>
			mutate({
				id,
				payload: {
					specification: {
						allowLoanIssue: checked,
						// нормально всё, не трогайте
						allowCommunicationWithClient: undefined!,
					},
				},
			}),
		disabled: isRefetching ? false : isFetching,
		title: data!.specification.allowLoanIssue ? 'Да' : 'Нет',
		checked: data!.specification.allowLoanIssue ?? false,
	}

	return (
		<Styled.Item onClick={goToApplication}>
			<Grid.Row gap='l' sizes={sizes} style={{ alignItems: 'center' }}>
				<Styled.Col as='div' font='body-2'>
					<Styled.WithIcon>
						<Image src={home} />
						<Text as='span' font='body-2' ellipsis>
							{data!.number}
						</Text>
					</Styled.WithIcon>
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					{numInstance.format(data!.calculation.amount)}
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					{data!.participant.title}
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					{defaultOr(utils.getFullName(data!.employee, true))}
				</Styled.Col>
				<Styled.Col
					as='div'
					font='body-2'
					variant={status?.loanIssued ? 'success' : status?.loanRejected ? 'error' : undefined}
				>
					{status?.title}
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					{data?.manager ? (
						<Link
							onClick={(event) => event.stopPropagation()}
							to={`https://wa.me/7${data.manager.phone}`}
							target='_blank'
							rel='noopener'
						>
							<Styled.WithIcon>
								{data.manager.name && <Image src={whatsapp} />}
								<Text as='span' font='body-2' ellipsis>
									{defaultOr(data.manager.name)}
								</Text>
							</Styled.WithIcon>
						</Link>
					) : (
						defaultOr(null)
					)}
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					<Styled.Toggle {...allowCommunicationWithClientProps} />
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					<Styled.Toggle {...allowLoanIssueProps} />
				</Styled.Col>
				<Styled.Col as='div' font='body-2'>
					{date.format(new Date(data!.createdAt as Date))}
				</Styled.Col>
			</Grid.Row>
		</Styled.Item>
	)
}
