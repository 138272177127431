import { WithNull } from 'api/types'
import { ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto } from 'dto'

import { moneyUtils } from '@creditclubteam/kit/legacy-helpers'

export const getServicePayment = (
	{
		servicePayments,
		duration,
	}: WithNull<ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto> = {} as WithNull<ComCreditclubPartnerLkDtoV1ApplicationDecisionLoanTermsDto>
) => {
	const [payment] = servicePayments ?? []

	if (!payment) return [moneyUtils.RUB(0, true)]
	if (payment.durationInterval.from === payment.durationInterval.to) {
		const value = Number((payment.amount / duration).toFixed(2))
		const shouldCeil = value - Math.floor(value) >= 0.5
		return [
			`${moneyUtils.RUB(shouldCeil ? Math.ceil(value) : value, true)} в месяц`,
			`${moneyUtils.RUB(payment.amount, true)} за весь срок`,
			'Оплата единоразово',
		]
	}

	return [
		`${moneyUtils.RUB(payment.amount, true)} в ${payment.durationInterval.from}-${
			payment.durationInterval.to
		} месяц`,
	]
}
