import { useNavigate } from 'react-router-dom'
import error403 from 'assets/error-403.svg'
import { urls } from 'routes/urls'

import { Button, Text } from '@creditclubteam/kit/ui-components'
import { Image } from 'components/common/Image'

import { Styled } from './styled'

export const Error403 = () => {
	const navigate = useNavigate()

	return (
		<Styled.Wrap>
			<Styled.Content>
				<Image src={error403} />
				<Text as='h1' font='h1'>
					Нет доступа
				</Text>
				<Text indent='xxl'>Обратитесь к владельцу аккаунта</Text>
				<Button type='button' onClick={() => navigate(urls.root)}>
					Назад на главную
				</Button>
			</Styled.Content>
		</Styled.Wrap>
	)
}
