import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	from {
		opacity: 0;
		transform: scale(.985);
	}

	to {
		opacity: 1;
		transform: scale(1);
}
`

export const FadeIn = styled.div`
	animation-duration: .35s;
	animation-name: ${fadeIn};
	transition: transform .35s ease-in-out, opacity .35s ease-in-out;
`