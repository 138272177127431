import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { PageLoading } from 'components/common/PageLoading'

const Background = styled.div<{ isActive?: boolean }>(({ theme, isActive }) => ({
	position: 'absolute',
	zIndex: -1,
	opacity: 0,
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
	width: '100%',
	height: '100%',
	transition: 'zIndex .3s ease, opacity .3s ease',

	'@supports not (backdrop-filter: blur())': {
		background: 'white',
		border: `1px solid ${theme.colors.border}`,
	},

	'@supports (backdrop-filter: blur())': {
		backdropFilter: 'blur(5px)',
	},

	...mixins.when(isActive, {
		zIndex: 1,
		opacity: 1,
	}),
}))

const Loader = styled(PageLoading)<{ isActive?: boolean }>(({ isActive }) => ({
	position: 'absolute',
	zIndex: -1,
	opacity: 0,
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
	width: '100%',
	height: '100%',
	transition: 'zIndex .3s ease, opacity .3s ease',

	...mixins.when(isActive, {
		zIndex: 1,
		opacity: 1,
	}),
}))

export const Styled = {
	Background,
	Loader,
}
