/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
    FacilityPledgeDataDto,
} from ".";

/**
 * Информация о залоге
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDto
 */
export interface ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDto {

    /**
     * Тип залога: * `FACILITY` – Недвижимость 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDto
     */
    type?: ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1ApplicationRequestPledgePledgeDataDtoTypeEnum {
    FACILITY = 'FACILITY'
}

