/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
    EntrepreneurParticipantDataDto,OrganizationParticipantDataDto,PersonParticipantDataDto,
} from ".";

/**
 * Участники
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto
 */
export interface ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto {

    /**
     * Тип участника: * `PERSON` – ФЛ * `ORGANIZATION` – ЮЛ * `ENTREPRENEUR` – ИП 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDto
     */
    type?: ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1ApplicationRequestParticipantParticipantDataDtoTypeEnum {
    PERSON = 'PERSON',
    ORGANIZATION = 'ORGANIZATION',
    ENTREPRENEUR = 'ENTREPRENEUR'
}

