import { useCallback, useMemo, useState } from 'react'
import { dictionariesApi } from 'api/dictionaries'
import { ComCreditclubPartnerCommonDtoV1AddressDto } from 'dto'
import { getInputProps, getSelectProps } from 'styles/theme'
import { NullableDeep } from 'ts-toolbelt/out/Object/Nullable'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import {
	Button,
	ButtonProps,
	Input,
	Select,
	SelectOption,
	useAutocomplete,
} from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import { Modal, ModalProps } from 'components/common/Modal'

type Values = NullableDeep<
	Pick<
		ComCreditclubPartnerCommonDtoV1AddressDto,
		| 'city'
		| 'street'
		| 'streetType'
		| 'streetTypeFull'
		| 'block'
		| 'blockType'
		| 'blockTypeFull'
		| 'house'
		| 'houseType'
		| 'houseTypeFull'
		| 'flat'
		| 'flatType'
		| 'flatTypeFull'
		| 'mergedAddress'
		| 'region'
		| 'regionFiasId'
		| 'regionType'
		| 'regionTypeFull'
	>
>

export interface AddressModalProps {
	isOpen: boolean
	onClose: () => void
	onChange: (values: Values) => void
}

const initialValues: Values = {
	city: null,
	street: null,
	streetType: null,
	streetTypeFull: null,
	house: null,
	houseType: null,
	houseTypeFull: null,
	block: null,
	blockType: null,
	blockTypeFull: null,
	flat: null,
	flatType: null,
	flatTypeFull: null,
	region: null,
	regionFiasId: null,
	regionType: null,
	regionTypeFull: null,
	mergedAddress: null,
}

export const AddressModal = ({ isOpen, onChange, onClose }: AddressModalProps) => {
	const { data: dictionary } = dictionariesApi.useGetAddressTypes()

	const [values, setValues] = useState<Values>(initialValues)

	const setValue = useCallback(
		(key: keyof Values, value: Nullable<string>) => setValues((p) => ({ ...p, [key]: value })),
		[]
	)

	const modalProps: ModalProps = {
		isOpen,
		onClose,
		onAfterClose: () => setValues(initialValues),
		title: 'Ручной ввод адреса',
	}

	const cityProps = getInputProps({
		label: 'Город / Населенный пункт *',
		value: values.city || '',
		error: [values.city, values.region].some((v) => v === null),
		onChange: ({ target }) =>
			setValues((p) => ({
				...p,
				region: null,
				regionFiasId: null,
				regionType: null,
				regionTypeFull: null,
				city: target.value || null,
			})),
		placeholder: 'Санкт-Петербург',
		autocompleteConfig: useAutocomplete<
			ComCreditclubPartnerCommonDtoV1AddressDto,
			ComCreditclubPartnerCommonDtoV1AddressDto
		>({
			request: {
				url: '/v1/suggestions/address',
				method: 'post',
				data: {
					query: values.city,
					bound: {
						from: 'CITY',
						to: 'SETTLEMENT',
					},
				},
			},
			getOptionItem: (option, id) => ({ id, label: option.mergedAddress, payload: option }),
			query: values.city ?? '',
			selected: null,
			onSelect: (option) =>
				setValues((p) => ({
					...p,
					region: option.payload?.region ?? null,
					regionFiasId: option.payload?.regionFiasId ?? null,
					regionType: option.payload?.regionType ?? null,
					regionTypeFull: option.payload?.regionTypeFull ?? null,
					city: option.label as string,
				})),
		}),
	})

	const streetProps = getInputProps({
		value: values.street || '',
		placeholder: 'Малышева',
		error: !values.street,
		label: 'Улица *',
		onChange: ({ target }) => setValue('street', target.value || null),
	})

	const streetTypeProps = getSelectProps<false>({
		label: 'Сокращение *',
		value: values.streetType,
		error: !values.streetType,
		optionsWrapperMaxHeight: 200,
		onChange: useCallback(
			({ selected }) =>
				setValues((p) => ({
					...p,
					streetType: selected!.id as string,
					streetTypeFull: selected!.label as string,
				})),
			[]
		),
		options: useMemo(
			(): SelectOption[] => dictionary?.streetTypes?.map(({ id, title: label }) => ({ id, label })) ?? [],
			[dictionary]
		),
	})

	const houseProps = getInputProps({
		value: values.house || '',
		placeholder: '55',
		error: !values.house,
		label: 'Дом *',
		onChange: ({ target }) => setValue('house', target.value || null),
	})

	const houseTypeProps = getSelectProps<false>({
		label: 'Сокращение *',
		value: values.houseType,
		error: !values.houseType,
		optionsWrapperMaxHeight: 200,
		onChange: useCallback(
			({ selected }) =>
				setValues((p) => ({
					...p,
					houseType: selected!.id as string,
					houseTypeFull: selected!.label as string,
				})),
			[]
		),
		options: useMemo(
			(): SelectOption[] => dictionary?.houseTypes?.map(({ id, title: label }) => ({ id, label })) ?? [],
			[dictionary]
		),
	})

	const blockProps = getInputProps({
		value: values.block || '',
		placeholder: '2',
		label: 'Корпус / Блок / Строение',
		onChange: ({ target }) => setValue('block', target.value || null),
	})

	const blockTypeProps = getSelectProps<false>({
		label: 'Сокращение',
		value: values.blockType,
		optionsWrapperMaxHeight: 200,
		onChange: useCallback(
			({ selected }) =>
				setValues((p) => ({
					...p,
					blockType: selected!.id as string,
					blockTypeFull: selected!.label as string,
				})),
			[]
		),
		options: useMemo(
			(): SelectOption[] => dictionary?.blockTypes?.map(({ id, title: label }) => ({ id, label })) ?? [],
			[dictionary]
		),
	})

	const flatProps = getInputProps({
		value: values.flat || '',
		placeholder: '514',
		label: 'Квартира',
		onChange: ({ target }) => setValue('flat', target.value || null),
	})

	const flatTypeProps = getSelectProps<false>({
		label: 'Сокращение',
		value: values.flatType,
		optionsWrapperMaxHeight: 200,
		onChange: useCallback(
			({ selected }) =>
				setValues((p) => ({
					...p,
					flatType: selected!.id as string,
					flatTypeFull: selected!.label as string,
				})),
			[]
		),
		options: useMemo(
			(): SelectOption[] => dictionary?.flatTypes?.map(({ id, title: label }) => ({ id, label })) ?? [],
			[dictionary]
		),
	})

	const mergedAddressProps = getInputProps({
		value: values.mergedAddress || '',
		error: !values.mergedAddress,
		placeholder: 'Екатеринбург, ул. Седова, д. 55, кв. 178',
		label: 'Полный адрес одной строкой *',
		onChange: ({ target }) => setValue('mergedAddress', target.value || null),
	})

	const handleSave = useCallback(() => {
		onChange(values)
		onClose()
	}, [onChange, onClose, values])

	const saveProps: ButtonProps = {
		type: 'button',
		onClick: handleSave,
		fullWidth: false,
		disabled: [
			values.city,
			values.streetType,
			values.houseType,
			values.region,
			values.mergedAddress,
			values.street,
			values.house,
		].some((v) => v === null),
	}

	return (
		<Modal {...modalProps}>
			<Grid.Section>
				<Grid.Row sizes='1fr'>
					<Input {...cityProps} />
				</Grid.Row>
				<Grid.Row>
					<Input {...streetProps} />
					<Select {...streetTypeProps} />
				</Grid.Row>
				<Grid.Row>
					<Input {...houseProps} />
					<Select {...houseTypeProps} />
				</Grid.Row>
				<Grid.Row>
					<Input {...blockProps} />
					<Select {...blockTypeProps} />
				</Grid.Row>
				<Grid.Row>
					<Input {...flatProps} />
					<Select {...flatTypeProps} />
				</Grid.Row>
				<Grid.Row sizes='1fr'>
					<Input {...mergedAddressProps} />
				</Grid.Row>
				<Button {...saveProps}>Сохранить</Button>
			</Grid.Section>
		</Modal>
	)
}
