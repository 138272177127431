/* tslint:disable */
/* eslint-disable */
/**
 * ЛК Партнера
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
    
} from ".";

/**
 * Обновление статуса сотрудника партнера
 *
 * @export
 * @interface ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDto
 */
export interface ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDto {

    /**
     * Статус сотрудника: * `ACTIVE` – Активен * `DEACTIVATED` – Деактивирован 
     *
     * @type {string}
     * @memberof ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDto
     */
    status: ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDtoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ComCreditclubPartnerLkDtoV1OrganizationRequestStatusUpdateDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED'
}

