import styled from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Button as _Button } from '@creditclubteam/kit/ui-components'


const Wrap = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.s,
}))

const TabItemWrap = styled.div(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.l,
	flexDirection: 'column',
}))

const Button = styled(_Button)(({ theme, variant }) => ({
	display: 'flex',
	gap: theme.spaces.xs,
	alignItems: 'center',
	borderRadius: 8,
	padding: `${6}px ${theme.spaces.l}px`,
	height: 'unset',
	transition: 'box-shadow .3s ease-in-out, background .3s ease-in-out, color .3s ease-in-out',
	backgroundColor: '#F5F4F5',

	...mixins.when(variant === 'transparent-blue', {
		backgroundColor: '#C2E9FF',
	}),

	'&:hover': {
		background: 'white',
		color: theme.colors.activeHovered,
		boxShadow: `0 0 1px 1px inset ${theme.colors.active}`,
	},

	img: {
		height: 24,
		width: 24,
	},

	...theme.fonts.tab,
}))

export const Styled = {
	Wrap,
	Button,
	TabItemWrap,
}
