import 'swiper/css'
import 'swiper/css/pagination'

import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'
import { THEME_ID, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import {
	browserTracingIntegration,
	init,
	reactRouterV6BrowserTracingIntegration,
	replayIntegration,
} from '@sentry/react'
import axios from 'axios'
import { NoticeContainer } from 'components/standalone/NoticeContainer'
import { keycloak } from 'keycloak/instance'
import { KeycloakProvider } from 'providers/Keycloak'
import { Metrika } from 'providers/Metrika'
import { QueryProvider } from 'providers/Query'
import { RouterProvider } from 'providers/Router'
import { SentryProvider } from 'providers/Sentry'
import { ThemeProvider } from 'styled-components'
import { registerSW } from 'virtual:pwa-register'

import { join } from '@creditclubteam/kit/helpers'
import { ConfigureAxios, sentryUtils } from '@creditclubteam/kit/legacy-helpers'
import { notice } from 'components/common/Notice'

import { GlobalStyles, materialTheme, preparedTheme } from './styles/theme'

import 'normalize.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-popper-tooltip/dist/styles.css'
import 'rc-pagination/assets/index.css'

init({
	enabled: import.meta.env.PROD,
	environment: window.__env__.BUILD_MODE ?? 'localhost',
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		browserTracingIntegration(),
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		replayIntegration(),
	],

	beforeBreadcrumb(breadcrumb) {
		if (sentryUtils.shouldIgnoreBreadcrumb(breadcrumb)) {
			return null
		}

		return breadcrumb
	},

	beforeSend(event, hint) {
		if (sentryUtils.shouldSendError(hint?.originalException)) {
			return event
		}

		return null
	},

	tracesSampleRate: 1.0,
	tracePropagationTargets: [],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

new ConfigureAxios({
	env: window.__env__.BUILD_MODE ?? 'localhost',
	baseURL: {
		localhost: window.__env__.BASE_URL,
		development: window.__env__.BASE_URL,
		production: window.__env__.BASE_URL,
	},
	updateTokenFn: () => null,
	userReceivingTokenAfterFail: () => null,
}).init({
	axios,
	keycloakInstance: keycloak,
})

axios.interceptors.request.use((request) => {
	if (!request.headers?.['Accept']) request.headers!['Accept'] = 'application/problem+json'
	;(request as any).$$notPutApiVersion = true
	return request
})

axios.interceptors.response.use(undefined, (error) => {
	const skipableErrors = [
		'duplicate_email',
		'duplicate_organization',
		'last_owner',
		'email_not_exists',
	]

	if (
		axios.isCancel(error) ||
		!error?.response?.data ||
		skipableErrors.includes(error?.response?.data?.type)
	)
		throw error

	notice({
		props: {
			autoClose: false,
		},
		message: join(
			[
				`Cтатус: ${error.response.data.status}`,
				`Путь: ${error.response.data.instance}`,
				`Название: ${error.response.data.type}`,
				error.response.data.detail && `Описание: ${error.response.data.detail}`,
				`Время: ${error.response.data.timestamp}`,
			],
			'\n'
		),
	})

	throw error
})

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<Metrika />
		<ThemeProvider theme={preparedTheme}>
			<MuiThemeProvider theme={{ [THEME_ID]: materialTheme }}>
				<GlobalStyles />
				<SentryProvider>
					<KeycloakProvider>
						<HelmetProvider>
							<NoticeContainer />
							<QueryProvider>
								<RouterProvider />
							</QueryProvider>
						</HelmetProvider>
					</KeycloakProvider>
				</SentryProvider>
			</MuiThemeProvider>
		</ThemeProvider>
	</StrictMode>
)

if (window.__env__.BUILD_MODE !== 'localhost') {
	registerSW({ immediate: true })
}