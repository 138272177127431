import React, { PropsWithChildren } from 'react'

import { Button, ButtonProps } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

export interface ControlsProps {
	edit?: boolean

	submitProps?: ButtonProps
	cancelProps?: ButtonProps
	editProps?: ButtonProps
}

export const Controls = (props: PropsWithChildren<ControlsProps>) => {
	const { edit, submitProps, cancelProps, editProps, children } = props

	const editDefaults: ButtonProps = {
		type: 'button',
	}

	const cancelDefaults: ButtonProps = {
		type: 'button',
		variant: 'gray',
	}

	const handleEdit = React.useCallback(
		(event: any) => {
			if ('preventDefault' in event) event.preventDefault()

			if (editProps?.onClick) editProps.onClick(event)
		},
		[editProps]
	)

	const editBtnProps: typeof editProps = editProps
		? { ...editProps, ...editDefaults, onClick: handleEdit }
		: editDefaults

	const submitBtnProps: typeof submitProps = submitProps

	const cancelBtnProps: typeof cancelProps = cancelProps
		? { ...cancelProps, ...cancelDefaults }
		: cancelDefaults

	if (children) return <>{children}</>

	return (
		<Grid.Row controls>
			{!edit ? (
				<Button {...editBtnProps}>Редактировать</Button>
			) : (
				<>
					<Button {...submitBtnProps}>Сохранить</Button>
					<Button {...cancelBtnProps}>Отмена</Button>
				</>
			)}
		</Grid.Row>
	)
}
